import { Component, OnInit } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
@Component({
  selector: 'app-fs-header',
  templateUrl: './fs-header.component.html',
  styleUrls: ['./fs-header.component.less'],
})
export class FsHeaderComponent implements OnInit {
  isCollapsed: boolean = false;

  arrowUp = faArrowUp;
  arrowDown = faArrowDown;

  constructor() {}

  ngOnInit(): void {}

  collapseHeader() {
    this.isCollapsed = !this.isCollapsed;
  }
}
