import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { uuid } from 'uuid';
import * as _ from 'lodash-es';

import { UserGroupAttributes } from '~app/models/user-group';
import { PersonAttributes } from '~app/models/person';
import { UserGroupService } from '~app/services/user-group.service';
import { RolePermissionService } from '~app/services/role-permission.service';
import { BooleanInput } from '@angular/cdk/coercion';
import { FormsUtility } from '~app/utilities/';

const supervisorErrorMessage =
  'This is Required.  Choose at least one Supervisor who is not also a Team Member';
const teammemberErrorMessage =
  'This is Required.  Choose at least one Team Member who is not also a Supervisor';
const supervisorErrorSet = { atLeastOne: supervisorErrorMessage };
const teammemberErrorSet = { atLeastOne: teammemberErrorMessage };

@Component({
  selector: 'ciao-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.less'],
})
export class UserGroupFormComponent implements OnInit {
  @Input() isEditForm: BooleanInput = false;
  @Input() canEditUserGroup = false;
  @Input() canEditTeamMembers = false;
  @Input() canEditSupervisors = false;
  @Input() canViewTeamMembers = false;
  userGroupId: uuid = null;

  regionStation: UserGroupAttributes;
  forestLab: UserGroupAttributes;

  private currentUserGroupSubscription: Subscription;
  private readonly subscriptions: Subscription = new Subscription();

  get dataNew(): Partial<FormData> {
    return this.userGroupFormGroup.value;
  }

  get allowNavigate(): boolean {
    return !this.userGroupFormGroup.dirty;
  }

  get allowSave(): boolean {
    return this.userGroupFormGroup.valid && this.membersFormGroup.valid;
  }
  userGroupControls = {
    id: new FormControl(''),
    label: new FormControl('', [
      Validators.required,
      FormsUtility.isOverCharLimit,
    ]),
    tier: new FormControl(3, {
      validators: [Validators.required],
      nonNullable: true,
    }),
    tierLabel: new FormControl('Team', { nonNullable: true }),
    description: new FormControl(''),
    pointOfContact: new FormControl('', [
      Validators.required,
      FormsUtility.isOverCharLimit,
    ]),
    contactEmail: new FormControl('', [Validators.required, Validators.email]),
    parentId: new FormControl(''),
    forestLab: new FormControl('', Validators.required),
    regionStation: new FormControl('', Validators.required),
    parent: new FormControl<UserGroupAttributes>(null),
  };
  membersControls = {
    nextTeamMember: new FormControl<PersonAttributes>(null),
    nextSupervisor: new FormControl<PersonAttributes>(null),
    listTeamMember: new FormControl<PersonAttributes[]>([], {
      validators: Validators.required,
      nonNullable: true,
    }),
    listSupervisor: new FormControl<PersonAttributes[]>([], {
      validators: Validators.required,
      nonNullable: true,
    }),
  };
  userGroupFormGroup = new FormGroup(this.userGroupControls);
  membersFormGroup = new FormGroup(this.membersControls);

  initialSupervisors: PersonAttributes[] = [];
  initialTeamMembers: PersonAttributes[] = [];

  readonly defaultData: UserGroupAttributes = {
    id: '',
    label: '',
    tier: 3,
    tierLabel: 'Team',
    description: '',
    pointOfContact: '',
    contactEmail: '',
    parentId: '',
    regionStation: null,
    forestLab: null,
    parent: null,
  };

  readonly allRegionsOptions$: Observable<{ label: string; value: uuid }[]>;
  readonly allForestLabOptions$: Observable<{ label: string; value: uuid }[]>;

  constructor(
    private userGroupService: UserGroupService,
    private rolePermissionService: RolePermissionService
  ) {
    const { regionOptions$, forestOptions$ } =
      this.getParentGroupDropdownOptions();
    this.allRegionsOptions$ = regionOptions$;
    this.allForestLabOptions$ = forestOptions$;
  }

  ngOnInit(): void {
    this.membersControls.listSupervisor.addValidators((control) => {
      let errors = null;
      if (control.value?.length < 1) {
        errors = supervisorErrorSet;
      }
      this.membersControls.nextSupervisor.setErrors(errors);
      return errors;
    });
    this.membersControls.listTeamMember.addValidators((control) => {
      let errors = null;
      if (control.value?.length < 1) {
        errors = teammemberErrorSet;
      }
      this.membersControls.nextTeamMember.setErrors(errors);
      return errors;
    });
    let s1 = this.membersControls.nextSupervisor.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value),
        tap((person) => {
          let arr = this.membersControls.listSupervisor
            .value as PersonAttributes[];
          arr.push(person);
          arr = arr.filter(
            (value, index, self) =>
              self.findIndex((person) => person.id === value.id) === index
          );
          this.membersControls.listSupervisor.setValue(arr);
        }),
        delay(0),
        tap(() => this.membersControls.nextSupervisor.setValue(null))
      )
      .subscribe();
    let s2 = this.membersControls.nextTeamMember.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => !!value),
        tap((person) => {
          let arr = this.membersControls.listTeamMember
            .value as PersonAttributes[];
          arr.push(person);
          arr = arr.filter(
            (value, index, self) =>
              self.findIndex((person) => person.id === value.id) === index
          );
          this.membersControls.listTeamMember.setValue(arr);
        }),
        delay(0),
        tap(() => this.membersControls.nextTeamMember.setValue(null))
      )
      .subscribe();

    this.subscriptions.add(s1);
    this.subscriptions.add(s2);

    this.refreshUserGroup(null);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.currentUserGroupSubscription?.unsubscribe();
  }

  refreshUserGroup(userGroupId: uuid) {
    this.userGroupFormGroup.reset();
    this.membersFormGroup.reset();
    this.userGroupId = userGroupId;
    let obs: Observable<
      [UserGroupAttributes, { [roleId: string]: PersonAttributes[] }]
    >;
    if (userGroupId) {
      let userGroup$ = this.userGroupService.findById(userGroupId);
      let getMembers$ = this.userGroupService.getMembersByRole(userGroupId, [
        'team_member',
        'supervisor',
      ]);
      // on refresh set the
      obs = combineLatest([userGroup$, getMembers$]);
    } else {
      obs = of([this.defaultData, {}]);
    }

    obs
      .pipe(
        take(1),
        delay(100),
        tap(([userGroup, memberLists]) => {
          let userGroupForm = _.mergeWith(
            {},
            this.defaultData,
            this.convertObjectsToData(userGroup),
            (a, b) => (b === null ? a : undefined)
          );
          this.userGroupFormGroup.setValue(userGroupForm);
          this.userGroupFormGroup.markAsPristine();
          this.userGroupFormGroup.markAsUntouched();
          this.initialSupervisors = memberLists['supervisor'] ?? [];
          this.initialTeamMembers = memberLists['team_member'] ?? [];
          this.membersFormGroup.patchValue({
            listSupervisor: this.initialSupervisors.slice(),
            listTeamMember: this.initialTeamMembers.slice(),
          });
        })
      )
      .subscribe();
  }

  getParentGroupDropdownOptions() {
    const adminGroups$ =
      this.rolePermissionService.searchMyUserGroupsByPermission({
        permissionIds: ['create_child_usergroup'],
      });
    const ShowUserGroups$ = combineLatest([
      adminGroups$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
    ]).pipe(
      map(([adminGroups, allRegions, allForests]) => {
        const showItems: { [id: string]: true } = {};
        adminGroups.forEach((group) => {
          if (group.tierLabel === 'Region') {
            showItems[group.id] = true;
            group.children.forEach((child) => (showItems[child.id] = true));
          } else if (group.tierLabel === 'ForestLab') {
            showItems[group.id] = true;
            showItems[group.parentId] = true;
          }
        });
        const showRegions = allRegions.filter((group) => showItems[group.id]);
        const showForests = allForests.filter((group) => showItems[group.id]);
        return { showRegions, showForests };
      }),
      shareReplay(1)
    );

    const op_mapToOptions = map((groups: UserGroupAttributes[]) =>
      groups.map((group) => ({
        label: group.labelPrefix + group.label,
        value: group.id,
      }))
    );

    const regionOptions$ = ShowUserGroups$.pipe(
      pluck('showRegions'),
      op_mapToOptions
    );
    const regionStationId$ =
      this.userGroupControls.regionStation.valueChanges.pipe(
        startWith(this.userGroupControls.regionStation.value)
      );
    const forestOptions$ = combineLatest([
      regionStationId$,
      ShowUserGroups$,
    ]).pipe(
      map(([rsGroupId, { showForests }]) =>
        showForests.filter((forest) => forest.parentId === rsGroupId)
      ),
      op_mapToOptions
    );
    return { regionOptions$, forestOptions$ };
  }

  removePersonFromList(person: PersonAttributes, listControl: FormControl) {
    let arr = listControl.value as PersonAttributes[];
    arr = arr.filter((item) => item.id !== person.id);
    listControl.setValue(arr);
  }

  GetRoleDifferences(userGroupId: uuid) {
    let modifiedRoles = [];
    if (this.canEditSupervisors) {
      let setSupervisorsTo: PersonAttributes[] =
        this.membersControls.listSupervisor.value;
      setSupervisorsTo.forEach((member) => {
        if (
          !this.initialSupervisors.find((initial) => member.id === initial.id)
        ) {
          modifiedRoles.push({
            action: 'Add',
            PersonId: member.id,
            RoleId: 'supervisor',
            UserGroupId: userGroupId,
          });
        }
      });
      this.initialSupervisors.forEach((member) => {
        if (!setSupervisorsTo.find((initial) => member.id === initial.id)) {
          modifiedRoles.push({
            action: 'Remove',
            PersonId: member.id,
            RoleId: 'supervisor',
            UserGroupId: userGroupId,
          });
        }
      });
    }
    if (this.canEditTeamMembers) {
      let setTeamMembersTo: PersonAttributes[] =
        this.membersControls.listTeamMember.value;
      setTeamMembersTo.forEach((member) => {
        if (
          !this.initialTeamMembers.find((initial) => member.id === initial.id)
        ) {
          modifiedRoles.push({
            action: 'Add',
            PersonId: member.id,
            RoleId: 'team_member',
            UserGroupId: userGroupId,
          });
        }
      });
      this.initialTeamMembers.forEach((member) => {
        if (!setTeamMembersTo.find((initial) => member.id === initial.id)) {
          modifiedRoles.push({
            action: 'Remove',
            PersonId: member.id,
            RoleId: 'team_member',
            UserGroupId: userGroupId,
          });
        }
      });
    }

    return modifiedRoles;
  }

  saveFormData() {
    let comp = this;
    let data = comp.userGroupFormGroup.value as Required<FormData>;
    let userGroupAttributes = this.convertDataToAttr(data);
    let saveUserGroup$: Observable<UserGroupAttributes>;
    let userGroupId$: Observable<string>;

    if (this.canEditUserGroup) {
      saveUserGroup$ = this.userGroupService.saveUserGroup(userGroupAttributes);
    } else {
      saveUserGroup$ = of(userGroupAttributes);
    }
    userGroupId$ = saveUserGroup$.pipe(
      take(1),
      map((userGroup) => userGroup.id),
      switchMap((id) => {
        //GetRoleDifferences includes limitations for if the user can/cannot edit.
        let differences = this.GetRoleDifferences(id);
        if (differences.length === 0) {
          return of(id);
        }
        let operations = differences.map((change) =>
          change.action === 'Add'
            ? this.rolePermissionService.assignRole$(change)
            : this.rolePermissionService.unassignRole$(change)
        );
        let operationBulk = combineLatest(operations).pipe(map((_) => id));
        return operationBulk;
      }),
      tap((id) => {
        this.refreshUserGroup(id);
      }),
      catchError((err, caught) => {
        this.refreshUserGroup(this.userGroupId);
        throw err;
      })
    );
    return userGroupId$;
  }

  deleteData() {
    if (this.userGroupFormGroup.dirty) {
      throw new Error(
        'There are unsaved changes.  Either refresh the data, or save.'
      );
    }
    // Delete user group
    let userGroupDel$ = this.userGroupService.deleteUserGroup(this.userGroupId);
    return userGroupDel$;
  }
  convertDataToAttr(data: FormData): UserGroupAttributes {
    if (data.forestLab) {
      data.tier = 3;
      data.parentId = data.forestLab;
    } else {
      data.tier = 2;
      data.parentId = data.regionStation;
    }

    let userGroup = {
      id: data.id || undefined,
      label: data.label,
      tier: data.tier,
      tierLabel: data.tierLabel || 'Team',
      description: data.description,
      pointOfContact: data.pointOfContact,
      contactEmail: data.contactEmail,
      parentId: data.parentId || null,
      parent: data.parent || null,
    };
    return userGroup;
  }

  convertObjectsToData(userGroup?: UserGroupAttributes): FormData {
    let regionStation = null;
    let forestLab = null;
    if (userGroup.tier == 3) {
      regionStation = userGroup.parent?.parentId;
      forestLab = userGroup.parentId;

      // set view only variables
      this.regionStation = userGroup.parent?.parent;
      this.forestLab = userGroup.parent;
    } else {
      regionStation = userGroup.parentId;
      forestLab = null;

      // set view only variabled
      this.regionStation = userGroup.parent;
      this.forestLab = null;
    }

    return {
      id: userGroup?.id || undefined,
      label: userGroup?.label || '',
      tier: userGroup?.tier ?? undefined,
      tierLabel: userGroup?.tierLabel || 'Team', //same as tier value, this will have to be changed, but for now all new user groups are "teams"
      description: userGroup?.description || '',
      pointOfContact: userGroup?.pointOfContact || '',
      contactEmail: userGroup?.contactEmail || '',
      parentId: userGroup?.parentId || null,
      regionStation: regionStation,
      forestLab: forestLab,
      parent: userGroup?.parent || null,
    };
  }
}
type FormData = Required<UserGroupFormComponent['userGroupFormGroup']['value']>;
