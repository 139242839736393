import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { PersonAttributesSlim } from '~app/models';
import { PersonService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';

@Component({
  selector: 'style-guide-form-controls',
  templateUrl: './style-guide-form-controls.component.html',
  styleUrls: ['./style-guide-form-controls.component.less'],
})
export class StyleGuideFormControlsComponent implements OnInit, OnDestroy {
  somePeople: PersonAttributesSlim[];
  randomRegionRoleSelect: any;
  subscriptions: Subscription = new Subscription();
  constructor(
    private personService: PersonService,
    private userGroupService: UserGroupService
  ) {
    let oldDate = new Date();
    oldDate.setDate(oldDate.getDate() - 10);
    this.miscellaneousControls.patchValue({
      date: oldDate,
      dateRange: {
        from: oldDate.toISOString(),
        to: oldDate.toISOString().slice(0, 10),
      },
    });
  }

  ngOnInit(): void {
    let subscriptions = [];
    subscriptions[0] = this.personService.allPeople
      .pipe(tap((people) => (this.somePeople = people)))
      .subscribe();
    /* Forms */
    subscriptions[1] = this.myFormGroup.valueChanges
      .pipe(
        startWith(this.myFormGroup.value),
        tap(() => this.formDebug2Count++),
        //tap(() => console.log('formDebug Setter count', this.formDebug2Count)),
        tap((value) => {
          this.formDebug2 = JSON.stringify({ data: value }, null, 2);
        })
      )
      .subscribe();
    subscriptions[2] = this.dropdownControls.valueChanges
      .pipe(
        startWith(this.dropdownControls.value),
        tap((value) => {
          this.dropdownResults = JSON.stringify({ data: value }, null, 2);
        })
      )
      .subscribe();
    subscriptions[3] = this.userGroupService.allRegions$
      .pipe(
        tap(
          (regions) =>
            (this.randomRegionRoleSelect = [
              { userGroupId: regions[3]?.id, roleId: 'region_admin' },
              { userGroupId: regions[8]?.id, roleId: 'region_admin' },
              { userGroupId: regions[5]?.id, roleId: 'region_admin' },
            ])
        ),
        map(
          (regions) =>
            [].concat(
              ...regions.map((region) => [
                {
                  label: `${region.label} - Admin`,
                  value: { userGroupId: region.id, roleId: 'region_admin' },
                  disabled: Math.random() > 0.5, //NOSONAR
                },
              ])
            ) as SelectOption[]
        ),
        tap((values) => (this.dropdownOptionSets.region_roles$ = of(values)))
      )
      .subscribe();
    subscriptions.forEach((sub) => this.subscriptions.add(sub));
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }
  /* Forms */
  myFormGroup = new UntypedFormGroup({
    secondGroup: new UntypedFormGroup({
      controlA: new UntypedFormControl(false),
      controlB: new UntypedFormControl('gobldely'),
      controlC: new UntypedFormControl('gook'),
      controlD: new UntypedFormControl('c'),
      controlE: new UntypedFormControl('false'),
      controlF: new UntypedFormControl(''),
      controlG: new UntypedFormControl(''),
      controlH: new UntypedFormControl(''),
    }),
    controlI: new UntypedFormControl(''),
    controlJ: new UntypedFormControl(''),
    controlK: new UntypedFormControl(''),
    controlL: new UntypedFormControl(''),
  });
  dropdownOptionSets = dropdownOptionSets;
  autocompleteOptionSets = autocompleteOptionSets;

  dropdownControls = new UntypedFormGroup({
    A: new UntypedFormControl(''),
    B: new UntypedFormControl(''),
    C: new UntypedFormControl(''),
    D: new UntypedFormControl(''),
    E: new UntypedFormControl(''),
    F: new UntypedFormControl(''),
  });
  miscellaneousControls = new UntypedFormGroup({
    checkbox: new UntypedFormControl(false),
    input: new UntypedFormControl(''),
    gibberish: new UntypedFormControl(''),
    date: new UntypedFormControl(new Date().toISOString().slice(0, 10)),
    dateRange: new UntypedFormControl({
      to: '',
      from: '',
    }),
    dateTime: new UntypedFormControl(new Date().toISOString()),
    dateTimeNative: new UntypedFormControl(new Date().toISOString()),
    latLong: new UntypedFormControl(null),
  });
  dropdownResults: string = '';

  RegionRoleCompare(c1, c2) {
    return c1.userGroupId === c2.userGroupId && c1.roleId === c2.roleId;
  }
  RegionRoleCustomTrigger(values: any[]) {
    if (!(values instanceof Array)) {
      return;
    }
    let regionAdminCount = values.filter(
      (rr) => rr.roleId === 'region_admin'
    ).length;
    return [regionAdminCount ? `${regionAdminCount} Admin Roles` : '']
      .filter((x) => x)
      .join(' + ');
  }

  FillDropdownsWithBadData() {
    this.dropdownControls.setValue({
      A: 'gibberish',
      B: 'gibberish',
      C: 'gibberish',
      D: 'gibberish',
      E: 'gibberish',
      F: 'gibberish',
    });
  }
  FillDropdownsWithGoodData() {
    this.dropdownControls.setValue({
      A: 'a',
      B: this.somePeople[1]?.id,
      C: this.somePeople[2],
      D: 'd',
      E: 'epsilon',
      F: this.randomRegionRoleSelect,
    });
  }
  get formDebug1() {
    /* Do Not Use This Style in production !!!  Prefer observable subscriptions */
    this.formDebug1Count++;
    return JSON.stringify({ data: this.myFormGroup.value }, null, 2);
  }
  formDebug2: string;
  formDebug1Count: number = 0;
  formDebug2Count: number = 0;
}

const autocompleteOptionSets = {
  simpleLowerCase$: of(['alpha', 'beta', 'gamma', 'delta', 'epsilon']),
  simpleUpperCase$: of(['Alpha', 'Bravo', 'Charlie', 'Delta', 'Echo']),
};

const dropdownOptionSets = {
  simpleLowerCase$: of([
    { value: 'a', label: 'alpha' },
    { value: 'b', label: 'beta' },
    { value: 'c', label: 'gamma' },
    { value: 'd', label: 'delta' },
    { value: 'e', label: 'epsilon' },
  ]),
  simpleUpperCase$: of([
    { value: 'a', label: 'Alpha' },
    { value: 'b', label: 'Bravo' },
    { value: 'c', label: 'Charlie' },
    { value: 'd', label: 'Delta' },
    { value: 'e', label: 'Echo' },
  ]),
  region_roles$: of([
    {
      label: 'R1 Role 1',
      value: {
        userGroupId: '11111111-34fd-4ca1-a59e-31662f31d4fd',
        roleId: '',
      },
    },
  ]),
};

type SelectOption = {
  label: string;
  value: string | any;
};
