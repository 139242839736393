import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { PersonService, TripService } from '~services';
import { UserGroupService } from '~services/user-group.service';
import { TripAttributes, PersonAttributesSlim } from '~models';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  map,
  tap,
  switchMap,
  startWith,
  debounceTime,
  shareReplay,
  distinctUntilChanged,
  share,
  catchError,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { SortingCriteria } from '~app/components/report-table/types';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RolePermissionService } from '~app/services/role-permission.service';
import {
  IsLoadingPaginationResult,
  PaginationData,
  PaginationResult,
} from '~app/models/pagination-data';
import * as _ from 'lodash-es';
import { UserGroupAttributes } from '~app/models/user-group';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  tripsPaginationOperator$: (
    input: Observable<PaginationData>
  ) => Observable<PaginationResult<TripAttributes>>;

  // Form Field
  continuousFilterControls = {
    searchText: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl(null),
  };
  continuousFilterGroup = new UntypedFormGroup(this.continuousFilterControls);
  /**
   * @TODO Update backend to allow for more options
   * - add expectedReturnRange
   * - add crewMembers
   * - add supervisor
   * - add location
   * - make tripStatus accept many
   */
  filterModalControls = {
    searchText: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl(null),
    tripStatus: new UntypedFormControl(''),
    imNamedOnThisTrip: new UntypedFormControl(false),
  };
  filterModalGroup = new UntypedFormGroup(this.filterModalControls);
  filterData$ = new BehaviorSubject({
    searchText: '',
    userGroupId: [],
    tripStatus: '',
    imNamedOnThisTrip: false,
  });

  teamSelectionOptions$: Observable<{ label: string; value: string }[]>;
  sortingBy$: BehaviorSubject<SortingCriteria<TripAttributes>[]> =
    new BehaviorSubject([
      { sortId: 'tripStatus', order: 'ASC' },
      { sortId: 'endDate', order: 'ASC' },
    ]);

  paginationModalControls = new UntypedFormGroup({
    limit: new UntypedFormControl(0),
    offset: new UntypedFormControl(0),
    order: new UntypedFormControl([]),
  });

  get paginationMessage() {
    return this.filterData$.value.searchText
      ? `matching '${this.filterData$.value.searchText}'`
      : '';
  }

  paginationResult$: Observable<PaginationResult<TripAttributes>>;

  statusFilterOptions$: Observable<{ value: string; label: string }[]>;
  crewMemberFilterOptions$: Observable<
    { value: PersonAttributesSlim; label: string }[]
  >;
  supervisorFilterOptions$: Observable<
    { value: PersonAttributesSlim; label: string }[]
  >;
  userGroupFilterOptions$: Observable<
    { value: UserGroupAttributes; label: string }[]
  >;

  paginationData$: Observable<PaginationData>;
  subscriptions = new Subscription();

  constructor(
    private tripService: TripService,
    private rolePermissionService: RolePermissionService,
    private userGroupService: UserGroupService,
    private personService: PersonService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const myUserGroups$ =
      this.rolePermissionService.searchMyUserGroupsByPermission({
        permissionIds: ['view_trips'],
      });
    this.teamSelectionOptions$ = myUserGroups$.pipe(
      this.userGroupService.op_sortGroups,
      map((groups) =>
        groups.map((group) => ({
          label: `${group.labelPrefix} - ${group.label}`,
          value: group.id,
        }))
      ),
      share()
    );
    this.subscriptions.add(
      this.continuousFilterGroup.valueChanges
        .pipe(
          debounceTime(500),
          distinctUntilChanged((x, y) => _.isEqual(x, y)),
          map((value) => {
            this.filterModalGroup.patchValue(value);
            this.applyFilters();
          })
        )
        .subscribe()
    );
    this.paginationData$ = combineLatest([
      this.paginationModalControls.valueChanges.pipe(
        startWith(this.paginationModalControls.value)
      ),
      this.filterData$,
      this.sortingBy$,
    ]).pipe(
      distinctUntilChanged((x, y) => _.isEqual(x, y)),
      map(([paginationFormData, filterFormData, sortingBy]) => ({
        //pagination form data
        limit: paginationFormData.limit,
        offset: paginationFormData.offset,
        // filter form data
        searchText: filterFormData.searchText,
        userGroupId: filterFormData.userGroupId,
        tripStatus: filterFormData.tripStatus,
        imNamedOnThisTrip: filterFormData.imNamedOnThisTrip,

        order: sortingBy.map((sort) => [sort.sortId, sort.order]) as [
          string,
          ('ASC' | 'DESC')?
        ][],
      })),
      map(
        ({
          searchText,
          userGroupId,
          tripStatus,
          imNamedOnThisTrip,
          limit,
          offset,
          order,
        }) => {
          let where: [string, any][] = [];
          if (searchText) {
            where.push(['searchText', searchText]);
          }
          if (userGroupId?.length) {
            where.push(['userGroupId', userGroupId]);
          }
          if (tripStatus) {
            where.push(['tripStatus', tripStatus]);
          }
          if (imNamedOnThisTrip) {
            where.push(['imNamedOnThisTrip', '']);
          }

          return { where, limit, offset, order } as PaginationData;
        }
      )
    );

    this.paginationResult$ = combineLatest([
      this.paginationData$,
      this.userGroupService.allUserGroups$,
      this.tripService.checkForRefreshOnInterval$,
    ]).pipe(
      // Also I guess listen to a search button press maybe?  Except there is no submit/search button.
      map(([paginationData, userGroups]) => {
        return {
          paginationData,
          userGroups,
        };
      }),
      switchMap(({ paginationData, userGroups }) =>
        this.tripService.search(paginationData).pipe(
          tap((paginationResult) => {
            paginationResult.rows.forEach(
              (row) =>
                (row.userGroup = userGroups.find(
                  (group) => row.userGroupId === group.id
                )) && row.endDate
            );
          }),
          startWith(IsLoadingPaginationResult<TripAttributes>()),
          catchError((err, caught) => {
            return of() as typeof caught;
          })
        )
      ),

      share()
    );

    // bring in crewmembers and supervisors for filter dropwdowns

    /**
     * @todo Filter crewmember/supervisor lists
     * -> Crewmembers/Supervisors on the teams that I can view trips for.
     */
    let crewMemberAndSupervisorList$ = this.personService.allPeople.pipe(
      map((people) =>
        people.map((person) => ({
          value: person,
          label: person.displayName,
        }))
      )
    );

    // Crew member list
    this.crewMemberFilterOptions$ = crewMemberAndSupervisorList$;
    // Supervisor list
    this.supervisorFilterOptions$ = crewMemberAndSupervisorList$;

    // Team list
    this.userGroupFilterOptions$ = this.userGroupService.allTeams$.pipe(
      this.userGroupService.op_sortGroups,
      map((groups) =>
        groups.map((group) => {
          return { value: group, label: group.label };
        })
      ),
      map((groups) => groups.slice()),
      shareReplay(1)
    );
    // Status List
    this.statusFilterOptions$ = of([
      '',
      'Closed',
      'Late',
      'Open',
      'Planned',
    ]).pipe(
      map((statuses) =>
        statuses.map((status) => ({
          value: status,
          label: status || 'All Statuses',
        }))
      )
    );
  }

  clearFilters() {
    this.filterModalGroup.setValue({
      searchText: '',
      userGroupId: [],
      tripStatus: '',
      imNamedOnThisTrip: false,
    });
    this.applyFilters();
  }
  applyFilters() {
    this.continuousFilterGroup.patchValue(this.filterModalGroup.value, {
      emitEvent: false,
    });
    this.filterData$.next(this.filterModalGroup.value);
    this.filterModalGroup.markAsPristine();
  }
  resetFilters() {
    this.filterModalGroup.setValue(this.filterData$.value, {
      emitEvent: false,
    });
    this.continuousFilterGroup.patchValue(this.filterModalGroup.value, {
      emitEvent: false,
    });
  }

  openNewTripModal = () => {
    this.router.navigate(['trip/new']);
  };

  goToEditTrip(trip: TripAttributes) {
    this.router.navigate(['trip', 'id', trip.id]);
  }

  allCrewCheckedIn(trip: TripAttributes) {
    return trip.crewMembersProcessed.every(
      (crewMember) => crewMember?.crewMemberStatus === 'CheckedIn'
    );
  }
}
