import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { v4 } from 'uuid';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BooleanInput } from '@angular/cdk/coercion';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'ciao-modal',
  templateUrl: './ciao-modal.component.html',
  styleUrls: ['./ciao-modal.component.less'],
})
export class CiaoModalComponent implements OnInit, OnDestroy {
  console = console;
  @Input('title') title: string;
  @Input() preventBackdropClosing: BooleanInput;
  @Input() hideCloseButton: BooleanInput;
  /** Set this to true to prevent user from closing the modal using the default close button.  Bind closeAction to listen for the close button click. */
  @Input() alternateCloseAction: BooleanInput;
  @Input() loading: boolean;
  componentId: string = v4();

  @ViewChild('content') private content;

  @Output('modalInstance') modalInstance = new EventEmitter<NgbModalRef>();
  private _modalInstance: NgbModalRef;

  /** Emitted when the modal successfully closes by any means */
  @Output('result') result = new EventEmitter<string>();
  /** Emitted when the user attempts to close a modal with alternateCloseAction enabled */
  @Output('closeAction') closeAction = new EventEmitter<boolean>();

  subscriptions = new Subscription();
  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event: NavigationStart) => event instanceof NavigationStart),
          tap((_) => this.dismiss('Navigated Away'))
        )
        .subscribe()
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  close(message: string): void {
    this._modalInstance?.close(message);
  }
  dismiss(message: string): void {
    this._modalInstance?.dismiss(message);
  }

  handleCloseAction(modal) {
    if (this.alternateCloseAction) {
      this.closeAction.emit(true);
    } else {
      modal.dismiss('x clicked');
    }
  }

  openModal(): NgbModalRef {
    this._modalInstance = this.modalService.open(this.content, {
      backdrop:
        this.preventBackdropClosing || this.alternateCloseAction
          ? 'static'
          : true,
      ariaLabelledBy: this.componentId,
    });
    this.modalInstance.emit(this._modalInstance);
    this._modalInstance.result
      .then(
        (val) => val,
        (err) => err
      )
      .then((val) => this.result.emit(val))
      .then(() => this.modalInstance.emit(null));
    return this._modalInstance;
  }
  openSmallModal(): NgbModalRef {
    this._modalInstance = this.modalService.open(this.content, {
      backdrop: this.preventBackdropClosing ? 'static' : true,
      ariaLabelledBy: this.componentId,
      windowClass: 'small-modal',
    });
    this.modalInstance.emit(this._modalInstance);
    this._modalInstance.result.finally(() => this.modalInstance.emit(null));
    return this._modalInstance;
  }
}
