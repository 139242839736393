import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SortingCriteria } from '../types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'th[ciaoSortableHeader]',
  templateUrl: './sortable-header.component.html',
  styleUrls: ['./sortable-header.component.less'],
})
export class SortableHeaderComponent implements OnChanges {
  @Input() sortingBy$: BehaviorSubject<SortingCriteria<any>[]> =
    new BehaviorSubject([]);
  @Input() sortId: string;
  @Input() keepSort = 1;

  @Output() sortBy: EventEmitter<SortingCriteria<any>[]> = new EventEmitter();

  materialSortIcon$: Observable<string> = of('unfold_more');
  @HostBinding('aria-sort') ariaSort: 'ascending' | 'descending' | null = null;

  constructor() {}

  ngOnChanges() {
    this.setSortDirection();
  }

  onClick(event: MouseEvent) {
    let sortingBy = this.sortingBy$.value.slice();
    let index = sortingBy.findIndex((item) => item.sortId === this.sortId);

    switch (index) {
      case -1:
        // Add Order (ASC)
        sortingBy.unshift({ sortId: this.sortId, order: 'ASC' });
        break;
      case 0:
        // Change Existing Order (make DESC or remove)
        if (sortingBy[0].order === 'ASC') {
          sortingBy[0] = { sortId: this.sortId, order: 'DESC' };
        } else {
          sortingBy.shift();
        }
        break;
      default:
        // Move order to beginning of list.  Set to ASC.
        sortingBy.splice(index, 1);
        sortingBy.unshift({ sortId: this.sortId, order: 'ASC' });
        break;
    }

    // Truncate list to maximum of keepsort length.
    sortingBy.splice(this.keepSort);
    this.sortBy.emit(sortingBy);
    this.sortingBy$.next(sortingBy);
  }

  setSortDirection() {
    let sortDirection = this.sortingBy$.pipe(
      map((sortingBy) => {
        let order = sortingBy.find(
          (item) => item.sortId === this.sortId
        )?.order;
        return order === 'ASC' || order === 'DESC' ? order : 'NONE';
      }),
      shareReplay(1)
    );

    this.materialSortIcon$ = sortDirection.pipe(
      delay(0), // Delay 0ms to avoid NG0100: Expression has changed after it was checked
      tap((direction) => {
        if (direction === 'ASC') {
          this.ariaSort = 'ascending';
        } else if (direction === 'DESC') {
          this.ariaSort = 'descending';
        } else {
          this.ariaSort = null;
        }
      }),
      map(
        (direction) =>
          ({
            NONE: 'unfold_more',
            ASC: 'keyboard_arrow_up',
            DESC: 'keyboard_arrow_down',
          }[direction])
      )
    );
  }
}
