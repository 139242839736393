<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" [id]="componentId">{{ title }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="handleCloseAction(modal)"
      [disabled]="hideCloseButton"
      [hidden]="hideCloseButton"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <ng-container class="modal-header">
    <span #modalNav>
      <ng-content select="[modalNav]"></ng-content>
    </span>
  </ng-container>
  <div class="modal-body">
    <span #modalBody>
      <ng-content select="[modalBody]"></ng-content>
    </span>
    <ng-template [ngIf]="modalFooterInput.childNodes.length === 0">
      <p>There is no content in this modal.</p>
    </ng-template>
  </div>
  <div class="modal-footer">
    <span #modalFooterInput>
      <ng-content select="[modalFooter]"></ng-content>
    </span>
    <ng-template [ngIf]="modalFooterInput.childNodes.length === 0">
      <button class="btn" (click)="modal.dismiss('cancel')">Cancel</button>
      <button class="btn-action" (click)="modal.dismiss('confirm')">
        Confirm
      </button>
    </ng-template>
  </div>
  <div class="loading-overlay" *ngIf="loading">
    <ciao-loading></ciao-loading>
  </div>
</ng-template>
