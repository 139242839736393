import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TripModalFormComponent } from '../types';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CrewMemberMetaAttributes,
  PersonAttributes,
  TripAttributes,
} from '~app/models';
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  pluck,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { UserGroupService } from '~app/services/user-group.service';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { AuthService, PersonService } from '~app/services';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { FormsUtility } from '~app/utilities';

@Component({
  selector: 'ciao-crew-form',
  templateUrl: './crew-form.component.html',
  styleUrls: ['./crew-form.component.less'],
})
export class CrewFormComponent
  implements
    OnInit,
    OnChanges,
    OnDestroy,
    TripModalFormComponent<CrewMemberMetaAttributes>
{
  @Input() editingExisting: boolean;
  @Input() trip: TripAttributes;
  @Input() set userGroupId(value: string) {
    this.userGroupId$.next(value);
  }
  userGroupId$ = new BehaviorSubject<string>(null);

  formGroup = new FormGroup({
    id: new FormControl(''),
    crewMember: new FormControl<PersonAttributes>(null, [
      Validators.required,
      this.crewValidator.bind(this),
    ]),
    supervisor: new FormControl<PersonAttributes>(null, [
      Validators.required,
      this.supervisorValidator.bind(this),
    ]),
    fsCellPhone: new FormControl('', [Validators.required]),
    supervisorPhone: new FormControl('', [Validators.required]),
    satPhone: new FormControl(''),
    satLocatorId: new FormControl('', FormsUtility.isOverCharLimit),
    notes: new FormControl(''),
  });

  get finalResult(): CrewMemberMetaAttributes {
    return this.formGroup.value;
  }

  readonly crewMemberAndSupervisorList$ = this.userGroupId$.pipe(
    filter((x) => !!x),
    switchMap((userGroupId) =>
      this.userGroupService.getCrewMemberDropdownLists(userGroupId)
    )
  );
  readonly crewMemberOptions$ = this.crewMemberAndSupervisorList$.pipe(
    map((listByRoles) => listByRoles.crew_member),
    shareReplay(1)
  );

  readonly supervisorOptions$ = this.crewMemberAndSupervisorList$.pipe(
    map((listByRoles) => listByRoles.supervisor),
    shareReplay(1)
  );

  helpTextActive = false;

  subscriptions = new Subscription();

  // font awesome
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  constructor(
    private userGroupService: UserGroupService,
    private authService: AuthService,
    private personService: PersonService
  ) {}

  ngOnInit(): void {
    this.setAutoValidateCrewSuper();
    this.setAutoFillPhoneSubscription();
    this.autofillSuperVisor();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.trip) {
      this.autofillCurrentUser();
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** Only autofill the current user if this is the first crew member. */
  autofillCurrentUser() {
    if (this.trip.crewMembers.length === 0) {
      let currentPerson$ = this.authService.currentUser$.pipe(
        map((currentUser) => currentUser?.person)
      );
      let sub = combineLatest([this.crewMemberOptions$, currentPerson$])
        .pipe(
          filter((_) => this.trip.crewMembers.length === 0),
          tap(([crewMemberOptions, currentPerson]) => {
            let currentPersonInCrewOptions = crewMemberOptions.find(
              (option) => option.value.id === currentPerson.id
            )?.value;
            // if team selected contains current user then patch vals
            if (currentPersonInCrewOptions) {
              this.formGroup.controls.crewMember.setValue(
                currentPersonInCrewOptions
              );
            }
          })
        )
        .subscribe();
      this.subscriptions.add(sub);
    }
  }

  autofillSuperVisor() {
    const supervisorControl = this.formGroup.get('supervisor');
    const crewControl = this.formGroup.get('crewMember');
    this.subscriptions.add(
      crewControl.valueChanges
        .pipe(
          startWith(crewControl.value),
          distinctUntilChanged(this.comparePersonForSelect),
          pairwise(),
          filter(
            ([oldCrew, newCrew]) =>
              !supervisorControl.value ||
              supervisorControl.value?.id === oldCrew?.defaultSupervisorId
          ),
          pluck(1, 'defaultSupervisorId'),
          switchMap((id) => {
            if (!id) {
              return of(null);
            }
            return this.supervisorOptions$.pipe(
              map(
                (options) =>
                  options.find((supe) => supe.value.id === id)?.value || null
              )
            );
          }),
          tap((supervisor) => {
            supervisorControl.setValue(supervisor);
          })
        )
        .subscribe()
    );
  }

  setAutoValidateCrewSuper() {
    const crewControl = this.formGroup.get('crewMember');
    const supervisorControl = this.formGroup.get('supervisor');
    let sub1 = crewControl.valueChanges
      .pipe(
        distinctUntilChanged(this.comparePersonForSelect),
        tap(() => supervisorControl.updateValueAndValidity())
      )
      .subscribe();
    let sub2 = supervisorControl.valueChanges
      .pipe(
        distinctUntilChanged(this.comparePersonForSelect),
        tap(() => crewControl.updateValueAndValidity())
      )
      .subscribe();
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }

  setAutoFillPhoneSubscription() {
    const singlePhoneObservable = (
      personControl: FormControl<PersonAttributes>,
      phoneControl: FormControl<string>
    ) => {
      //console.log(personControl, phoneControl);
      return personControl.valueChanges.pipe(
        startWith(personControl.value),
        // Only Update Phone when you've actually changed the crew member.
        distinctUntilChanged((a, b) => this.comparePersonForSelect(a, b)),
        // Only Update Phone if phone either matches crew member's phone or is blank.
        // Use pairwise to get the last two items to accomplish this.
        // str?.match(/\d/g)?.join('') removes any characters that aren't numeric.
        pairwise(),
        filter(
          ([oldPerson, newPerson]) =>
            !phoneControl.value ||
            phoneControl.value?.match(/\d/g)?.join('') ===
              oldPerson?.defaultPhone?.phoneNumber?.match(/\d/g)?.join('')
        ),
        // undo the pairwise.
        pluck(1),

        filter((input) => !!input),
        tap((person) => {
          phoneControl.setValue(person?.defaultPhone?.phoneNumber);
        })
      );
    };
    this.subscriptions.add(
      singlePhoneObservable(
        this.formGroup.controls.crewMember,
        this.formGroup.controls.fsCellPhone
      ).subscribe()
    );
    this.subscriptions.add(
      singlePhoneObservable(
        this.formGroup.controls.supervisor,
        this.formGroup.controls.supervisorPhone
      ).subscribe()
    );
  }

  stripPhoneNumber(phone: string) {
    return phone?.match(/\d/g)?.join('') || null;
  }

  // Fixed comparePersonForSelect.  {a: '', b: ''} should return true.
  comparePersonForSelect(a: any, b: any) {
    if (a === b) {
      return true;
    }
    if (a && b && a?.id === b?.id) {
      // compare the ids, but not if a or b is null.
      return true;
    }
    return false;
  }

  crewSupeValidatorSharedInput() {
    const crewMemberId = this.formGroup?.controls.crewMember.value?.id;
    const supervisorId = this.formGroup?.controls.supervisor.value?.id;
    const metaId = this.formGroup?.controls.id.value;
    // Don't count the meta item that this one comes from.
    const pastMetas = this.trip?.crewMembers.filter(
      (meta) => meta.id !== metaId
    );
    const pastCrewMemberIds = pastMetas?.map((meta) => meta.crewMember?.id);
    const pastSupervisorIds = pastMetas?.map((meta) => meta.supervisor?.id);
    return {
      crewMemberId,
      supervisorId,
      pastCrewMemberIds,
      pastSupervisorIds,
      crewEqualsSuper: crewMemberId === supervisorId,
    };
  }

  crewValidator(control: UntypedFormControl) {
    const info = this.crewSupeValidatorSharedInput();
    if (!info.crewMemberId) {
      return null;
    }
    const crewIsSuperInMeta = info.pastSupervisorIds?.includes(
      info.crewMemberId
    );

    if (info.crewEqualsSuper || crewIsSuperInMeta) {
      return { crewIsSupervisor: `Crew cannot be an existing supervisor` };
    } else {
      return null;
    }
  }

  supervisorValidator(control: UntypedFormControl) {
    const info = this.crewSupeValidatorSharedInput();
    if (!info.supervisorId) {
      return null;
    }
    const superIsCrewInMeta = info.pastCrewMemberIds?.includes(
      info.supervisorId
    );

    if (info.crewEqualsSuper || superIsCrewInMeta) {
      return {
        crewIsSupervisor: `Supervisor cannot be an existing crew member`,
      };
    } else {
      return null;
    }
  }
}
