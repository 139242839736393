<div class="info-page">
  <div class="info-nav">
    <ng-template ngFor let-mainLink [ngForOf]="MainSidebar">
      <div class="link-section">
        <div
          class="section-title"
          [ngClass]="{ active: navCurrentSection == mainLink.href }"
        >
          <a [routerLink]="mainLink.href">
            {{ mainLink.title }}
          </a>
        </div>
      </div>
      <hr />
    </ng-template>
  </div>

  <div class="info-content">
    <div class="breadcrumb">
      <ng-template
        ngFor
        let-crumb
        [ngForOf]="breadcrumbTrail"
        let-first="first"
      >
        <span class="divider" *ngIf="!first"> / </span>
        <a
          [routerLink]="crumb.href"
          [routerLinkActive]="'active-link'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ crumb.title }}
        </a>
      </ng-template>
    </div>
    <ng-container
      *ngIf="currentSection?.type.startsWith('custom/'); else topicTemplate"
    >
      <ciao-faqs *ngIf="currentSection.type === 'custom/faq'" />
      <ciao-version-history
        *ngIf="currentSection.type === 'custom/versionHistory'"
      />
    </ng-container>
    <ng-template #topicTemplate>
      <div
        class="topic"
        *ngIf="currentTopic || currentSection as topic; else defaultMessage"
      >
        <h1>{{ currentSection.title }}</h1>
        <ng-container *ngIf="topic.type.startsWith('topic/')">
          <div class="resource-block" *ngIf="topic.type === 'topic/image'">
            <img class="resource" [src]="topic.src" [alt]="topic.alt" />
          </div>
          <div class="resource-block" *ngIf="topic.type === 'topic/video'">
            <!--
            The videos are alternate resources to the KBAs and PDFs.
            We are not able to efficiently generate
              captioning and descriptions at this time.
            sonarlint(Web:S4084)
          -->
            <!-- //NOSONAR --><video
              class="resource"
              controls=""
              [poster]="topic.poster"
              [src]="topic.src"
            >
              {{ topic.alt }}
              Video Resource. See below for more information.
              <!-- This information is read by screenreaders -->
            </video>
          </div>
          <markdown
            class="resource-description"
            *ngIf="topic.description as description"
            [data]="description"
          >
          </markdown>

          <p class="extra-help">
            Can't access the links? Contact the FS Customer Help Desk at
            <a href="mailto:chd@usda.gov">chd&#64;usda.gov</a>
            or
            <a href="tel:1-866-945-1354">1-866-945-1354</a>
            <span class="nowrap" ngPreserveWhitespace>
              (TTY: <a href="tel:1-800-877-8339">1-800-877-8339</a>)
            </span>
          </p>
        </ng-container>
      </div>
    </ng-template>

    <div class="topic-sidebar" *ngIf="currentSidebar">
      <ul class="link-list">
        <li><p>Choose a topic to learn more</p></li>
        <li *ngFor="let item of currentSidebar">
          <h4 *ngIf="item.type === 'header'" class="link-block-header">
            {{ item.title }}
          </h4>
          <ng-container *ngIf="item.type === 'link'">
            <a *ngIf="!item.external" [routerLink]="item.href">
              {{ item.title }}
              <ng-container *ngTemplateOutlet="iconsInLink"></ng-container>
            </a>
            <a
              *ngIf="item.external"
              rel="noopener noreferrer"
              target="_blank"
              [href]="item.href"
            >
              {{ item.title }}
              <mat-icon>arrow_outward</mat-icon>
              <ng-container *ngTemplateOutlet="iconsInLink"></ng-container>
            </a>
            <ng-template #iconsInLink>
              <mat-icon
                *ngIf="item.icon === 'video'"
                [fontSet]="'material-icons-outlined'"
                >smart_display</mat-icon
              >
              <mat-icon
                *ngIf="item.icon === 'pdf'"
                [fontSet]="'material-icons-outlined'"
                >picture_as_pdf</mat-icon
              >
            </ng-template>
            <ciao-new-icon *ngIf="item.new" />
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #defaultMessage>Section not found</ng-template>
