import * as _ from 'lodash-es';
import { PaginationData } from '~app/models';
import { TextService } from '~app/services';

export interface SortingCriteria<T = any> {
  sortId: string | string[];
  order: 'ASC' | 'DESC';
  fn?: (a: T, b: T) => number;
}

export function sortingCriteriaToPaginationSortOrder(
  sortBy: SortingCriteria[]
) {
  return sortBy.map((criteria) => [
    criteria.sortId,
    criteria.order,
  ]) as PaginationData['order'];
}

export interface columnRenderInterface {
  (data: any, type: 'sort', row: any): number | string;
  (data: any, type: 'display' | 'filter', row: any): string;
  (data: any, type: 'raw', row: any): any;
}
export interface ColumnSettingsInterface {
  title: string;
  data?: string;
  dataType?: 'string' | 'date' | 'datetime' | undefined;
  /**Set to false to hide*/ doShow?: boolean;
  /**Set to false to not filter on*/ doFilter?: boolean;
  /**Set to false to not show sorting buttons*/ doSort?: boolean;
  filterId?: string;
  sortId?: string;
  render?: columnRenderInterface;
  defaultContent?: string;
  onClick?: (data, event: MouseEvent) => void;
  /** fa-icon identifier */ icon?: string;
  columnIcon?: string;
  cellCSS?: { [cssProperty: string]: string };
  headerCSS?: { [cssProperty: string]: string };
  parseHtml?: boolean;
  clickableCell?: boolean;
}

export function defaultRender(
  data: any,
  type: 'display' | 'sort' | 'filter' | 'raw',
  row: Object
): string {
  if (!data) return null;
  let stringyData = data.toString();
  if (stringyData.indexOf('[object') !== -1) {
    stringyData = JSON.stringify(data);
  }
  return stringyData;
}

export class ColumnSettings implements ColumnSettingsInterface {
  title: string;
  data: string;
  dataType?: 'string' | 'date' | 'datetime' | undefined;
  doShow = true;
  doFilter = true;
  doSort = true;
  sortId: string;
  filterId: string;
  render: columnRenderInterface;
  defaultContent: string;
  onClick: (data, event: MouseEvent) => void;
  icon: string;
  columnIcon: string;
  cellCSS: { [cssProperty: string]: string };
  headerCSS: { [cssProperty: string]: string };
  parseHtml: boolean;
  clickableCell: boolean;
  constructor(
    settings: ColumnSettingsInterface,
    private textService: TextService
  ) {
    this.title = settings.title;
    this.data = settings.data || null;
    this.sortId = settings.sortId || settings.data || null;
    this.filterId = settings.filterId || settings.data || null;
    this.dataType = settings.dataType || null;
    this.doShow = !(settings.doShow === false);
    this.doFilter = !(settings.doFilter === false);
    this.doSort = !(settings.doSort === false);
    // console.log(this.dataType);
    if (settings.render) {
      this.render = settings.render;
    } else if (settings.dataType === 'date') {
      this.render = this.renderDate.bind(this);
      //console.log(this.render(new Date(), 'display', {}));
    } else if (settings.dataType === 'datetime') {
      this.render = this.renderDateTime.bind(this);
      //console.log(this.render(new Date(), 'display', {}));
    } else {
      this.render = defaultRender;
    }
    this.defaultContent = settings.defaultContent;
    if (!settings.defaultContent && settings.defaultContent !== '') {
      settings.defaultContent = '?';
    }
    this.onClick = settings.onClick;
    this.icon = settings.icon;
    this.columnIcon = settings.columnIcon;
    this.cellCSS = settings.cellCSS || {};
    this.headerCSS = settings.headerCSS || {};
    this.parseHtml = settings.parseHtml || false;
  }
  getData(row: any, type: 'sort'): number | string;
  getData(row: any, type: 'display' | 'filter'): string;
  getData(row: any, type: 'raw'): any;
  getData(row, type) {
    let data = _.get(row, this.data);
    if (this.render instanceof Function) {
      data = this.render(data, type, row);
    }
    if (data !== undefined && data !== null) {
      return data;
    } else if (type === 'display') {
      return this.defaultContent;
    }
  }
  renderDate(
    data: Date | null,
    type: 'display' | 'sort' | 'filter' | 'raw',
    row: Object
  ) {
    let date = new Date(data);
    if (!date || isNaN(date.valueOf())) {
      return 'none';
    }
    switch (type) {
      case 'raw':
        return date?.toISOString();
      case 'display':
        return this.textService.formatDateOnly(data);
      case 'sort':
      default:
        return date?.toISOString();
      case 'filter':
        return this.textService.formatDateForFilter(date, false);
    }
  }
  renderDateTime(
    data: Date | null,
    type: 'display' | 'sort' | 'filter' | 'raw',
    row: Object
  ) {
    let date = new Date(data);
    if (!date || isNaN(date.valueOf())) {
      return 'none';
    }
    switch (type) {
      case 'raw':
        return date?.toISOString();
      case 'display':
        return this.textService.formatDatetime(date);
      case 'sort':
      default:
        return date?.toISOString();
      case 'filter':
        return this.textService.formatDateForFilter(date, true);
    }
  }
}
