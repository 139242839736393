import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IsLoadingPaginationResult,
  PaginationData,
  PaginationResult,
} from '~app/models/pagination-data';
import { ColumnSettings, SortingCriteria } from './types';
import * as _ from 'lodash-es';

export function filterData<T>(
  data: T[],
  searchText: string,
  columns: ColumnSettings[]
): T[] {
  if (!searchText) {
    return data;
  } else {
    let searchTerms = searchText.toLowerCase().split(' ');
    return data.filter((item) => {
      // search all columns, but don't re-get data each time
      let allData = columns
        .filter((col) => col.doFilter)
        .map((col) => {
          let dat = col.getData(item, 'filter');
          if (!dat) {
            return '';
          }
          return dat.toLowerCase();
        })
        .filter((val) => Boolean(val));
      // Every term must pass.
      // console.log(allData);
      for (let term of searchTerms) {
        // console.log(term);
        let foundColumn = allData.find((data) => {
          return data.indexOf(term) !== -1;
        });
        if (!foundColumn) {
          return false;
        }
      }
      return true;
    });
  }
}

export function getSortingFunction<T>(
  criteria: SortingCriteria<T>,
  columns: ColumnSettings[]
) {
  criteria.order = criteria.order || 'ASC';
  let orderMultiplier = 1;
  if (criteria.order === 'DESC') {
    orderMultiplier = -1;
  }
  let col = columns.find((col) => col.sortId === criteria.sortId);
  return (aItem: T, bItem: T) => {
    let a = col.getData(aItem, 'sort');
    let b = col.getData(bItem, 'sort');
    if (typeof a === 'number' && typeof b === 'number') {
      if (isNaN(a) && isNaN(b)) return 0;
      if (isNaN(a)) return -1 * orderMultiplier;
      if (isNaN(b)) return 1 * orderMultiplier;
      if (a === b) return 0;
      if (a > b) return 1 * orderMultiplier;
      if (a < b) return -1 * orderMultiplier;
    } else {
      if (!a && !b) return 0;
      if (!a) return -1 * orderMultiplier;
      if (!b) return 1 * orderMultiplier;
      a = a.toString().toLowerCase();
      b = b.toString().toLowerCase();
      // console.log(a, b);
      if (a === b) return 0;
      if (a > b) return 1 * orderMultiplier;
      if (a < b) return -1 * orderMultiplier;
    }
  };
}

export function sortData<T>(
  data: T[],
  sortingOrders: [string, ('ASC' | 'DESC' | undefined)?][],
  columns: ColumnSettings[]
) {
  let fns = sortingOrders.map((order) => {
    let criteria: SortingCriteria<T> = { sortId: order[0], order: order[1] };
    return getSortingFunction(criteria, columns);
  });
  data.sort((aItem: T, bItem: T) => {
    let result = fns.reduce((res: number, fn) => res || fn(aItem, bItem), 0);
    return result;
  });
  return data;
}

export function pageData<T>(
  data: T[],
  limit: number,
  offset: number
): PaginationResult<T> {
  let pagedData = data.slice(offset, offset + limit);
  return {
    totalRowCount: data.length,
    rowCount: pagedData.length,
    rows: pagedData,
  };
}

// function OtherStuf() {
//   this.pageCount = Math.ceil(filteredData.length / this.rowsPerPage);
//   this.pageNumbers = new Array(this.pageCount);
//   // console.log(data);
//   if (data.length === 0) {
//     this.noDataMessage = 'Data loaded, zero records';
//   } else if (filteredData.length === 0) {
//     this.noDataMessage = 'No matching records';
//   }
// }

export function ClientSidePaginationOperator<T>(
  columns: ColumnSettings[],
  data$: Observable<T[] | 'loading'>
): (
  paginationData$: Observable<PaginationData>
) => Observable<PaginationResult<T>> {
  return (paginationData$: Observable<PaginationData>) => {
    return combineLatest([data$, paginationData$]).pipe(
      map(([data, paginationData]) => {
        if (data === 'loading') {
          return IsLoadingPaginationResult();
        }
        let searchText = paginationData.where.find(
          (item) => item[0] === 'searchText'
        )?.[1] as string;
        let sortingCriterias = paginationData.order;
        let filteredData = filterData(data, searchText, columns);
        let sortedData = sortData(filteredData, sortingCriterias, columns);
        let pagedData = pageData(
          sortedData,
          paginationData.limit,
          paginationData.offset
        );
        return pagedData;
      })
    );
  };
}

/**Separated out to reduce cognitive complexity of the GenericSort */
export function NumericSort(a: number, b: number, multiplier: number) {
  if (a === b) return 0;
  else if (a > b) return 1 * multiplier;
  else if (a < b) return -1 * multiplier;
  else if (isNaN(a) && isNaN(b)) return 0;
  else if (isNaN(a)) return 1 * multiplier;
  else if (isNaN(b)) return -1 * multiplier;
}

/**Separated out to reduce cognitive complexity of the GenericSort */
export function NonNumericSort(
  a: Exclude<any, number>,
  b: Exclude<any, number>,
  multiplier: number
) {
  if (!a && !b) return 0;
  else if (!a) return 1 * multiplier;
  else if (!b) return -1 * multiplier;
  a = a.toString().toLowerCase();
  b = b.toString().toLowerCase();
  if (a === b) return 0;
  else if (a > b) return 1 * multiplier;
  else if (a < b) return -1 * multiplier;
}

export function GenericSort(a: any, b: any, multiplier: number) {
  if (typeof a === 'number' && typeof b === 'number') {
    // These sections were separated into their own functions
    // to reduce cognitive complexity according to SonarQube.
    return NumericSort(a, b, multiplier);
  } else {
    // These sections were separated into their own functions
    // to reduce cognitive complexity according to SonarQube.
    return NonNumericSort(a, b, multiplier);
  }
}

/**
 *
 * @param arr An array of objects.  The array will be mutated.
 * @param criteria Sorting Criteria.  sortId may be a
 */
export function SortByCriteria<T>(arr: T[], criteria: SortingCriteria<T>[]) {
  arr.sort((itemA, itemB) => {
    return criteria.reduce((prev, crit) => {
      if (prev) return prev;
      let sortPath =
        crit.sortId instanceof Array ? crit.sortId : crit.sortId.split('.');
      let multiplier = crit.order === 'ASC' ? 1 : -1;
      let a = _.get(itemA, sortPath);
      let b = _.get(itemB, sortPath);
      return GenericSort(a, b, multiplier);
    }, 0);
  });
}
