import { Component, inject } from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

export type ErrorDialogData = {
  errorType: 'INVALID_FILE_TYPE' | 'INVALID_FILE_SIZE';
  fileName: string;
};

@Component({
  selector: 'ciao-error-dialog',
  standalone: true,
  imports: [MatDialogModule, MatIcon, NgSwitch, NgSwitchCase, NgSwitchDefault],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.less',
})
export class ErrorDialogComponent {
  readonly dialogRef = inject(MatDialogRef<ErrorDialogComponent>);
  readonly data: ErrorDialogData = inject(MAT_DIALOG_DATA);
}
