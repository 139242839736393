<h1>Frequently Asked Questions</h1>
<div class="qa-section">
  <ng-template ngFor let-mainSection [ngForOf]="mainFaqMap">
    <div class="topic-section">
      <div class="section-title">
        <h3 class="topic-title">{{ mainSection.title }}</h3>
        <hr />
      </div>

      <ng-template ngFor let-question [ngForOf]="mainSection.questions">
        <div class="container" #qaSet [id]="'question-answer-' + question.slug">
          <button (click)="openOrClose(question.slug)">
            {{ question.question }}
          </button>
          <div
            *ngIf="selected.includes(question.slug)"
            class="expandable-answer-section"
          >
            <markdown
              id="markdown"
              ngPreserveWhitespaces
              [data]="question.answer"
            ></markdown>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
