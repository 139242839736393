<div>
  <h3 class="modal-form-title">
    {{ editingExisting ? "Update" : "Add" }} Equipment
  </h3>

  <div class="row">
    <ciao-form-field
      label="Vehicles/Equipment"
      type="textarea"
      placeholder="Describe details (e.g. fleet #, telematics, color, POV)"
      [toolTip]="
        'Please provide a description of the vehicle or equipment for this trip. If using a vehicle, you can also complete the fields below.'
      "
      [formControlInput]="formGroup.get('details')"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="State"
      type="combobox"
      [formControlInput]="formGroup.get('state')"
      [selectOptions$]="stateOptions$"
      [compareWith]="compareStateForSelect"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="License #"
      type="input"
      [formControlInput]="formGroup.get('license')"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="Make"
      type="input"
      [formControlInput]="formGroup.get('make')"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="Model"
      type="input"
      [formControlInput]="formGroup.get('model')"
    ></ciao-form-field>
  </div>
</div>
