<ng-container
  *ngIf="showAnnouncements && announcement$ | async as announcement"
>
  <div class="bar-container">
    <span class="announcement-text">
      <strong>Announcement:</strong> {{ announcement.text }}
    </span>
  </div>
</ng-container>
<div *ngIf="showTab" class="white-bar"></div>

<button
  class="booktab"
  (click)="!showAnnouncements ? openAnnouncementBar() : closeAnnouncementBar()"
>
  <div class="booktab-tab-shape"></div>
  <div class="booktab-content">
    <span class="screenreader" aria-live="assertive">
      click to
      {{
        showAnnouncements ? "collapse announcements" : "expand announcements"
      }}
    </span>
    <fa-icon
      class="icon"
      [icon]="showAnnouncements ? faChevronUp : faChevronDown"
    ></fa-icon>
    Message
  </div>
</button>
