import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService, ConfigService } from './services';
import { UserAttributes } from './models';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { RolePermissionService } from './services/role-permission.service';
import { UserModalComponent } from './components/views/user-modal/user-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'CIAO | Check In And Out';
  underConstruction: boolean;
  currentUser$: Observable<UserAttributes>;
  activeRouterLinks$: Observable<{ url: string; title: string }[]>;
  currentlyNavigating$: Observable<boolean>;

  @ViewChild('menubtn') menubtn: ElementRef<HTMLElement>;
  @ViewChild('links') links: ElementRef<HTMLElement>;
  @ViewChild('userModal') userModal: UserModalComponent;

  constructor(
    public authService: AuthService,
    private configService: ConfigService,
    private rolePermissionService: RolePermissionService
  ) {}

  ngOnInit() {
    this.currentUser$ = this.authService.currentUser$;
    this.activeRouterLinks$ = combineLatest([
      this.authService.currentUser$,
      this.rolePermissionService.isAdmin$,
      this.rolePermissionService.isSystemAdmin$,
    ]).pipe(
      map(([user, isAdmin, isSystemAdmin]) => {
        if (!user) {
          return 'noUser';
        } else if (isSystemAdmin) {
          return 'systemAdminUser';
        } else if (isAdmin) {
          return 'adminUser';
        } else {
          return 'basicUser';
        }
      }),
      map((listName) => allRouterLinks[listName] || allRouterLinks.error)
    );
    try {
      this.underConstruction = this.configService.getConfig(
        'show_under_construction'
      );
    } catch (err) {
      console.error(err);
      this.underConstruction = true;
    }
  }

  ngAfterViewInit() {
    this.menubtn.nativeElement.addEventListener('click', () => {
      this.menubtn.nativeElement.classList.toggle('active');
      this.links.nativeElement.classList.toggle('active');
    });
  }
  removeActive() {
    this.menubtn.nativeElement.classList.remove('active');
    this.links.nativeElement.classList.remove('active');
  }
  openUserModal() {
    this.userModal?.openModal();
  }
}

const allRouterLinks = {
  error: [],
  noUser: [],
  basicUser: [
    //{ title: 'Home', url: '/home' },
    { title: 'Trips', url: '/search' },
    { title: 'Teams', url: '/teams' },
    { title: 'Help', url: '/help' },
  ],
  adminUser: [
    //{ title: 'Home', url: '/home' },
    { title: 'Trips', url: '/search' },
    { title: 'Teams', url: '/teams' },
    { title: 'Users', url: '/users' },
    { title: 'Help', url: '/help' },
  ],
  systemAdminUser: [
    //{ title: 'Home', url: '/home' },
    { title: 'Trips', url: '/search' },
    { title: 'Teams', url: '/teams' },
    { title: 'Users', url: '/users' },
    { title: 'Help', url: '/help' },
    // Change back to route appropriately after #1710
    { title: 'System Admin', url: '/system-admin/announcements' },
  ],
};
