<mat-form-field>
  <input
    matInput
    [id]="inputId"
    [title]="label"
    [autocomplete]="'ciao-no-autocomplete'"
    class="dateInput dateInputFrom"
    #dpDate
    placeholder="mon dd, yyyy"
    [formControl]="secretFormControl"
    (click)="datepicker.toggle()"
    (blur)="formControlInput.updateValueAndValidity()"
    autocomplete="off"
  />
</mat-form-field>
<mat-form-field>
  <input
    matInput
    hidden
    class="form-control"
    placeholder="mon dd, yyyy"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    (dateSelect)="onDateSelection($event)"
    [positionTarget]="dpDate"
    [placement]="['bottom-left', 'bottom']"
  />
</mat-form-field>
