import { MonoTypeOperatorFunction, Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/**
 * Refresh Source (https://github.com/ReactiveX/rxjs/issues/5196)
 * @type {MonoTypeOperatorFunction}
 * @param notifier$ When the notifier emits, repeat the last emitted value from the source
 *
 * This is different from `notifier$.pipe(switchMapTo(source))`, because in that case,
 *   the source re-calculates, whereas in this case, the source is simply re-emitted.
 */
export const repeatLatestWhen =
  <T>(notifier$: Observable<any>) =>
  (source: Observable<T>) =>
    combineLatest([source, notifier$.pipe(startWith([null]))]).pipe(
      map(([val]) => val)
    );
