import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, mapTo } from 'rxjs/operators';

import { AuthService } from '~app/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    let result = this.authService.currentUser$.pipe(
      filter((user) => !!user),
      mapTo(true)
    );
    return result;
  }
}
