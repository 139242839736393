import { Component } from '@angular/core';
import { LoadingDelayPopupService } from './loading-delay-popup.service';

/*
https://stackoverflow.com/questions/52311173/angular-material-create-dialog-box-in-same-component-as-existing-functionality
> Update: I was not correct in my assumptions that the TemplateRef's type
> parameter was the component reference - in fact, it's actually the
> "data-binding context of the embedded view", as shown in the
> documentation for the TemplateRef#createEmbeddedView method
*/

@Component({
  templateUrl: './loading-delay-popup.component.html',
  styleUrls: ['./loading-delay-popup.component.less'],
})
/** If a User is not part of any Team, show this pop-up on the Welcome page once per session. */
export class LoadingDelayPopupComponent {
  constructor(private loadingDelayService: LoadingDelayPopupService) {}
  refreshPage() {
    window.location.reload();
  }
  closePopup() {
    this.loadingDelayService.closePopup();
  }
}
