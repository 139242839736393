import { Injectable } from '@angular/core';
import { PlotAttributes, TripAttributes } from '~models';
import { ApiService, Endpoint } from './api.service';
import {
  Observable,
  of,
  AsyncSubject,
  interval,
  combineLatest,
  fromEvent,
  BehaviorSubject,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { clone as lodashClone } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import mime from 'mime';
import { Okay, OkayAttributes } from '~app/models/okay';
import { TextService } from './text.service';
import { PaginationData, PaginationResult } from '~app/models/pagination-data';
import { UserGroupService } from './user-group.service';

export const AllowedFileAttachmentContentTypes: string[] = [
  'image/jpeg',
  'image/jpg',
  'image/pjpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
] as const;

/** Mutates the original array to be sorted in alphabetical order */
export function alphabetizeAttachments(
  attachmentArray: FileAttachments.AttachedFile[]
) {
  attachmentArray.sort((a, b) => {
    if (a.fileName.toLowerCase() > b.fileName.toLowerCase()) {
      return 1;
    } else if (a.fileName.toLowerCase() < b.fileName.toLowerCase()) {
      return -1;
    }
  });
}

export function op_ProcessTripFromAPI(
  userGroupService: UserGroupService,
  apiUrl: string
) {
  return (trip$: Observable<TripAttributes>) => {
    return trip$.pipe(
      tap((trip) => {
        if (!trip.attachments) return;
        trip.attachments.forEach((attachment) => {
          attachment.status = 'uploaded';
          attachment.viewHref = `${apiUrl}/trips/${attachment.tripId}/files/${attachment.id}`;
          attachment.downloadHref = attachment.viewHref + '?download';
          attachment.extension = mime
            .getExtension(attachment.contentType)
            ?.toUpperCase();
        });
        alphabetizeAttachments(trip.attachments);
      }),
      tap((trip) => {
        if (!trip.okays) return;
        trip.okays.sort((a, b) => b.timeOkay.valueOf() - a.timeOkay.valueOf());
        let allCheckIns = trip.okays.filter((okay) => okay.type === 'checkIn');
        let lastCheckIn = allCheckIns[allCheckIns.length - 1];
        if (trip.tripStatus === 'Closed' && lastCheckIn) {
          lastCheckIn.closedTrip = true;
        }
      }),
      switchMap((trip) =>
        userGroupService.findById(trip.userGroupId).pipe(
          tap((userGroup) => (trip.usergroup = userGroup)),
          mapTo(trip),
          catchError((err, caught) => {
            console.error(err);
            return of(trip);
          })
        )
      )
    );
  };
}

@Injectable({
  providedIn: 'root',
})
export class TripService {
  public readonly MaxIndividualTrips = 10;
  public readonly MinRefreshTime = 1 * 60 * 1000; // 1 minute
  public readonly AllowedFileAttachmentSize = 10 * 1024 * 1024; // 10 MB
  public readonly ErrorCodes = {
    FileType: 'INVALID_FILE_TYPE',
    FileSize: 'INVALID_FILE_SIZE',
  } as const;

  public readonly lastUpdatedDate$ = this.apiService
    .GetSingle<string>('/trips/last-updated')
    .pipe(
      map((date) => new Date(date)),
      catchError((err, caught) => {
        return of<Date>();
      })
    );
  public readonly checkForRefreshOnInterval$ = combineLatest([
    interval(10000),
    fromEvent(document, 'visibilitychange').pipe(startWith(0)),
  ]).pipe(
    filter((_) => document.visibilityState === 'visible'),
    startWith(0),
    switchMapTo(this.lastUpdatedDate$),
    distinctUntilChanged((x, y) => x.valueOf() === y.valueOf())
  );
  public readonly endpointName = 'trips';
  public readonly apiUrl = this.apiService.apiUrl;
  private endpoint: Endpoint<TripAttributes>;
  private okayEndpoint: Endpoint<Okay>;

  private trip$ById: {
    [id: string]: ReturnType<TripService['createReusableTripObservable']>;
  } = {};

  constructor(
    private apiService: ApiService,
    private userGroupService: UserGroupService,
    private textService: TextService
  ) {
    this.endpoint = apiService.endpoint(this.endpointName);
    this.okayEndpoint = apiService.endpoint(this.endpointName + '/okays');
  }

  /**
   * `addOkay` adds an okay to the list, fetches the trip again, and updates
   * the trip list and the findById result.  `addOkay` returns void,
   * So watch the existing `trip$` for changes.
   */
  addOkay(okay: OkayAttributes): Observable<TripAttributes> {
    let tripId = okay.tripId;
    let okayAdded = this.okayEndpoint.Post(okay);
    return okayAdded.pipe(
      mapTo(this.getReusableTripObservable(tripId)),
      tap((reusable) => reusable.refresh$.next()),
      switchMap((reusable) => reusable.trip$),
      take(1)
    );
  }

  private createReusableTripObservable(tripId: string) {
    const refresh$ = new BehaviorSubject<void>(null);
    const trip$ = refresh$.pipe(
      switchMap(() =>
        this.endpoint
          .Get(tripId)
          .pipe(op_ProcessTripFromAPI(this.userGroupService, this.apiUrl))
      ),
      share<TripAttributes>()
    );
    return {
      status: '' as 'loading' | 'loaded' | 'destroyed' | '',
      lastRefreshed: new Date().valueOf(),
      trip$: trip$,
      refresh$: refresh$,
    };
  }
  private getReusableTripObservable(tripId: string) {
    if (!this.trip$ById[tripId]) {
      this.trip$ById[tripId] = this.createReusableTripObservable(tripId);
    }
    return this.trip$ById[tripId];
  }

  /** A reusuable observable that will refresh whenever the trip is saved by any other tripService.saveTrip call */
  findById(tripId: string): Observable<TripAttributes> {
    let obs = this.getReusableTripObservable(tripId);
    obs.refresh$.next();
    return obs.trip$;
  }
  search(paginationData: Partial<PaginationData>) {
    let fullPaginationData: PaginationData = {
      limit: paginationData.limit ?? 20,
      offset: paginationData.offset ?? 0,
      order: paginationData.order ?? [['updatedAt', 'ASC']],
      where: paginationData.where ?? [],
    };
    return this.apiService.Search<PaginationResult<TripAttributes>>(
      '/trips',
      fullPaginationData
    );
  }

  saveTrip(input: Partial<TripAttributes>): Observable<TripAttributes> {
    let originalRequest = this.endpoint.Put(input.id, input);
    if (!input.id) {
      originalRequest = this.endpoint.Post(input);
    }
    let request = originalRequest.pipe(
      op_ProcessTripFromAPI(this.userGroupService, this.apiUrl),
      tap((trip) => {
        this.getReusableTripObservable(trip.id).refresh$.next();
      })
    );
    return request;
  }
  deleteTrip(id: uuid): Observable<TripAttributes> {
    let request = this.endpoint
      .Delete(id)
      .pipe(tap(() => this.getReusableTripObservable(id).refresh$.next()));
    return request;
  }

  getSelectOption(trip: TripAttributes) {
    let name = trip.crewLeader?.displayName;
    let date = this.textService.formatDateOnly(trip.departureDate);
    return { label: `${name} | ${date}`, value: trip };
  }

  getBlankTripForm(): TripAttributes {
    return {
      id: '',
      tripStatus: 'Planned',
      usergroup: null,
      userGroupId: '',
      startDate: new Date().toISOString(),
      endDate: null,
      locations: [],
      crewMembers: [],
      equipmentList: [],
      okays: [],
    };
  }

  duplicateTrip({ tripId, startDate, endDate }) {
    return this.apiService.Custom<TripAttributes>(
      'POST',
      `trips/duplicate/${tripId}`,
      {
        startDate,
        endDate,
        duplicate: {
          crewMembers: true,
          locations: true,
          equipmentList: true,
          okays: false,
          attachments: true,
        },
      }
    );
  }

  formatFullAddressAsString(location: PlotAttributes, separationStr = ' | ') {
    const street1 = location?.address?.street1 || '';
    const street2 = location?.address?.street2 || '';
    const city = location?.address?.city || '';
    const state = location?.address?.state || '';
    const zip = location?.address?.zip || '';

    const addressArr = [street1, street2].filter((s) => !!s).join(', ') || '';
    const stateLine = [city, state].filter((s) => !!s).join(', ') || '';

    const formattedAddress = [addressArr, stateLine, zip]
      .filter((s) => !!s)
      .join(separationStr);

    return formattedAddress || 'No address provided';
  }

  attachFile(tripId, file: File) {
    // comment out the following two if statements in order to test the backend portion
    if (
      !AllowedFileAttachmentContentTypes.includes(file.type) &&
      !/\.gpx$/.test(file.name) // allow .gpx files to be added
    ) {
      return throwError(new Error(this.ErrorCodes.FileType));
    }
    if (file.size > this.AllowedFileAttachmentSize) {
      return throwError(new Error(this.ErrorCodes.FileSize));
    }
    const reuse = this.getReusableTripObservable(tripId);
    const formData: FormData = new FormData();
    formData.append('attachment', file, file.name);
    return this.apiService
      .Post<FileAttachments.AttachedFile>(`/trips/${tripId}/attach/`, formData)
      .pipe(tap(() => reuse.refresh$.next()));
  }
  removeAttachment(file: FileAttachments.AttachedFile) {
    const reuse = this.getReusableTripObservable(file.tripId);
    file.status = 'deleting';
    return this.apiService
      .Delete(`/trips/${file.tripId}/files/`, file.id)
      .pipe(tap(() => reuse.refresh$.next()));
  }
}
