import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { v4 as uuid } from 'uuid';
import { startWith, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';

@Component({
  selector: 'app-style-guide',
  templateUrl: './style-guide.component.html',
  styleUrls: ['./style-guide.component.less'],
})
export class StyleGuideComponent implements OnInit {
  subscriptions = new Subscription();
  randomNumberString = Math.random().toString().replace('.', '');

  //font awesome
  ban = faBan;
  bullHorn = faBullhorn;
  check = faCheck;
  filter = faFilter;
  mobileAlt = faMobileAlt;
  sort = faSort;
  sortDown = faSortDown;
  sortUp = faSortUp;
  bell = faBell;
  comment = faComment;
  sun = faSun;

  faPreferredIcons = [
    { displayName: 'check', name: this.check },
    { displayName: 'filter', name: this.filter },
    { displayName: 'sort', name: this.sort },
    { displayName: 'sortUp', name: this.sortUp },
    { displayName: 'sortDown', name: this.sortDown },
  ];

  buttonStyles = [
    'brown',
    'action',
    'green',
    'navigate',
    'info',
    'blue',
    'danger',
    'destructive',
    'red',
  ];

  date = new Date();

  /* Report Tables */
  reportColumns1 = [
    { title: 'Alpha', data: 'a' },
    { title: 'Bravo', data: 'b' },
    { title: 'Charlie', data: 'c' },
    { title: 'Delta', data: 'd' },
    { title: 'Echo', data: 'e' },
  ];
  reportColumns2 = [
    { title: 'Alpha', data: 'a' },
    { title: 'Bravo', data: 'b' },
    { title: 'Charlie', data: 'c' },
    { title: 'Delta', data: 'd' },
    { title: 'Echo', data: 'e' },
  ];
  reportData1 = reportData.slice(11, 16);
  reportData2 = reportData;
  focusOnControl = new UntypedFormControl('');
  focusOnOptions$ = of([
    { value: '', label: 'General' },
    { value: 'forms', label: 'Forms' },
    { value: 'combobox', label: 'ComboBox' },
  ]);

  openThenClose(modalRef: CiaoModalComponent) {
    let instance = modalRef.openModal();
    setTimeout(() => {
      instance.dismiss('autoClosed');
      // modalRef.dismiss('autoClosed') would also work
    }, 4000);
  }
  constructor(private route: ActivatedRoute, private router: Router) {
    this.focusOnControl.setValue(route.snapshot.params.focusOn ?? '');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.focusOnControl.valueChanges
        .pipe(tap((val) => this.router.navigate(['dev', 'styleGuide', val])))
        .subscribe()
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

const reportData = [
  {
    id: new uuid(),
    item: 'first',
    a: 92148,
    b: 'asdfasd',
    c: 'abcde',
    d: 'asefgesf',
    e: '98wf@usda.gov',
  },
  {
    id: new uuid(),
    item: 'second',
    a: 894894,
    b: 'IFadsfasdEasdfsadfSji',
    c: 'FGHIJ',
    d: 'z;lije',
    e: '98wf@usda.gov',
  },
  {
    id: new uuid(),
    item: 'third',
    a: 819814,
    b: 'adsfsda',
    c: 'klmnop',
    d: 'aiflwz;lijej9',
    e: 'afsdkljflksdj@usda.gov',
  },
  {
    id: new uuid(),
    item: 'fourth',
    a: 645456,
    b: 'adsfasd',
    c: 'FGHIJ',
    d: 'z;lije',
    e: 'alskdfj9@usda.gov',
  },
  {
    id: new uuid(),
    item: 'fifth',
    a: 984981,
    b: 'IasdfsadFESji',
    c: 'abcde',
    d: 'z;lije',
    e: '2039u@usda.gov',
  },
  {
    id: new uuid(),
    item: 'sixth',
    a: 561981,
    b: 'IFESjasdfasdi',
    c: 'FGHIJ',
    d: 'asefgesf',
    e: '2039uafliwsje3@usda.gov',
  },
  {
    id: new uuid(),
    item: 'seventh',
    a: 871651,
    b: 'adsf',
    c: 'abcde',
    d: 'z;lije',
    e: '0f9j40u@usda.gov',
  },
  {
    id: new uuid(),
    item: 'eigth',
    a: 897418,
    b: 'asdf',
    c: 'FGHIJ',
    d: 'asefgesf',
    e: 'falis;j9@usda.gov',
  },
  {
    id: new uuid(),
    item: 'ninth',
    a: 987891,
    b: 'IFEaSji',
    c: 'abcde',
    d: 'z;lije',
    e: 'aofjaiej@usda.gov',
  },
  {
    id: new uuid(),
    item: 'tenth',
    a: 687.27,
    b: 'adfs',
    c: 'FGHIJ',
    d: 'z;lije',
    e: '9f38q4h@usda.gov',
  },
  {
    id: new uuid(),
    item: 'eleventh',
    a: 186185,
    b: 'adsf',
    c: 'klmnop',
    d: 'asefgesf',
    e: '56as4fd@usda.gov',
  },
  {
    id: new uuid(),
    item: 'twelfth',
    a: 484651,
    b: 'adsfaf',
    c: 'FGHIJ',
    d: 'z;lije',
    e: 'a46sdf5@usda.gov',
  },
  {
    id: new uuid(),
    item: 'thirteenth',
    a: 186511,
    b: 'asdf',
    c: 'abcde',
    d: 'asefgesf',
    e: 'a65sd4f@usda.gov',
  },
  {
    id: new uuid(),
    item: 'fourteenth',
    a: 897195,
    b: 'IFESasdfsadji',
    c: 'FGHIJ',
    d: 'z;lije',
    e: 'af684G4@usda.gov',
  },
  {
    id: new uuid(),
    item: 'fifteenth',
    a: 156116,
    b: 'adsf',
    c: 'abcde',
    d: 'z;lije',
    e: 'fa86s4e48@usda.gov',
  },
  {
    id: new uuid(),
    item: 'sixteenth',
    a: 837784,
    b: 'asdfasd',
    c: 'FGHIJ',
    d: 'asefgesf',
    e: 'af68s4e68@usda.gov',
  },
  {
    id: new uuid(),
    item: 'seventeenth',
    a: 654860,
    b: 'adsf',
    c: 'abcde',
    d: 'z;lije',
    e: 'a6esf74@usda.gov',
  },
];
