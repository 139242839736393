import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  startWith,
  switchMapTo,
  tap,
} from 'rxjs/operators';

import { TripAttributes } from '~app/models';
import { TripService } from '~app/services';
import { IndividualFileBarComponent } from './individual-file-bar/individual-file-bar.component';

import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { alphabetizeAttachments } from '~app/services/trip.service';

@Component({
  selector: 'ciao-file-upload',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatProgressBarModule,
    IndividualFileBarComponent,
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.less',
})
export class FileUploadComponent {
  @ViewChild('fileInput') fileInputElement: ElementRef<HTMLInputElement>;
  private trip$ = new BehaviorSubject<TripAttributes>(null);
  @Input({ required: true }) set trip(value) {
    this.trip$.next(value);
  }
  get trip() {
    return this.trip$.value;
  }

  existingFiles$: Observable<FileAttachments.AttachedFile[]> = this.trip$.pipe(
    map((trip) => trip.attachments)
  );
  newFiles$ = new BehaviorSubject<FileAttachments.AttachedFile[]>([]);
  allFiles$ = combineLatest([this.existingFiles$, this.newFiles$]).pipe(
    map(([existingFiles, newFiles]) => {
      newFiles = newFiles.filter((file) => file.status !== 'uploaded');
      let allFiles = existingFiles.concat(newFiles);
      alphabetizeAttachments(allFiles);
      return allFiles;
    }),
    startWith([])
  );
  files: FileAttachments.AttachedFile[] = [];

  constructor(
    private tripService: TripService,
    private matDialogService: MatDialog
  ) {}

  onFileSelected(event: any) {
    this.uploadFiles(event.target.files);
    // Reset the input so that attempting to add the same files again will add the files again.
    this.fileInputElement.nativeElement.value = null;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      this.uploadFiles(event.dataTransfer.files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  deleteFile(file: FileAttachments.AttachedFile) {
    this.tripService.removeAttachment(file).subscribe();
  }

  cleanupNewFile(deleteFile: FileAttachments.AttachedFile) {
    let files = this.newFiles$.value;
    files = files.filter((file) => file !== deleteFile);
    this.newFiles$.next(files);
  }

  uploadFiles(files: FileList) {
    Array.from(files).forEach((file) => {
      this.uploadFile(file);
    });
  }

  uploadFile(file: File) {
    const newFile: FileAttachments.AttachedFile = {
      id: '',
      tripId: this.trip.id,
      fileName: file.name,
      contentType: file.type,
      fileSize: file.size,
      status: 'uploading',
    };
    const newFiles = this.newFiles$.value;
    newFiles.push(newFile);
    this.newFiles$.next(newFiles);
    this.tripService
      .attachFile(this.trip.id, file)
      .pipe(
        tap((attachedFile) => {
          Object.assign(newFile, attachedFile[0]);
          newFile.status = 'uploaded';
        }),
        switchMapTo(this.trip$),
        catchError((err, caught) => {
          console.log(err.message);
          this.matDialogService.open(ErrorDialogComponent, {
            panelClass: 'raleway-dialog-alpha',
            width: '450px',
            data: {
              fileName: newFile.fileName,
              errorType: err.message ?? err,
            },
          });
          throw err;
        }),
        finalize(() => this.cleanupNewFile(newFile))
      )
      .subscribe();
  }
}
