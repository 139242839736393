import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash-es';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { uuid } from 'uuid';
import {
  catchError,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AssignedRole, PersonAttributes, UserAttributes } from '~app/models';
import { AuthService, AutocompleteService, PersonService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';
import { ToastrService } from 'ngx-toastr';
import { UserGroupAttributes } from '~app/models/user-group';
import { RolePermissionService } from '~app/services/role-permission.service';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { NgClass, NgIf } from '@angular/common';
import { FormsUtility } from '~app/utilities/';

@Component({
  selector: 'ciao-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.less'],
  standalone: true,
  imports: [CiaoFormsModule, NgClass, NgIf],
})
export class UserDetailFormComponent implements OnInit {
  _personId: uuid = null;
  @Input() set personId(value: uuid) {
    this._personId = value;
    this.refreshPerson();
  }

  // DEPRECATED: This can be removed from this component when the admin tab is implemented
  @Input() registrationContext?: boolean = false;

  get personId() {
    return this._personId;
  }

  canUpdate$ = this.authService.currentUser$.pipe(
    filter((user) => !!user),
    map((user) => user.personId === this.personId)
  );

  readonly defaultData: FormData;
  formGroup = new FormGroup({
    person: new FormGroup({
      id: new FormControl(''),
      firstName: new FormControl('', [
        Validators.required,
        FormsUtility.isOverCharLimit,
      ]),
      lastName: new FormControl('', [
        Validators.required,
        FormsUtility.isOverCharLimit,
      ]),
      jobTitle: new FormControl('', [Validators.required]),
    }),
    users: new FormArray([
      new FormGroup({
        email: new FormControl('', [
          Validators.email,
          this.govEmailValidator.bind(this),
        ]),
        id: new FormControl(''),
        notificationOptions: new FormGroup({
          defaultSet: new FormControl(false),
        }),
      }),
      new FormGroup({
        email: new FormControl('', [
          Validators.email,
          this.nonGovEmailValidator.bind(this),
        ]),
        id: new FormControl(''),
        notificationOptions: new FormGroup({
          defaultSet: new FormControl(false),
        }),
      }),
    ]),
    /** Deprecated: This formControl "regionRoles" will be removed when admin tab is established */
    regionRoles: new FormControl([], { nonNullable: true }),
  });

  regionOptions$: Observable<{ value: string; label: string }[]>;
  jobOptions$: Observable<{ value: string; label: string }[]>;

  /** Deprecated: This variable will be removed when admin tab is established */
  AssignRegionRoleSelectOptions$: Observable<
    {
      label: string;
      value: { UserGroupId: string; RoleId: string };
      disabled: boolean;
    }[]
  >;

  /** Deprecated: This variable will be removed when admin tab is established */
  InitialAssignedRoles$: Observable<AssignedRole[]> = of([]);

  /** Deprecated: This variable will be removed when admin tab is established */
  appliedPermissionsAssignAdminRoles$ = combineLatest([
    this.userGroupService.systemUserGroup$,
    this.userGroupService.allRegions$,
  ]).pipe(
    filter(([system, regions]) => Boolean(system && regions.length)),
    map(([system, regions]) => [system, ...regions]),
    switchMap((userGroups) =>
      this.rolesPermissionService.searchMyAppliedPermissions({
        permissionIds: ['assign_system_admin', 'assign_region_admin'],
        filterWithinUserGroups: userGroups,
      })
    )
  );

  subscriptions = new Subscription();

  get formTitle() {
    return this.personId ? 'Update Details' : 'Add Details';
  }

  constructor(
    private userGroupService: UserGroupService,
    private autoCompleteService: AutocompleteService,
    private personService: PersonService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private rolesPermissionService: RolePermissionService
  ) {
    this.defaultData = {
      person: {
        id: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
      },
      users: [
        { email: '', id: '', notificationOptions: { defaultSet: false } },
        { email: '', id: '', notificationOptions: { defaultSet: false } },
      ],
      /** Deprecated: This formControl "regionRoles" will be removed when admin tab is established */
      regionRoles: [],
    };
  }

  ngOnInit(): void {
    /** Deprecated: This method call will be removed when admin tab is established */
    this.intializeEnabledRegionRoleOptions();

    this.subscriptions.add(
      this.canUpdate$
        .pipe(
          tap((canUdpdate) => {
            if (!canUdpdate) {
              this.formGroup.get('users.0.notificationOptions').disable();
              this.formGroup.get('users.1.notificationOptions').disable();
            }
          })
        )
        .subscribe()
    );

    this.regionOptions$ = this.userGroupService.allRegions$.pipe(
      map((userGroups) =>
        userGroups.map((userGroup) => ({
          value: userGroup.id,
          label: userGroup.label,
        }))
      )
    );
    this.jobOptions$ = this.autoCompleteService.allJobTitles$.pipe(
      map((jobTitles) =>
        jobTitles.map((jobTitle) => ({
          value: jobTitle.titleName,
          label: jobTitle.titleName,
        }))
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /** Deprecated: This method will be removed when admin tab is established */
  intializeEnabledRegionRoleOptions() {
    let appliedPermissionsAssignAdminRoles$ = combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
    ]).pipe(
      filter(([system, regions, forestLabs]) =>
        Boolean(system && regions.length)
      ),
      map(([system, regions, forestLabs]) => [
        system,
        ...regions,
        ...forestLabs,
      ]),
      switchMap((userGroups) =>
        this.rolesPermissionService.searchMyAppliedPermissions({
          permissionIds: ['assign_system_admin', 'assign_region_admin'],
          filterWithinUserGroups: userGroups,
        })
      )
    );

    /** Deprecated: This variable will be removed when admin tab is established */
    this.AssignRegionRoleSelectOptions$ = combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
      appliedPermissionsAssignAdminRoles$,
    ]).pipe(
      map(([system, regions, forestLabs, myPermissions]) => {
        // for the record, this whole thing takes 1ms
        const unicodeSpace = '\u00A0';
        const unicodeTab = unicodeSpace.repeat(8);
        let idsOfGroupsICanAssign = {};
        // idsOfGroupsICanAssign Enables O(n+m) instead of O(n*m)
        myPermissions.forEach((perm) => {
          idsOfGroupsICanAssign[
            perm.UserGroupId + ' | ' + perm.AppliedPermissionId
          ] = true;
        });
        let processOptionForGroup = (group: UserGroupAttributes) => {
          const RoleId = {
            '': 'system_admin',
            System: 'system_admin',
            Region: 'region_admin',
            ForestLab: 'region_admin',
          }[group.tierLabel];
          const indent = group.tierLabel === 'ForestLab' ? 1 : 0;
          return {
            label:
              unicodeSpace.repeat(8 * indent) + group.labelPrefix + group.label,
            value: { UserGroupId: group.id, RoleId: RoleId },
            disabled: !idsOfGroupsICanAssign[`${group.id} | assign_${RoleId}`],
          };
        };
        let roleSelectOptions = [processOptionForGroup(system)];
        if (roleSelectOptions[0].disabled) {
          roleSelectOptions.pop();
        }
        regions.forEach((region) => {
          let regionOption = processOptionForGroup(region);
          let labs = region.children
            .filter((child) => child.tierLabel === 'ForestLab')
            .map((lab) => processOptionForGroup(lab))
            .filter((option) => !option.disabled);
          if (labs.length || !regionOption.disabled) {
            roleSelectOptions.push(regionOption, ...labs);
          }
        });
        return roleSelectOptions;
      })
    );
  }

  emailsValidatorSharedInput() {
    const formArray = this.formGroup?.get('users') as FormArray;
    //console.log(formArray);

    const govControl = formArray?.at(0).get('email') as FormControl;
    const nonGovControl = formArray?.at(1).get('email') as FormControl;

    const govEmail = formArray?.at(0).get('email').value;
    const nonGovEmail = formArray?.at(1).get('email').value;

    return {
      govControl,
      nonGovControl,
      govEmail,
      nonGovEmail,
    };
  }

  // A bit repetetive but works for avoiding recursive callback on validation
  govEmailValidator(control: FormControl) {
    const info = this.emailsValidatorSharedInput();

    if (!info.nonGovEmail && !info.govEmail) {
      return { oneEmailMinimum: `Please include at least one email` };
    } else if (
      (info.nonGovEmail || info.govEmail) &&
      info.nonGovControl.invalid
    ) {
      info.nonGovControl.updateValueAndValidity();
      return null;
    } else {
      return null;
    }
  }

  nonGovEmailValidator(control: FormControl) {
    const info = this.emailsValidatorSharedInput();

    if (!info.govEmail && !info.nonGovEmail) {
      return { crewIsSupervisor: `Please include at least one email` };
    } else if ((info.nonGovEmail || info.govEmail) && info.govControl.invalid) {
      info.govControl.updateValueAndValidity();
      return null;
    } else {
      return null;
    }
  }

  // This function should be completely covered by `this.formGroup.reset()`
  // For a minute, it seemed to be actually needed due to regionRoles becoming blank instead of array
  // But that was fixed using the `nonNullable` option in new FormControl
  //
  // resetForm() {

  //   this.formGroup.patchValue(this.defaultData);
  //   this.formGroup.markAsUntouched();
  //   this.formGroup.markAsPristine();
  // }

  refreshPerson() {
    this.formGroup.reset();
    if (!this._personId) {
      // this.resetForm();
      return;
    }
    let person$ = this.personService.findById(this._personId);

    let userGroup$ = person$.pipe(
      switchMap((person) => this.userGroupService.findById(person.mainGroupId))
    );

    /** Deprecated: This will be removed when admin tab is established */
    this.InitialAssignedRoles$ = this.rolesPermissionService
      .searchRoles({
        personIds: [this._personId],
        roleIds: ['system_admin', 'region_admin'],
      })
      .pipe(shareReplay(1));

    // ensure we are able to set the user group to the person obj before form binding
    /** Deprecated: This reference of will be removed when admin tab is established */
    let obs = combineLatest([person$, userGroup$, this.InitialAssignedRoles$]);

    let sub = obs
      .pipe(take(1))
      /** Deprecated: This reference to regionRoles will be removed when admin tab is established */
      .subscribe(([person, userGroup, regionRoles]) => {
        if (person) {
          person.mainGroup = userGroup;
        }

        let personForm = _.mergeWith(
          {},
          this.defaultData,
          /** Deprecated: This reference to regionRoles will be removed when admin tab is established */
          convertObjectsToData(person, null, regionRoles),
          (a, b) => (b === null ? a : undefined)
        );

        this.formGroup.patchValue(personForm);
        /** Deprecated: This setting of regionRoles will be removed when admin tab is established */
        this.formGroup.get('regionRoles').patchValue(regionRoles);
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      });

    this.subscriptions.add(sub);
  }

  saveFormData() {
    let data = this.formGroup.value;
    this.formGroup.patchValue(data);
    let attr = convertDataToAttr(data);

    /** Deprecated: This return statemen will be removed when admin tab is established (roles and permissions will not need to be saved here) */
    return this.personService.savePerson(attr.person, attr.users).pipe(
      map((person) => person.id),
      switchMap((personId) =>
        combineLatest([
          this.InitialAssignedRoles$,
          this.AssignRegionRoleSelectOptions$,
          of(personId),
        ])
      ),
      take(1),
      map(([initialRoles, roleOptions, personId]) => {
        // Only look at enabled roles
        let enabledRegionRoles = roleOptions
          .filter((regionRole) => !regionRole.disabled)
          .map((opt) => opt.value);
        // Look at roles that the user changed
        let intendedRoles = this.formGroup.get('regionRoles').value as {
          UserGroupId: string;
          RoleId: string;
        }[];
        let changes = enabledRegionRoles.map((regionRole) => {
          let presentOnInitial = Boolean(
            initialRoles.find((initial) =>
              this.AssignRegionRoleCompare(initial, regionRole)
            )
          );
          let presentOnChanged = Boolean(
            intendedRoles.find((role) =>
              this.AssignRegionRoleCompare(role, regionRole)
            )
          );
          let change: 'Assign' | 'Unassign' | undefined;
          if (presentOnInitial && !presentOnChanged) {
            change = 'Unassign';
          } else if (!presentOnInitial && presentOnChanged) {
            change = 'Assign';
          } else if (!presentOnInitial && !presentOnChanged) {
            change = undefined;
          } else if (presentOnInitial && presentOnChanged) {
            change = undefined;
          }
          return {
            regionRole,
            presentOnInitial,
            presentOnChanged,
            change,
          };
        });
        return { changes: changes.filter((item) => item.change), personId };
      }),
      switchMap(({ changes, personId }) => {
        if (changes.length === 0) {
          return of(personId);
        }
        let assignRequests = changes.map((change) => {
          if (change.change === 'Assign') {
            return this.rolesPermissionService.assignRole$({
              PersonId: personId,
              UserGroupId: change.regionRole.UserGroupId,
              RoleId: change.regionRole.RoleId,
            });
          } else if (change.change === 'Unassign') {
            return this.rolesPermissionService.unassignRole$({
              PersonId: personId,
              UserGroupId: change.regionRole.UserGroupId,
              RoleId: change.regionRole.RoleId,
            });
          } else {
            return throwError('This code should never be reached.');
          }
        });
        return combineLatest(assignRequests).pipe(mapTo(personId));
      }),
      tap((personId) => {
        this.authService.loadCurrentUser();
        this.refreshPerson();
        this.personService.refresh$.next();
      }),
      tap(() => {
        this._personId
          ? this.toastrService.success(
              'User was updated successfully.',
              'Success'
            )
          : this.toastrService.success(
              'User was added successfully.',
              'Success'
            );
      }),
      catchError((err, caught) => {
        /**@todo - Standardize Error Messages From API to limit rewriting on frontend */
        let messageDetails = '';
        let messageTitle = 'Error';

        if (JSON.stringify(err).toLowerCase().includes('same email')) {
          messageTitle = 'Account already exists';
          messageDetails =
            'Sorry, an account already exists for this user. If you would like to add this email to this account, delete the existing account and try again.';
        }
        if (err?.error?.errors) {
          let message = '';
          let errors = err?.error?.errors;
          if (errors instanceof Array) {
            errors = errors.map((e) => {
              if (e.message && e.fields) {
                return `${e.message}: ${JSON.stringify(e.fields)}`;
              } else if (e.message) {
                return e.message;
              } else {
                return JSON.stringify(e);
              }
            });
            message = errors.join(' - ');
          } else {
            message = errors.toString();
          }
          let action = this._personId ? 'updated' : 'created';
          messageDetails = `User was not ${action} successfully. Error: ${message}`;
        }
        if (!messageDetails) {
          let action = this._personId ? 'updated' : 'created';
          console.log('Developer: You should parse this error:', err);
          messageDetails = `User was not ${action} successfully. Error: ${err}`;
        }
        this.toastrService.error(messageDetails, messageTitle, {
          tapToDismiss: false,
        });
        console.error(err);
        throw err;
      })
    );

    // TODO: Uncomment when admin tab is established and we no longer need to save roles/permission on this form
    // let savePerson$: Observable<PersonAttributes>;
    // let personId$: Observable<string>;
    // savePerson$ = this.personService.savePerson(attr.person, attr.emails);

    // personId$ = savePerson$.pipe(
    //   map((person) => person.id),
    //   concatMap((id) => {
    //     return of(id);
    //   }),
    //   tap(() => {
    //     this.refreshPerson();
    //     this.toastrService.success('User was updated successfully.', 'Success');
    //   }),
    //   tap(() => {
    //     this.authService.loadCurrentUser();
    //   }),
    //   catchError((err) => {
    //     this.toastrService.error(
    //       `User was not updated successfully. Error: ${err}`,
    //       'Error'
    //     );
    //     return of(null);
    //   })
    // );
    // return personId$;
  }

  /** Deprecated: This will be removed when admin tab is established */
  AssignRegionRoleCompare(
    c1: Partial<AssignedRole>,
    c2: Partial<AssignedRole>
  ) {
    return c1?.UserGroupId === c2?.UserGroupId && c1?.RoleId === c2?.RoleId;
  }

  /** Deprecated: This will be removed when admin tab is established */
  AssignRegionRoleCustomTriggerText(values: AssignedRole[]) {
    if (!(values instanceof Array)) {
      return;
    }
    let systemAdminCount = values.filter(
      (rr) => rr.RoleId === 'system_admin'
    ).length;
    let regionAdminCount = values.filter(
      (rr) => rr.RoleId === 'region_admin'
    ).length;
    return [
      systemAdminCount ? `System Admin` : '',
      regionAdminCount ? `${regionAdminCount} Admin Role(s)` : '',
    ]
      .filter((x) => x)
      .join(' + ');
  }
}

function convertObjectsToData(
  person?: PersonAttributes,
  user?: UserAttributes,
  /** Deprecated: This will be removed when admin tab is established */
  regionRoles?: AssignedRole[]
): FormData {
  return {
    person: {
      id: person?.id || '',
      firstName: person?.firstName || '',
      lastName: person?.lastName || '',
      jobTitle: person?.jobTitle,
    },
    users:
      person?.users?.map((user) => ({
        id: user.id,
        email: user.email,
        notificationOptions: user.notificationOptions,
      })) || [],

    /** Deprecated: This will be removed when admin tab is established */
    regionRoles: regionRoles ?? [],
  };
}

function convertDataToAttr(data: Partial<FormData>): {
  person: PersonAttributes;
  users: UserAttributes[];
} {
  let { id, firstName, lastName, jobTitle } = data.person;
  let person = {
    id: id || undefined,
    firstName: firstName,
    lastName: lastName,
    displayName: `${firstName} ${lastName}`,
    displayNameDropdown: `${firstName} ${lastName} (${data.users[0]?.email})`,
    // registration specific fields
    jobTitle: jobTitle,
  };
  let users = data.users
    .filter((user) => !!user.email)
    .map((user) => ({
      id: user.id,
      email: user.email,
      notificationOptions: { defaultSet: user.notificationOptions?.defaultSet },
    }));
  return { person, users };
}

interface FormData {
  person?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    jobTitle?: string;
  };
  users?: {
    id?: string;
    email?: string;
    notificationOptions?: { defaultSet?: boolean };
  }[];
  /** Deprecated: This will be removed when admin tab is established */
  regionRoles?: AssignedRole[];
}
