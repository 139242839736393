import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { TripAttributes } from '~app/models';
import { FileUploadComponent } from './file-upload/file-upload.component';

@Component({
  selector: 'ciao-attachments-form',
  templateUrl: './attachments-form.component.html',
  standalone: true,
  styleUrl: './attachments-form.component.less',
  imports: [FileUploadComponent, AsyncPipe],
})
export class AttachmentsFormComponent {
  @Input({ required: true }) trip$: Observable<TripAttributes>;
  formGroup = new FormGroup({});
}
