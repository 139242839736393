import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AddValidator } from '../../form.util';

import {
  CustomDateParserFormatter,
  CustomDateAdapter,
} from '../custom-ngb-date-parser-formatter';

@Component({
  selector: 'ciao-form-field-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.less'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
  ],
})
export class FormFieldDateInputComponent implements OnInit, OnDestroy {
  @Input('label') label: string;
  @Input('inputId') inputId: string;

  hoveredDate: NgbDate = null;

  ngbDate: NgbDate;

  @Input() formControlInput: UntypedFormControl;
  @Input() secretFormControl: UntypedFormControl = new UntypedFormControl('');

  private subscriptions: Subscription = new Subscription();

  constructor(
    public formatter: NgbDateParserFormatter,
    public adapter: NgbDateAdapter<string>
  ) {}

  ngOnInit(): void {
    let validator = this.validDateValidator.bind(this);
    AddValidator(this.formControlInput, validator); // for the message
    AddValidator(
      this.secretFormControl,
      (control: AbstractControl) => this.formControlInput.errors
    ); // for the outline
    this.formControlInput.valueChanges
      .pipe(
        map((isoStr) => this.adapter.fromModel(isoStr)),
        map((struct) => this.formatter.format(struct)),
        distinctUntilChanged(),
        tap((humanStr) => this.secretFormControl.setValue(humanStr))
      )
      .subscribe();
    this.secretFormControl.valueChanges
      .pipe(
        map((humanStr) => this.formatter.parse(humanStr)),
        map((struct) => this.adapter.toModel(struct)),
        distinctUntilChanged(),
        tap((isoStr) =>
          this.formControlInput.setValue(isoStr, { emitEvent: false })
        ),
        tap(() => {
          this.formControlInput.markAsTouched();
        })
      )
      .subscribe();
    // setTimeout(() => this.formControlInput.updateValueAndValidity());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDateSelection(date: NgbDate) {
    let humanString = this.formatter.format(date);
    this.secretFormControl.setValue(humanString);
  }

  validDateValidator(control: AbstractControl) {
    let humanString = this.secretFormControl.value;
    let struct = this.formatter.parse(humanString);
    let isoString = this.adapter.toModel(struct);
    if (humanString && !isoString) {
      return { invalid_Date: 'Invalid Date' };
    } else {
      return null;
    }
  }
}
