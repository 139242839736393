<div
  [ngClass]="{ 'one-error': list?.length === 1 }"
  class="errors-list"
  *ngIf="showErrors()"
>
  <ul>
    <ng-template ngFor let-item [ngForOf]="errorList()" let-i="index">
      <li>{{ item | titlecase }}</li>
    </ng-template>
  </ul>
</div>
