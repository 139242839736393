<div class="content">
  <h2 id="trips-table-identifier" class="title">Trips</h2>
  <p
    *ngIf="(teamSelectionOptions$ | async)?.length === 0"
    class="font-red pad-left-2"
  >
    Note: You need to be on a Team to view trips.
    <a [routerLink]="'/help/info/how-to'">Learn how to join a Team here.</a>
  </p>
  <div class="interactables-row">
    <span class="search-and-filter">
      <ciao-form-field
        label="Search"
        [formControlInput]="continuousFilterControls.searchText"
      ></ciao-form-field>
      <!-- <button
        class="btn-action-solid filter-btn"
        (click)="filterModal.openModal()"
      >
        <fa-icon [icon]="['fas', 'filter']"></fa-icon> Filter
      </button> -->
      <ciao-form-field
        class="mat-form-background-brown"
        label="Teams"
        placeholder="All Available Teams"
        type="multiselect"
        [formControlInput]="continuousFilterControls.userGroupId"
        [selectOptions$]="teamSelectionOptions$"
      ></ciao-form-field>
    </span>
    <a class="new-item-button semantic-button" [routerLink]="'/trip/new'">
      New Trip
      <span class="icon" role="presentation">
        <img src="assets/img/icons/new-item-button-icon.svg" alt="Go" />
      </span>
    </a>
  </div>

  <ciao-page-selector
    [displayRowCount]="true"
    [tableName]="'Trips'"
    [paginationResult]="paginationResult$ | async"
  ></ciao-page-selector>
  <div class="scroll-x-on-overflow">
    <table
      ciao-table-style-v1
      [attr.aria-describedby]="'trips-table-identifier'"
    >
      <thead>
        <tr>
          <th ciaoSortableHeader [sortingBy$]="sortingBy$" [sortId]="'endDate'">
            Expected Return
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'tripStatus'"
          >
            Status
          </th>
          <th>Crew</th>
          <th>Supervisor</th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'groupLabel'"
          >
            Team
          </th>
          <th class="column-location">Location</th>
        </tr>
      </thead>
      <tbody *ngIf="paginationResult$ | async as paginationResult">
        <ng-template [ngIf]="paginationResult.isLoading">
          <tr class="messageRow">
            <td [attr.colspan]="6">
              <ciao-loading></ciao-loading>
            </td>
          </tr>
        </ng-template>
        <ng-template
          ngFor
          let-trip
          [ngForOf]="paginationResult?.rows"
          let-i="index"
          [ngForTrackById]
        >
          <tr (click)="goToEditTrip(trip)">
            <td class="column-expected-return">
              {{ trip.endDate | ciaoDate : "datetime" : "readable" }}
            </td>
            <td>
              <span class="status-tile" [ngClass]="'status-' + trip.tripStatus">
                {{ trip.tripStatus }}
              </span>
            </td>
            <td>
              <ng-template ngFor let-crew [ngForOf]="trip.crewMembersProcessed">
                <div
                  [ngClass]="{
                    disabled:
                      !allCrewCheckedIn(trip) &&
                      crew.crewMemberStatus === 'CheckedIn'
                  }"
                >
                  {{ crew.displayName ?? "?" }}

                  {{
                    crew.crewMemberStatus === "CheckedIn" &&
                    trip.tripStatus !== "Closed"
                      ? "(In)"
                      : ""
                  }}
                  <br />
                </div>
              </ng-template>
            </td>
            <td>
              <ng-template ngFor let-crew [ngForOf]="trip.crewMembers">
                {{ crew.supervisor?.displayName ?? "?" }} <br />
              </ng-template>
            </td>
            <td>
              {{ trip.userGroup?.labelPrefix ?? "?" }}
              {{ trip.userGroup?.label ?? "?" }}
            </td>
            <td>
              <span class="single-line">
                {{ trip.locations?.[0]?.description ?? '' }}
              </span>
              <span class="single-line" *ngIf="trip.locations?.[1]">
                <br />
                {{ trip.locations[1].description ?? "" }}
              </span>
              <span class="plus-x" *ngIf="trip.locations?.[2]">
                {{ trip.locations.length - 2 }}
              </span>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <ciao-page-selector
    #primaryPageSelector
    class="pageSelector"
    [tableName]="'Trips'"
    (paginationDataChange)="paginationModalControls.patchValue($event)"
    [paginationResult]="paginationResult$ | async"
    [filteredMessage]="paginationMessage"
    [pageControl]="primaryPageSelector?.pageControl"
  ></ciao-page-selector>
  <p class="help-text">
    Trips will be removed 30 days after the Expected Return
  </p>
</div>

<ciao-modal #filterModal [title]="'Filter Settings'" (result)="resetFilters()">
  <div class="modal-body" modalBody>
    <ciao-form-field
      label="Search"
      [formControlInput]="filterModalControls.searchText"
    ></ciao-form-field>
    <ciao-form-field
      label="Status"
      type="dropdown"
      [formControlInput]="filterModalControls.tripStatus"
      [selectOptions$]="statusFilterOptions$"
    ></ciao-form-field>
    <ciao-form-field
      class="mat-form-background-brown"
      label="Teams"
      placeholder="All Available Teams"
      type="multiselect"
      [formControlInput]="filterModalControls.userGroupId"
      [selectOptions$]="teamSelectionOptions$"
    ></ciao-form-field>
    <ciao-form-field
      label="Only Show Trips I'm Either On Or Supervising"
      type="toggle"
      [formControlInput]="filterModalControls.imNamedOnThisTrip"
    ></ciao-form-field>
    <!--
      TODO: Add filters in backend
      <ciao-form-field
        label="Expected Return Range"
        type="dateRange"
        [formControlInput]="filterModalControls.get('expectedReturnRange')"
      ></ciao-form-field>
      <ciao-form-field
        label="Crew"
        type="multiselect"
        [formControlInput]="filterModalControls.get('crew')"
        [selectOptions$]="crewMemberFilterOptions$"
      ></ciao-form-field>
      <ciao-form-field
        label="Supervisor"
        type="multiselect"
        [formControlInput]="filterModalControls.get('supervisor')"
        [selectOptions$]="crewMemberFilterOptions$"
      ></ciao-form-field>
      <ciao-form-field
        label="Location"
        [formControlInput]="filterModalControls.get('location')"
      ></ciao-form-field>
    -->
    <br />
  </div>
  <div modalFooter>
    <div class="modal-footer-buttons">
      <button
        (click)="clearFilters(); filterModal.close('')"
        class="btn-action-light"
      >
        Clear Filters
      </button>
      <button
        (click)="applyFilters(); filterModal.close('')"
        class="btn-action-solid"
        [disabled]="!filterModalGroup.dirty"
      >
        Apply Filters
      </button>
    </div>
  </div>
</ciao-modal>
