<ciao-modal
  #tripModal
  [alternateCloseAction]="alternateCloseActionEnabled"
  [preventBackdropClosing]="true"
  (modalInstance)="onModalInstance()"
  (result)="onModalClose()"
  (closeAction)="onTripCreationCancel()"
  [loading]="isLoading"
>
  <div modalNav>
    <div id="nav-tabs">
      <ul>
        <ng-container *ngFor="let tabName of tabs">
          <li
            [matTooltip]="ArrowMessage"
            [matTooltipPosition]="tabToolTipPosition"
            [matTooltipDisabled]="!modalIsOpen || !isNavigationDisabled"
            [matTooltipShowDelay]="showDelay"
            [matTooltipClass]="{ 'green-tooltip': true }"
            [class.active]="tabName === currentTab"
            [class.nav-disabled]="isNavigationDisabled"
          >
            <button
              class="semantic-button"
              (click)="currentTab = tabName"
              [disabled]="isNavigationDisabled"
              [class.nav-disabled]="isNavigationDisabled"
            >
              <div class="tab-name">{{ tabName }}</div>
              <span *ngIf="tabName === currentTab" class="triangle"> </span>
            </button>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div modalBody>
    <p *ngIf="canCreateTrips$ | async" class="font-red">
      Note: You need to be on a Team to create a trip.
      <a [routerLink]="'/help/info/how-to'">Learn how to join a Team here.</a>
    </p>
    <div id="trip-modal-table-container" *ngIf="currentData">
      <h3
        *ngIf="currentData?.length && currentTab !== 'Attachments'"
        class="title"
      >
        {{ tabTableNoun }}
      </h3>
      <table *ngIf="currentData?.length && currentTab !== 'Attachments'">
        <caption>
          <span class="screenreader">List of Existing {{ currentTab }}</span>
        </caption>
        <thead>
          <tr>
            <th *ngFor="let column of currentTableColumns">
              {{ column.head }}
            </th>
            <th class="shrink"><span class="screenreader">Options</span></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let row of currentData"
            [class.currently-editing]="row.id === currentlyEditingId"
          >
            <td *ngFor="let column of currentTableColumns">
              <span class="data-content">
                {{ column.accessor(row) }}
              </span>
            </td>
            <td>
              <button
                mat-icon-button
                [matMenuTriggerFor]="rowOptionsMenu"
                [matMenuTriggerData]="{ id: row.id }"
                aria-label="Row Options"
                class="semantic-button"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
        <mat-menu #rowOptionsMenu>
          <ng-template matMenuContent let-id="id">
            <button
              mat-menu-item
              (click)="onRowEdit(id)"
              *ngIf="id !== currentlyEditingId"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item disabled *ngIf="id === currentlyEditingId">
              <mat-icon>edit</mat-icon>
              <i>Currently Editing</i>
            </button>
            <button
              mat-menu-item
              [matMenuTriggerFor]="deleteRowConfirmation"
              [matMenuTriggerData]="{ id: id }"
              *ngIf="canDeleteRow"
            >
              <mat-icon>delete</mat-icon> Delete
            </button>
          </ng-template>
        </mat-menu>
        <mat-menu #deleteRowConfirmation>
          <ng-template matMenuContent let-id="id">
            <button mat-menu-item (click)="onRowDelete(id)">
              <mat-icon style="color: red">delete</mat-icon>
              Confirm Delete
            </button>
          </ng-template>
        </mat-menu>
      </table>
    </div>
    <div>
      <ciao-details-form
        #currentForm
        [editingExisting]="!!tripId"
        *ngIf="currentTab === 'Details'"
      ></ciao-details-form>
      <ciao-locations-form
        #currentForm
        [editingExisting]="editRowMode"
        *ngIf="currentTab === 'Locations'"
      >
      </ciao-locations-form>
      <ciao-crew-form
        #currentForm
        [editingExisting]="editRowMode"
        *ngIf="currentTab === 'Crew'"
        [userGroupId]="(trip$ | async).userGroupId"
        [trip]="trip$ | async"
      >
      </ciao-crew-form>
      <ciao-equipment-form
        #currentForm
        [editingExisting]="editRowMode"
        *ngIf="currentTab === 'Equipment'"
      >
      </ciao-equipment-form>
      <ciao-attachments-form
        #currentForm
        *ngIf="currentTab === 'Attachments'"
        [trip$]="trip$"
      ></ciao-attachments-form>
    </div>
  </div>
  <div modalFooter id="trip-modal-footer">
    <div class="action-btns">
      <button
        class="btn-action-solid"
        [disabled]="!canSave"
        *ngIf="currentTab === 'Details' && !duplicatingMode && !tripId"
        (click)="onSaveDetails()"
      >
        Save and Continue
      </button>
      <button
        class="btn-action-solid"
        [disabled]="!canSave"
        *ngIf="currentTab === 'Details' && !duplicatingMode && tripId"
        (click)="onSaveDetails()"
      >
        Update
      </button>
      <button
        class="btn-action-solid"
        [disabled]="!formIsValid"
        *ngIf="currentTab === 'Details' && duplicatingMode"
        (click)="onDuplicateSend()"
      >
        Duplicate Trip
      </button>
      <button
        class="btn-action-solid"
        [disabled]="!canSave"
        *ngIf="
          currentTab !== 'Details' &&
          !editRowMode &&
          currentTab !== 'Attachments'
        "
        (click)="onAddRow()"
      >
        Add
      </button>
      <button
        class="btn-action-solid"
        [disabled]="!canSave"
        *ngIf="currentTab !== 'Details' && editRowMode"
        (click)="onUpdateRow()"
      >
        Update {{ tabNoun }}
      </button>
      <button class="btn-action-light" *ngIf="editRowMode" (click)="onCancel()">
        Cancel
      </button>
      <button
        class="btn-action-light"
        *ngIf="formIsDirty && !editRowMode"
        (click)="onClearForm()"
      >
        Clear Form
      </button>
      <button
        class="btn-action-light"
        *ngIf="canCheckout"
        (click)="onCheckOut()"
      >
        Check Out
      </button>
      <button
        class="btn-action-light"
        *ngIf="canViewTrip"
        (click)="onViewTrip()"
      >
        View Trip
      </button>
    </div>
    <div class="form-navigation">
      <button
        [matTooltip]="ArrowMessage"
        [matTooltipPosition]="arrowToolTipPosition"
        [matTooltipDisabled]="!isNavigationDisabled"
        [matTooltipClass]="{ 'green-tooltip': true }"
        [disabled]="isNavigationDisabled || isNavLeftDisabled"
        class="btn-action-light icon borderless"
        (click)="prevTab()"
      >
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button
        [matTooltip]="ArrowMessage"
        [matTooltipPosition]="arrowToolTipPosition"
        [matTooltipDisabled]="!isNavigationDisabled"
        [matTooltipClass]="{ 'green-tooltip': true }"
        [disabled]="isNavigationDisabled || isNavRightDisabled"
        class="btn-action-light icon borderless"
        (click)="nextTab()"
      >
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>
</ciao-modal>
