export const headingResources: {
  asset: string;
  headingColor: string;
}[] = [
  {
    asset: '/assets/img/landing/desktop/canyon.jpg',
    headingColor: 'white',
  },
  {
    asset: '/assets/img/landing/desktop/desert2.jpg',
    headingColor: 'white',
  },
  {
    asset: '/assets/img/landing/desktop/misty.jpg',
    headingColor: 'black',
  },
  {
    asset: '/assets/img/landing/desktop/snow.jpg',
    headingColor: 'white',
  },
  {
    asset: '/assets/img/landing/desktop/sunshine.jpg',
    headingColor: 'black',
  },
  {
    asset: '/assets/img/landing/desktop/twilight.jpg',
    headingColor: 'white',
  },
  {
    asset: '/assets/img/landing/desktop/pine.jpg',
    headingColor: 'white',
  },
];

export const headingGreetings: { message: string }[] = [
  {
    message: `# CIAO is part of <br /> keeping you safe.`,
  },
  { message: `# Stay connected in the field.` },
  { message: `# Keep crews safe <br /> with CIAO.` },
  { message: `# Welcome to <br />FS CIAO.` },
  { message: `# Check in or out right here.` },
];
