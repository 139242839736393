<div class="file-upload-container">
  <input
    type="file"
    multiple
    (change)="onFileSelected($event)"
    hidden
    #fileInput
  />

  <div class="file-specs">
    <p>Maximum file size: 10 MB</p>
    <p>File Types: jpg, png, pdf, doc, docx, xls, xlsx, gpx</p>
  </div>

  <div class="flexbox">
    <div
      class="drag-drop-area"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
    >
      Drag and drop file here or...
      <button class="browse-btn" (click)="fileInput.click()">
        Browse for file(s)
      </button>
    </div>
    <div
      class="file-list"
      [ngClass]="{ aligncenter: (allFiles$ | async).length < 1 }"
    >
      <ciao-individual-file-bar
        class="file"
        *ngFor="let file of allFiles$ | async; let i = index"
        [file]="file"
        (removeFile)="deleteFile(file)"
      >
      </ciao-individual-file-bar>
      <div *ngIf="(allFiles$ | async).length < 1" class="no-files">
        No files to show
      </div>
      <div class="file flex-placeholder">
        <!-- This placeholder keeps the last item in two-column flex layouts from becoming 100% width-->
      </div>
    </div>
  </div>
</div>
