import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ciao-combobox-tester',
  templateUrl: './combobox-tester.component.html',
  styleUrls: ['./combobox-tester.component.less'],
})
export class ComboboxTesterComponent implements OnInit {
  controls = {
    main: new UntypedFormControl('', [this.validate.bind(this)]),
    auxillary: new UntypedFormControl(''),
  };
  controlGroup = new UntypedFormGroup(this.controls);
  selectOptions = [
    { label: 'Alpha', value: { id: 'alpha' } },
    { label: 'Beta', value: { id: 'beta' } },
    { label: 'Gamma', value: { id: 'gamma' } },
  ];
  selectOptions$ = of(this.selectOptions);

  compareWith = (a: any, b: any) => {
    if (!a || !b) return false;

    const bothId = a?.id && b?.id;
    if (bothId) return a?.id === b?.id;

    if (a?.id) return a?.id === b;
    if (b?.id) return a === b?.id;

    return a === b;
  };

  get debugVars() {
    const [options, { main, auxillary }] = [
      this.selectOptions,
      this.controlGroup.value,
    ];
    return {
      mainValue: main ?? 'none',
      auxillaryValue: auxillary ?? 'none',
      exactMatchingOption: options.find((opt) => opt.value === main) ?? 'none',
      compareWithMatchingOption:
        options.find((opt) => this.compareWith(opt.value, main)) ?? 'none',
      twoControlsSame: main?.id === auxillary?.id,
    };
  }

  debugVars$ = combineLatest([
    this.selectOptions$,
    this.controlGroup.valueChanges.pipe(startWith(this.controlGroup.value)),
  ]).pipe(
    map(([options, { main, auxillary }]) => {
      return {
        mainValue: main ?? 'none',
        auxillaryValue: auxillary ?? 'none',
        exactMatchingOption:
          options.find((opt) => opt.value === main) ?? 'none',
        compareWithMatchingOption:
          options.find((opt) => this.compareWith(opt.value, main)) ?? 'none',
        twoControlsSame: main?.id === auxillary?.id,
      };
    })
  );

  constructor() {}

  ngOnInit(): void {
    // this.controls.auxillary.setValue({ id: 'gamma' });
    this.controlGroup.patchValue({
      auxillary: { id: 'gamma' },
      main: { id: 'alpha' },
    });
    // setTimeout(() => this.controls.auxillary.setValue({ id: 'beta' }), 10000);
    // this.controls.auxillary.setValue({ id: 'beta' });
    this.controls.main.markAsTouched();
  }

  changeValue(id) {
    // setTimeout(() => this.controls.main.setValue({ id }), 10000);
    this.controls.main.setValue({ id });
  }

  validate() {
    const main = this.controls?.main?.value?.id;
    const auxillary = this.controls?.auxillary?.value?.id;
    console.log(`Validate ${main} !== ${auxillary}`);

    if (main === auxillary) {
      console.log('Cake is a lie');
      return { nomatch: 'The two values may not match' };
    } else {
      return null;
    }
  }
}
