import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PersonService } from '~app/services';
import { PersonAttributes } from '~app/models';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  share,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  SortingCriteria,
  sortingCriteriaToPaginationSortOrder,
} from '~app/components/report-table/types';
import {
  IsLoadingPaginationResult,
  PaginationData,
  PaginationResult,
} from '~app/models/pagination-data';
import * as _ from 'lodash-es';
import { BooleanInput } from '@angular/cdk/coercion';
import { UserGroupService } from '~app/services/user-group.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UserGroupAttributes } from '~app/models/user-group';
import { UserModalComponent } from '~app/components/views/user-modal/user-modal.component';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';

type GroupOptions = {
  label: string;
  id: UserGroupAttributes['id'];
  parentId: UserGroupAttributes['parentId'];
  userGroupObject: UserGroupAttributes;
  children?: GroupOptions[];
};

@Component({
  selector: 'people-table',
  templateUrl: './people-table.component.html',
  styleUrls: ['./people-table.component.less'],
})
export class PeopleTableComponent implements OnInit, OnDestroy {
  @Input() allowFocusChange: BooleanInput;

  @ViewChild('userModal') userModal: UserModalComponent;

  selectedData: PersonAttributes;
  personId: string = null;

  //#region TableStuff
  //lone control to filter report table
  searchTextControl = new UntypedFormControl('');
  continuousFilterGroup = new UntypedFormGroup({
    searchText: this.searchTextControl,
  });

  filterModalControls = {
    roles: new UntypedFormGroup({
      system_admin: new UntypedFormControl(),
      region_admin: new UntypedFormControl(),
      forest_admin: new UntypedFormControl(),
      supervisor: new UntypedFormControl(),
      team_member: new UntypedFormControl(),
    }),
    /** filled in dynamically */
    regions: new UntypedFormGroup({}),
    /** filled in dynamically */
    forests: new UntypedFormGroup({}),
    /** The set of checkboxes that control "All" the other checkboxes in their group */
    allChecks: new UntypedFormGroup({}),
  };
  filterModalGroup: UntypedFormGroup;
  filterModalInitialized = false;
  readonly filterRegionList$ = this.initializeFilterModalRegionOptions();
  readonly applyFilters$ = new BehaviorSubject<void>(null);
  /** For reseting the filters, ie: when you close the modal without applying */
  previousFilterData: any;

  //font awesome'
  filter = faFilter;

  readonly filterData$ = combineLatest([
    this.continuousFilterGroup.valueChanges.pipe(
      startWith(this.continuousFilterGroup.value),
      debounceTime(500),
      distinctUntilChanged((x, y) => _.isEqual(x, y))
    ),
    this.applyFilters$,
  ]).pipe(switchMapTo(this.getFilterDataObservableLogic()));

  sortingBy$ = new BehaviorSubject<SortingCriteria<PersonAttributes>[]>([
    { sortId: 'firstName', order: 'ASC' },
  ]);
  pagingInfo$ = new BehaviorSubject({ limit: 10, offset: 0 });
  get paginationMessage() {
    return this.searchTextControl.value
      ? `matching '${this.searchTextControl.value}'`
      : '';
  }

  paginationResult$: Observable<PaginationResult<PersonAttributes>> =
    combineLatest([
      this.filterData$,
      this.sortingBy$,
      this.pagingInfo$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
    ]).pipe(
      // Normally, I don't like to have this much logic in a single operator
      // But I don't know how to make it actually work without nesting all of this.
      switchMap(([filterData, sortBy, paging]) => {
        if (filterData.errors.noUserGroupsMatch) {
          return of({
            totalRowCount: 0,
            rowCount: 0,
            rows: [],
            isLoading: false,
          }).pipe(
            delay(200),
            startWith(IsLoadingPaginationResult<PersonAttributes>())
          );
        }

        let order = sortingCriteriaToPaginationSortOrder(sortBy);
        let where = Object.entries(filterData.filters).filter(
          (item) => !!item[1]
        );
        let paginationData: PaginationData = {
          limit: paging.limit,
          offset: paging.offset,
          order,
          where,
        };

        return this.personService.search(paginationData).pipe(
          catchError((err: HttpErrorResponse, caught) => {
            console.error(err);
            this.toastrService.error(
              err.error.errors?.toString() || err.message,
              'Users Not Loaded'
            );
            return of({
              totalRowCount: -1,
              rowCount: -1,
              rows: [],
              isLoading: true,
            });
          })
        );
      }),
      share()
    );

  get filterShouldShowForestLab() {
    let { forest_admin, team_member, supervisor } =
      this.filterModalGroup.value.roles;
    return Boolean(forest_admin || team_member || supervisor);
  }
  get filterShouldShowRegionStation() {
    let { region_admin, forest_admin, team_member, supervisor } =
      this.filterModalGroup.value.roles;
    return Boolean(region_admin || forest_admin || team_member || supervisor);
  }

  private allSubscriptions: Subscription = new Subscription();
  constructor(
    private personService: PersonService,
    public userGroupService: UserGroupService,
    private toastrService: ToastrService
  ) {
    this.allSubscriptions.add(this.filterRegionList$.pipe(take(1)).subscribe());
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  clearFilters() {
    this.filterModalGroup.reset();
    this.applyFilters$.next();
    this.previousFilterData = this.filterModalGroup.value;
  }
  applyFilters() {
    this.previousFilterData = this.filterModalGroup.value;
    this.applyFilters$.next();
    this.filterModalGroup.markAsPristine();
  }
  resetFilters() {
    this.filterModalGroup.setValue(this.previousFilterData);
  }

  toggleExpandedRows(
    person,
    toggleChoice: 'expand' | 'collapse' | 'toggle',
    $event?: MouseEvent
  ) {
    switch (toggleChoice) {
      case 'toggle':
        if (this.selectedData?.id === person?.id) {
          this.selectedData = null;
        } else {
          this.selectedData = person;
        }
        break;
      case 'expand':
        this.selectedData = person;
        break;
      case 'collapse':
        this.selectedData = null;
        break;
    }
    $event?.stopPropagation();
  }

  /** This Method is used to gather the appropriate roles and permissions given the person id. This could be used later on in the admin tab if applicable. */
  // openViewPersonModal(person: PersonAttributes) {
  //   this.selectedData = person;

  //   const sub = this.rolePermissionService
  //     .searchRoles({
  //       personIds: [this.selectedData.id],
  //       roleIds: ['system_admin', 'region_admin'],
  //     })
  //     .pipe(
  //       tap((assignedRoles) => {
  //         this.selectedData.regionRoles = assignedRoles;
  //       })
  //     )
  //     .subscribe();
  //   this.allSubscriptions.add(sub);

  //   this.viewPersonModal.openSmallModal();
  // }

  openUserModal(person) {
    this.personId = person?.id ?? null;
    this.userModal?.openModal();
  }

  initializeFilterModalRegionOptions() {
    this.filterModalInitialized = false;
    return combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
      this.userGroupService.allTeams$,
    ]).pipe(
      take(1),
      map(([system, regions, forests, teams]) => ({
        system,
        regions,
        forests,
        teams,
      })),
      map(({ system, regions, forests, teams }) => {
        let regionOptions = regions.map((region) => {
          let forestOptions = region.children
            .filter((forest) => forest.tierLabel === 'ForestLab')
            .map((forest) => {
              return {
                label: forest.labelPrefix + forest.label,
                id: forest.id,
                parentId: forest.parentId,
                userGroupObject: forest,
              };
            });
          return {
            label: region.labelPrefix + region.label,
            id: region.id,
            parentId: region.parentId,
            children: forestOptions,
            userGroupObject: region,
          };
        });
        return regionOptions as GroupOptions[];
      }),
      tap((regionOptions) => {
        this.filterModalControls.regions = new UntypedFormGroup({});
        this.filterModalControls.forests = new UntypedFormGroup({});
        this.filterModalControls.allChecks = new UntypedFormGroup({});
        this.filterModalControls.allChecks.addControl(
          'regions',
          new UntypedFormControl()
        );
        regionOptions.forEach((region) => {
          this.filterModalControls.regions.addControl(
            region.id,
            new UntypedFormControl()
          );
          this.filterModalControls.allChecks.addControl(
            region.id,
            new UntypedFormControl()
          );
          region.children.forEach((forest) => {
            this.filterModalControls.forests.addControl(
              forest.id,
              new UntypedFormControl()
            );
          });
        });
        this.filterModalGroup = new UntypedFormGroup(this.filterModalControls);
        this.clearFilters();
      }),
      tap((regionOptions) => {
        this.initializeFilterModalBehaviors(regionOptions);
      }),
      tap(() => {
        this.filterModalInitialized = true;
      }),
      shareReplay(1)
    );
  }

  debug(...args) {
    console.log(...args);
  }

  initializeFilterModalBehaviors(regionOptions: GroupOptions[]) {
    // Checking the regions - "All" checkbox should check all the regions.
    // Checking one of the forests - "All" checkbox should check all forests in that region.
    // Unchecking regions "All" when it is checked should uncheck all regions.
    // Unchecking one of the forests - "All" boxes should uncheck all forests in that region.
    // Unchecking one of the regions should uncheck the regions - "All" checkbox
    // Unchecking one of the forests should uncheck the "All" checkbox for that region.
    // Manually checking the last region  should automatically check or uncheck the Regions - "All" checkbox.
    // Manually checking the forest in a group should automatically check or uncheck the All Forests checkbox for that region.

    // Tried to use `{emitEvent: false}`, but that didn't work. Because we can't compare with unemitted events.
    // Instead, using skipChanges and skipping changes that occur while skipChanges is true.

    const forestOptions = regionOptions.map((region) => region.children).flat();
    const groupOptionsById = Object.fromEntries(
      [...regionOptions, ...forestOptions].map((option) => [option.id, option])
    ) as { [id: string]: GroupOptions };

    let skipChanges = false;

    // Make "All" checkboxes check and uncheck boxes in their group
    this.allSubscriptions.add(
      this.filterModalControls.allChecks.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let groupId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return { groupId, value: newValues[groupId] };
          }),
          filter(({ groupId, value }) => !!groupId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          map(({ groupId, value }) => {
            let list = regionOptions;
            let control = this.filterModalControls.regions;
            if (groupId !== 'regions') {
              list = groupOptionsById[groupId].children;
              control = this.filterModalControls.forests;
            }

            skipChanges = true;
            control.patchValue(
              Object.fromEntries(list.map((group) => [group.id, value]))
              // { emitEvent: false }
            );
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // Check and Uncheck
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          map((regions) => Object.entries(regions).every((entry) => entry[1])),
          filter((_) => !skipChanges),
          tap((allRegionsChecked) => {
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              regions: allRegionsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
    this.allSubscriptions.add(
      this.filterModalControls.forests.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let forestId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return forestId;
          }),
          filter((forestId) => !!forestId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          tap((forestId) => {
            // Find region that this forest is in.
            // Check all forests in that region to see if they're checked or not.
            const forestData = this.filterModalControls.forests.value;
            const regionId = groupOptionsById[forestId].parentId;
            const forestIds = groupOptionsById[regionId].children.map(
              (forest) => forest.id
            );
            const allForestsChecked = forestIds.every(
              (forestId) => forestData[forestId]
            );
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              [regionId]: allForestsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // If a region is unchecked, then mark all children as false.
    // Do this regardless of whether it's in response to one of the other automated behaviors
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            return Object.keys(newValues)
              .filter((k) => newValues[k] !== oldValues[k])
              .map((regionId) => ({ regionId, value: newValues[regionId] }));
          }),
          mergeMap((regions, index) => of(...regions)),
          // regionId is truthy and value is falsey
          filter(({ regionId, value }) => !!regionId && !value),
          // End Find Changed Key
          tap(({ regionId, value }) => {
            let patchValue = Object.fromEntries(
              groupOptionsById[regionId].children.map((child) => [
                child.id,
                false,
              ])
            );
            this.filterModalControls.allChecks.patchValue({
              [regionId]: false,
            });
            this.filterModalControls.forests.patchValue(patchValue);
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
  }

  getFilterDataObservableLogic() {
    return this.userGroupService.userGroupsByTier$.pipe(
      map((allGroups) => {
        const modalData: {
          roles: { [key: string]: true };
          regions: { [key: string]: true };
          forests: { [key: string]: true };
        } = this.filterModalGroup.value;
        const filterData = {
          filters: {
            searchText: this.searchTextControl.value,
            userGroups: [],
            roles: [],
          },
          errors: {
            noUserGroupsMatch: false,
          },
        };
        /** Calculations that are used multiple times (or in multiple iterations of one loop) */
        const modalDataProcessed = {
          /**
           * Use this to determine if at least one forest inside a region selection is selected.
           * For the purpose of selecting all the forests in a region.
           */
          regionsPartiallySelected: Object.fromEntries(
            allGroups.regions.map((region) => [
              region.id,
              region.children.some((forest) => modalData.forests[forest.id]),
            ])
          ),
          someGroupSelectionMade:
            (allGroups.regions.some((region) => modalData.regions[region.id]) &&
              this.filterShouldShowRegionStation) ||
            (allGroups.forests.some((forest) => modalData.forests[forest.id]) &&
              this.filterShouldShowForestLab),
        };

        // Add Roles
        {
          if (modalData.roles.system_admin) {
            filterData.filters.roles.push('system_admin');
          }
          if (modalData.roles.region_admin || modalData.roles.forest_admin) {
            // Due to a previous decision, the role associated with Forest Admin is the same role as Region Admin.
            // So both Region Admin and Forest Admin are 'region_admin' in the database
            filterData.filters.roles.push('region_admin');
          }
          if (modalData.roles.supervisor) {
            filterData.filters.roles.push('supervisor');
          }
          if (modalData.roles.team_member) {
            filterData.filters.roles.push('team_member');
          }
        }

        // Add UserGroups, but only if group selections were made.
        if (modalDataProcessed.someGroupSelectionMade) {
          if (modalData.roles.system_admin) {
            filterData.filters.userGroups.push(allGroups.system.id);
          }
          if (modalData.roles.region_admin) {
            filterData.filters.userGroups.push(
              ...allGroups.regions
                .filter((region) => modalData.regions[region.id])
                .map((region) => region.id)
            );
          }
          if (modalData.roles.forest_admin) {
            filterData.filters.userGroups.push(
              ...allGroups.forests
                .filter(
                  (forest) =>
                    // Add all selected forests
                    modalData.forests[forest.id] ||
                    // Add all forests for each selected region that has no forests selected
                    (modalData.regions[forest.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        forest.regionStation?.id
                      ])
                )
                .map((forest) => forest.id)
            );
          }
          if (modalData.roles.supervisor || modalData.roles.team_member) {
            filterData.filters.userGroups.push(
              ...allGroups.teams
                .filter(
                  (team) =>
                    // Add all teams for each forest selected
                    modalData.forests[team.forestLab?.id] ||
                    // Add all teams for each forest in each selected region that has no forests selected
                    (modalData.regions[team.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        team.regionStation?.id
                      ])
                )
                .map((region) => region.id)
            );
          }
        } else {
          // No Selection Made, so no groups sent and searching all
          // If region or forest admin and not region admin AND forest admin...
          //   ... Then we should send groups to not search both
          // Due to a previous decision, the role associated with Forest Admin is the same role as Region Admin.
          // So both Region Admin and Forest Admin are 'region_admin' in the database
          let {
            system_admin,
            region_admin,
            forest_admin,
            team_member,
            supervisor,
          } = modalData.roles;
          if (forest_admin !== region_admin) {
            if (forest_admin) {
              filterData.filters.userGroups.push(
                ...allGroups.forests.map((forest) => forest.id)
              );
            } else if (region_admin) {
              filterData.filters.userGroups.push(
                ...allGroups.regions.map((region) => region.id)
              );
            }
            if (team_member || supervisor) {
              filterData.filters.userGroups.push(
                ...allGroups.teams.map((team) => team.id)
              );
            }
            if (system_admin) {
              filterData.filters.userGroups.push(allGroups.system.id);
            }
          }
        }

        if (
          filterData.filters.userGroups.length === 0 &&
          modalDataProcessed.someGroupSelectionMade
        ) {
          filterData.errors.noUserGroupsMatch = true;
        }

        return filterData;
      })
    );
  }

  isSelected(person: PersonAttributes) {
    return this.selectedData?.id === person.id;
  }
}
