<div>
  <section class="usa-banner">
    <div class="container">
      <img
        class="usa-flag"
        src="assets/img/us_flag_small.png"
        alt="U.S. flag"
      />
      <p class="usa-banner-header-text">
        An official website of the United States government
        <button class="usa-banner-button" (click)="collapseHeader()">
          <span class="usa-banner-button-text">
            Here's how you know
            <fa-icon [icon]="arrowDown" *ngIf="!isCollapsed"></fa-icon>
            <fa-icon [icon]="arrowUp" *ngIf="isCollapsed"></fa-icon>
          </span>
        </button>
      </p>
    </div>

    <div class="usa-banner-content container" *ngIf="isCollapsed">
      <div class="usa-banner-content-official">
        <img
          class="usa-banner-img"
          src="assets/img/icon-dot-gov.svg"
          alt="Dot gov"
        />
        <div class="usa-banner-content-body">
          <p>
            <strong>Official websites use .gov</strong>
            <br />A .gov website belongs to an official government organization
            in the United States.
          </p>
        </div>
      </div>
      <div class="usa-banner-content-secure">
        <img
          class="usa-banner-img"
          src="assets/img/icon-https.svg"
          alt="Https"
        />
        <div class="usa-banner-content-body">
          <p>
            <strong> Secure .gov websites use HTTPS </strong>
            <br />
            A <strong>lock</strong> (
            <span class="icon-lock">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 52 64"
                class="usa-banner__lock-image"
                role="img"
                aria-labelledby="banner-lock-title banner-lock-description"
              >
                <title id="banner-lock-title">Lock</title>
                <desc id="banner-lock-description">A locked padlock</desc>
                <path
                  fill="#000000"
                  fill-rule="evenodd"
                  d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z"
                />
              </svg>
            </span>
            ) or <strong>https://</strong> means you’ve safely connected to the
            .gov website. Share sensitive information only on official, secure
            websites.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
