<ciao-announcement-bar></ciao-announcement-bar>
<div class="container" id="mainContainer">
  <div class="content-row">
    <div class="heading-container">
      <markdown class="major-head" [data]="majorHead"></markdown>
      <div class="minor-head">
        <img
          class="fs-logo"
          src="/assets/img/usda/fs_logo.png"
          alt="Forest Service Logo"
        />
        <h2>Safeguarding our greatest asset, you.</h2>
      </div>
    </div>
    <div class="right">
      <div class="grey-box d-flex justify-content-center btn-group-vertical">
        <ciao-okay-modal
          class="center"
          buttonClass="btn-action-light"
          description="Have a safe trip!"
          okayType="checkOut"
        ></ciao-okay-modal>
        <ciao-okay-modal
          class="center"
          buttonClass="btn-action-light"
          description="Welcome back!"
          okayType="checkIn"
        ></ciao-okay-modal>
        <div class="trip-landing">
          <!-- BUTTON TRIGGER FOR MODAL -->
          <button class="btn-action-solid" (click)="openNewTripModal()">
            <div class="inline">
              <fa-icon class="fa-2x" [icon]="compass"></fa-icon>
              <h4>Start a New Trip</h4>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ciao-popup-get-started></ciao-popup-get-started>
