import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, timer } from 'rxjs';
import {
  defaultIfEmpty,
  filter,
  map,
  share,
  skipWhile,
  take,
  takeLast,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { PersonAttributes } from '~models';
import { ApiService } from './api.service';
import { AuthDataService, whoamiResponse } from './auth-data.service';
import { ErrorService } from './error.service';
import { HttpClient } from '@angular/common/http';
import { executeDelayed } from '~app/utilities/execute-delayed.operator';
import { LoadingDelayPopupService } from '~app/components/loading-delay-popup/loading-delay-popup.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  maxTimesRedirected = 12;
  readonly timesRedirectedKey = 'timesAuthLoginRedirected';
  readonly errorListKey = 'oAuthErrorList';
  get currentUser$() {
    return this.authDataService.currentUser$;
  }
  get oAuthInfo$() {
    return this.authDataService.oAuthInfo$;
  }
  get sessionExpiry$() {
    return this.authDataService.sessionExpiry$;
  }

  /** an observable that emits one (most recent) personId, and then completes */
  get personId$() {
    return this.authDataService.currentUser$.pipe(
      filter((x) => !!x),
      map((currentUser) => currentUser.personId),
      take(1)
    );
  }

  private _errors$ = new BehaviorSubject<(Error | string)[]>(
    JSON.parse(window.sessionStorage.getItem(this.errorListKey)) || []
  );
  errors$ = this._errors$.asObservable();

  private _loadingUser = false;

  private _redirectUrl: string;
  public get redirectUrl() {
    if (window) {
      this._redirectUrl = window.sessionStorage.getItem('redirectAfterLogin');
    }
    return this._redirectUrl;
  }
  public set redirectUrl(value: string) {
    // TODO: guard against funky urls
    this._redirectUrl = value;
    if (window) {
      window.sessionStorage.setItem('redirectAfterLogin', value);
    }
  }

  private codeAndState$ = this.route.queryParams.pipe(
    filter((params) => params.code && params.state),
    map((params) => ({ code: params.code, state: params.state })),
    takeUntil(timer(100)),
    takeLast(1),
    defaultIfEmpty(null),
    tap((value) => {
      if (value) {
        this.router.navigate([], { relativeTo: this.route });
      }
    }),
    share()
  );

  constructor(
    private httpClient: HttpClient,
    private authDataService: AuthDataService,
    private errorService: ErrorService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingDelayPopupService: LoadingDelayPopupService
  ) {
    // this.loadCurrentUser();
    let existingErrors =
      JSON.parse(window.sessionStorage.getItem(this.errorListKey)) || [];
    this._errors$.next(existingErrors);
    this.errors$
      .pipe(
        // tap((errors) => console.log(errors)),
        map((errors) => errors.map((value) => (value || '').toString())),
        tap((errors) =>
          window.sessionStorage.setItem(
            this.errorListKey,
            JSON.stringify(errors)
          )
        )
      )
      .subscribe();
    this.authDataService._needsUpdate$
      .pipe(
        skipWhile(() => this._loadingUser),
        tap(() => this.loadCurrentUser())
      )
      .subscribe();
  }

  pushError(err: Error | string) {
    let list = this._errors$.value;
    list.push(err);
    this._errors$.next(list);
  }

  incrementTimesRedirected() {
    let timesRedirected =
      parseInt(window.sessionStorage.getItem(this.timesRedirectedKey)) || 0;
    if (timesRedirected > this.maxTimesRedirected) {
      throw new Error(
        `Redirects exceeded ${this.maxTimesRedirected} times.  Something's wrong with the login.`
      );
    }
    timesRedirected++;
    window.sessionStorage.setItem(
      this.timesRedirectedKey,
      timesRedirected.toString()
    );
  }
  resetTimesRedirected() {
    window.sessionStorage.setItem(this.timesRedirectedKey, '0');
    window.sessionStorage.setItem(this.errorListKey, '[]');
  }

  async logout() {
    console.log('Signing Out');
    // without `{withCredentials: true}`

    const singleUrlSignOut = (url, popup: Window) => {
      return new Promise((resolve, reject) => {
        popup.location = url;
        /*
        Note: This is having some intermittent problems where it doesn't log out of eAuth (and possibly others?).
        Some folks on some devices experience this <5% of the time.  Others experience it about 50% of the time.
        Theory:
          - 0.5 seconds is not long enough for eAuth to always log out.
          - 2 seconds should do the trick.
        */
        setTimeout(resolve, 2000);
      });
    };

    const urls = await this.apiService
      .GetAll<string>('/auth/get-logout-urls')
      .toPromise();

    const popup = window.open('', '_blank', 'popup,width=300;height=300');
    window.focus();
    for (let i = 0; i < urls.length; i++) {
      await singleUrlSignOut(urls[i], popup);
    }
    popup.close();
    await this.apiService.GetAll('/auth/logout').toPromise();
    window.sessionStorage.clear();
    this.loadCurrentUser();
  }

  registerNewUser(
    personAttributes: Partial<PersonAttributes> & {
      firstName: string;
      lastName: string;
    }
  ) {
    return this.apiService.Post('/auth/register', personAttributes).pipe(
      tap(() => this.loadCurrentUser()),
      tap(() => this.router.navigate(['/'])),
      map((result) => result)
      //tap(() => window.location.reload())
    );
  }

  /**
   * Load the current user.
   *
   * Send the /whoami request and all other accompanying processes.
   *
   * If the client is not authenticated, redirect to authentication
   * If the client is authenticated but not registered, redirect to register page
   *
   * Load relevant info into:
   * - currentUser
   * - loginUrl
   * - oAuthInfo
   * - sessionExpiry
   *
   */
  loadCurrentUser() {
    if (this._loadingUser) {
      return;
    }
    this._loadingUser = true;
    let queryParams = new URLSearchParams(window.location.search);
    let codeAndState = {
      code: queryParams.get('code'),
      state: queryParams.get('state'),
    };
    let whoami: Observable<whoamiResponse>;
    if (codeAndState.code && codeAndState.state) {
      this.router.navigate([''], {
        queryParams: {
          code: null,
          state: null,
        },
        queryParamsHandling: 'merge',
      });
      whoami = this.apiService.Post('/auth/whoami', codeAndState);
    } else {
      whoami = this.apiService.GetSingle('/auth/whoami');
    }
    let obs = whoami.pipe(
      executeDelayed(10000, () => {
        this.loadingDelayPopupService.openPopup();
      }),
      tap(() => {
        this.loadingDelayPopupService.closePopup();
      }),
      tap((authInfo) => {
        this._loadingUser = false;
        this.authDataService._currentUser$.next(authInfo.user);
        this.authDataService._loginUrl$.next(authInfo.redirectUri);
        this.authDataService._oAuthInfo$.next(authInfo.oAuthInfo);
        this.authDataService._sessionExpiry$.next(
          new Date(authInfo.sessionExpiry)
        );
        this.pushError(authInfo.error || null);
        if (authInfo.registered) {
          // implies authInfo.authenticated
          this.resetTimesRedirected();
          this.authDataService._foundUser$.next();
        } else if (authInfo.authenticated) {
          //console.log('Authenticated');
          // implies !authInfo.registered
          // window.location.search = '';
          this.router.navigate(['/register/'], {
            queryParams: {
              code: null,
              state: null,
            },
            queryParamsHandling: 'merge',
          });
        } else {
          // implies !authInfo.authenticated && !authInfo.registered
          this.incrementTimesRedirected();
          window.location.href = authInfo.redirectUri;
        }
      }),
      tap({
        error: (err) => {
          this.pushError(err);
        },
      })
    );
    obs.subscribe();
  }

  refreshSession$() {
    return this.apiService
      .Custom('POST', '/auth/refresh-session', null, {
        responseType: 'text',
      })
      .pipe(tap((_) => this.loadCurrentUser()));
  }
}

type CodeAndStateInput = {
  code: string;
  state: string;
};
