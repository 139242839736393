import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public maximumErrorsStored = 30;
  private errorList: Error[] = [];
  private _lastError: BehaviorSubject<Error> = new BehaviorSubject(null);
  public readonly lastError = this._lastError.asObservable();
  constructor() {}

  addError(value: Error | string) {
    if (!(value instanceof Error)) {
      value = new Error(value);
    }
    this.errorList.unshift(value);
    this._lastError.next(value);
    // If more errors stored than are supposed to be
    this.errorList.length = Math.min(
      this.errorList.length,
      this.maximumErrorsStored
    );
  }

  clearErrors() {
    this.errorList.length = 0;
    this._lastError.next(null);
  }

  getErrors(count?: number): Error[] {
    // on the off chance that count === errorList.length, return the whole list
    // otherwise, count should be a number between 0 and the length of the array.
    let countIsValid = this.errorList[count];
    // no one needs to grab a 0-length array.
    if (count && countIsValid) {
      return this.errorList.slice(0, count);
    } else {
      return this.errorList.slice();
    }
  }
}
