<ng-template #DialogTemplate>
  <div mat-dialog-title>
    <button
      mat-button
      type="button"
      class="close"
      aria-label="Close"
      (click)="onManualClose()"
    >
      <mat-icon fontSet="material-icons-round">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <img
      alt="decoration image. female ranger on brown background."
      src="/assets/img/ciao/female-ranger-on-background-2.png"
    />
    <!-- <h2>Let's get started!</h2>
    <p>
      Explore CIAO now. Need assistance? Visit Help from the top navigation
      anytime.
    </p>
    <p>
      Get quick access to videos, KBAs, FAQs, and other resources. We're always
      updating the Help Center with new tips based on your feedback, so if you
      have a question, go there first and look for the Getting Started Guide.
    </p> -->
    <h2>Let's get started!</h2>
    <p>
      Our videos, KBAs, FAQs, and Getting Started Guide will help you discover
      how to join a team, manage trip check-ins and check-outs, and explore
      other helpful features in our extensive Help Center
    </p>
    <!-- <p>
      Explore CIAO now. Need assistance? Visit Help from the top navigation
      anytime. Get quick access to videos, KBAs, FAQs, and other resources.
      We're always updating the Help Center with new tips based on your
      feedback, so if you have a question, go there first and look for the
      Getting Started Guide.
    </p> -->
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="visit-help-button-container">
      <a routerLink="/help" class="btn-action-solid" (click)="onManualClose()">
        Visit Help Now!
      </a>
    </div>
  </mat-dialog-actions>
</ng-template>
