import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
  name: 'filesize',
  standalone: true,
})
export class FilesizePipe implements PipeTransform {
  transform(byteCount: number, ifZero: string = '?'): string {
    if (!byteCount) {
      return ifZero;
    }
    return filesize(byteCount, { standard: 'jedec' });
  }
}
