<div class="file-row">
  <div class="name-progress" [class]="statusIndicator.class">
    <div mat-line>
      <a
        target="_blank"
        rel="noopener"
        [href]="file.viewHref"
        [class.disabled]="!statusIndicator.clicksAllowed"
        [ngClass]="{ disabled: !statusIndicator.clicksAllowed }"
        [matTooltip]="file.fileName"
      >
        <span class="file-name-sec1">
          {{ file.fileName.slice(0, file.fileName.length - 8) }}
        </span>
        <span class="file-name-sec2">
          {{ file.fileName.slice(file.fileName.length - 8) }}
        </span>
      </a>
    </div>
    <mat-progress-bar
      [style.display]="!statusIndicator.show ? 'none' : 'block'"
      [mode]="statusIndicator.mode"
      [value]="statusIndicator.value"
    ></mat-progress-bar>
  </div>

  <button
    mat-icon-button
    [matMenuTriggerFor]="rowOptionsMenu"
    [matMenuTriggerData]="{ id: file.id }"
    aria-label="Row Options"
    class="semantic-button"
    [hidden]="!statusIndicator.clicksAllowed"
  >
    <span class="screenreader">Row Options</span>
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu #rowOptionsMenu xPosition="before">
  <ng-template matMenuContent let-id="id">
    <a
      mat-menu-item
      [href]="file.viewHref"
      target="_blank"
      rel="noopener"
      aria-label="open file"
      class="semantic-button"
      [class.disabled]="!statusIndicator.clicksAllowed"
      matTooltipPosition="above"
      aria-label="Preview"
    >
      <mat-icon>visibility</mat-icon> Preview
    </a>
    <a
      mat-menu-item
      [href]="file.downloadHref"
      aria-label="download file"
      class="semantic-button"
      [class.disabled]="!statusIndicator.clicksAllowed"
      matTooltipPosition="above"
      aria-label="Download"
    >
      <mat-icon>download</mat-icon> Download
    </a>
    <button
      mat-menu-item
      [matMenuTriggerFor]="confirmDelete"
      [matMenuTriggerData]="{ id: id }"
    >
      <mat-icon>delete</mat-icon> Delete
    </button>
  </ng-template>
</mat-menu>

<mat-menu #confirmDelete>
  <ng-template matMenuContent let-id="id">
    <button mat-menu-item (click)="confirmDeleteClick()">
      <mat-icon style="color: red">delete</mat-icon>
      Confirm Delete
    </button>
  </ng-template>
</mat-menu>
