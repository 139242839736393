<div class="form-title">
  {{ (canUpdate$ | async) ? "Update" : "Add" }} Contact
</div>
<div class="wrapper">
  <form id="contact-form-government-phone" class="phone-form-group">
    <ciao-form-field
      label="Government Cell Phone"
      [formControlInput]="formGroup.get('governmentPhone.phoneNumber')"
      [toolTip]="infoText.GovernmentDevice"
      type="phoneNumber"
    ></ciao-form-field>
    <legend class="notifications-label">Select Text Notifications</legend>
    <ng-container *ngFor="let option of textNotificationOptions">
      <ciao-form-field
        [label]="option.label"
        [formControlInput]="
          formGroup.get(['governmentPhone', 'smsNotifications', option.key])
        "
        type="checkbox"
      ></ciao-form-field>
    </ng-container>
  </form>
  <form id="contact-form-personal-phone" class="phone-form-group">
    <ciao-form-field
      label="Personal Cell Phone"
      [formControlInput]="formGroup.get('personalPhone.phoneNumber')"
      [toolTip]="infoText.Personal"
      type="phoneNumber"
    ></ciao-form-field>
    <legend class="notifications-label">Select Text Notifications</legend>
    <ng-container *ngFor="let option of textNotificationOptions">
      <ciao-form-field
        [label]="option.label"
        [formControlInput]="
          formGroup.get(['personalPhone', 'smsNotifications', option.key])
        "
        type="checkbox"
      ></ciao-form-field>
    </ng-container>
  </form>
  <form id="contact-form-other">
    <ciao-form-field
      label="Default Phone"
      [formControlInput]="formGroup.get('defaultPhone')"
      [toolTip]="infoText.defaultPhone"
      [selectOptions$]="defaultPhoneSelectOptions$"
      type="dropdown"
    ></ciao-form-field>
    <ciao-form-field
      label="Default Supervisor"
      placeholder="Supervisor/Admin/Dispatcher"
      toolTipTitle="Default Supervisor/Admin/Dispatcher"
      [formControlInput]="formGroup.get('defaultSupervisorId')"
      [toolTip]="infoText.defaultSupervisor"
      type="combobox"
      [selectOptions$]="defaultSupervisorSelectOptions$"
    ></ciao-form-field>
  </form>
</div>
