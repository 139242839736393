import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { Observable, Subscription, merge, of, timer } from 'rxjs';
import { filter, map, mapTo, share, switchMap, tap } from 'rxjs/operators';
import { AuthService, TextService } from '~app/services';

/** 5 minutes in milliseconds */
const FIVE_MINUTES_MS = 5 * 60 * 1000;

@Component({
  selector: 'ciao-timeout-signout',
  templateUrl: './timeout-signout.component.html',
  styleUrls: ['./timeout-signout.component.less'],
})
export class TimeoutSignoutComponent {
  @ViewChild('DialogTemplate') DialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<TemplateRef<any>>;

  /**
   * When expiry is more than five minutes from now, last emission is "close".
   * When expiry is less than five minutes from now, last emission is "open".
   * When expiry is in past, last emission is "signedOut".
   */
  currentState$: Observable<'signedOut' | 'open' | 'close'> =
    this.authService.sessionExpiry$.pipe(
      filter((x) => !!x),
      switchMap((signOutAt) => {
        const openAt = new Date(signOutAt.valueOf() - FIVE_MINUTES_MS);
        const signOut$ = timer(signOutAt).pipe(mapTo('signedOut' as const));
        const open$ = timer(openAt).pipe(mapTo('open' as const));
        const close$ = of('close' as const);
        return merge(signOut$, open$, close$);
      }),
      share()
    );
  alreadySignedOut$ = this.currentState$.pipe(
    map((state) => state === 'signedOut')
  );
  timeUntilSignOut$ = this.authService.sessionExpiry$.pipe(
    filter((date) => !!date),
    switchMap((date) => timer(0, 1000).pipe(mapTo(date))),
    map((date) => date.valueOf() - new Date().valueOf()),
    map(this.textService.formatShortDurationMinutesSeconds)
  );

  subscriptions = new Subscription();

  constructor(
    public authService: AuthService,
    public dialogService: MatDialog,
    public textService: TextService
  ) {}

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.currentState$
        .pipe(
          tap((state) => {
            if (state === 'close') {
              this.closePopup();
            } else {
              this.openPopup();
            }
          })
        )
        .subscribe()
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openPopup() {
    if (this.dialogRef?.getState() === MatDialogState.OPEN) {
      return;
    }
    this.dialogRef = this.dialogService.open(this.DialogTemplate, {
      panelClass: 'ciao-mat-popup-panel',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: 'dialog',
    });
  }
  closePopup() {
    this.dialogRef?.close();
    this.dialogRef = null;
  }

  ActionStaySignedIn() {
    this.authService.refreshSession$().subscribe();
  }
  ActionSignOut() {
    this.authService.logout();
  }
  ActionSignIn() {
    this.authService.loadCurrentUser();
  }
}
