import { UntypedFormControl } from '@angular/forms';

export class FormsUtility {
  static isIntegerOrNull(value: string | number) {
    if (!value) {
      return true;
    }
    if (isNaN(value as number)) {
      return false;
    }
    if (parseInt(value as string).toString() === value.toString()) {
      return true;
    }
    return false;
  }

  static isIntegerValidator(control: UntypedFormControl) {
    if (FormsUtility.isIntegerOrNull(control.value)) {
      return null;
    } else {
      return { notInteger: `Number must be an integer.` };
    }
  }

  static isOverCharLimit(control: UntypedFormControl) {
    // Limit 255 on backend
    // Using 250 for cosmetic reasons

    let fieldLength = control.value?.length;
    if (fieldLength > 250) {
      return {
        charLimitExceeded: `Maximum characters reached (Max 250 characters actual ${fieldLength})`,
      };
    }
  }
}
