import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import { LoadingDelayPopupComponent } from './loading-delay-popup.component';

const SessionStorageKey =
  'Dismissed_Getting_Started_Error_Page_Loading_Delay_Popup';

@Injectable({
  providedIn: 'root',
})
export class LoadingDelayPopupService {
  private dialogRef: MatDialogRef<LoadingDelayPopupComponent>;
  constructor(private dialogService: MatDialog) {}

  openPopup() {
    console.log('Opening Popup');

    this.dialogRef = this.dialogService.open(LoadingDelayPopupComponent, {
      panelClass: 'ciao-mat-popup-panel',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: 'dialog',
    });
  }

  closePopup() {
    if (this.dialogRef?.getState() === MatDialogState.OPEN) {
      this.dialogRef.close();
    }
  }
}
