import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ciao-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
  host: { class: 'loading-panel' },
})
export class LoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
