import { Component, OnInit } from '@angular/core';
import { AuthService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';

@Component({
  selector: 'ciao-user-groups-page',
  templateUrl: './user-group-page.component.html',
  styleUrls: ['./user-group-page.component.less'],
})
export class UserGroupPageComponent implements OnInit {
  constructor(private userGroupService: UserGroupService) {}

  ngOnInit(): void {
    this.userGroupService.loadAllUserGroups();
  }
}
