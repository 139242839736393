import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { mainFaqMap } from './faq-data-map';
import { ActivatedRoute } from '@angular/router';
import { delay, map, tap } from 'rxjs/operators';

@Component({
  selector: 'ciao-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.less'],
})
export class FaqsComponent implements AfterViewInit {
  @ViewChildren('qaSet', { read: ElementRef })
  qaSets: QueryList<ElementRef<HTMLDivElement>>;
  faqTitleActive = false;
  mainFaqMap = mainFaqMap;
  selected: string[] = [];

  constructor(private route: ActivatedRoute, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.route.params
      .pipe(
        map((params) => (params.slugString as string).split('/')[1].split('&')),
        tap((slugs) => console.log(slugs)),
        tap((slugs) => (this.selected = slugs)),
        tap(() => this.cdr.detectChanges()),
        delay(0),
        tap(() => {
          let selectedEls = this.qaSets
            .map((el) => el.nativeElement)
            .filter((el) =>
              this.selected.includes(el?.id?.replace('question-answer-', ''))
            );
          selectedEls.forEach((el) =>
            el.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          );
        })
      )
      .subscribe();
  }

  openOrClose(slug: string) {
    if (this.selected.includes(slug)) {
      this.selected = this.selected.filter((item) => item !== slug);
    } else {
      this.selected.push(slug);
    }
  }
}
