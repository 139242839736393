import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '~app/components/shared/material.module';

import { CiaoDatePipe } from '~app/utilities/ciao-date.pipe';
import { FilesizePipe } from '~app/utilities/filesize.pipe';

import { CiaoModalComponent } from './ciao-modal/ciao-modal.component';
import { ToolTipComponent } from './tool-tip/tool-tip.component';
import { LoadingComponent } from '../loading/loading.component';
import { NewIconComponent } from './new-icon.component';

const internalComponents = [];
const sharedComponents = [
  CiaoModalComponent,
  ToolTipComponent,
  LoadingComponent,
  NewIconComponent,
];
const importExports = [
  MaterialModule,
  FontAwesomeModule,
  CiaoDatePipe,
  FilesizePipe,
];

@NgModule({
  declarations: [...internalComponents, ...sharedComponents],
  imports: [CommonModule, ...importExports],
  exports: [...sharedComponents, ...importExports],
})
export class CiaoSharedModule {}

/**
 * @see https://www.angularjswiki.com/angular/how-to-use-font-awesome-icons-in-angular-applications/#using-font-awesome-icons-in-angular-applications
 * for the original instructions and usage examples.  The install has changed since then.
 * @see http://localhost:4200/dev/styleGuide for working examples
 */
