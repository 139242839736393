<h1>Forms</h1>
<div class="form">
  <h3>Miscellaneous Types</h3>
  <ciao-form-field
    label="Type = Checkbox"
    type="checkbox"
    [formControlInput]="miscellaneousControls.get('checkbox')"
  ></ciao-form-field>
  <ciao-form-field
    label="Type = Input"
    type="input"
    [formControlInput]="miscellaneousControls.get('input')"
  ></ciao-form-field>
  <ciao-form-field
    class="half-width-input"
    label="Type = Input 50% Width"
    type="input"
    [formControlInput]="miscellaneousControls.get('input')"
  ></ciao-form-field>
  <div class="side-by-side-inputs">
    <ciao-form-field
      class="half-width-input"
      label="Type = Inputs in a Div"
      type="input"
      [formControlInput]="miscellaneousControls.get('input')"
    ></ciao-form-field>
    <ciao-form-field
      class="half-width-input"
      label="Type = Inputs in a Div"
      type="input"
      [formControlInput]="miscellaneousControls.get('input')"
    ></ciao-form-field>
  </div>
  <div class="row">
    <ciao-form-field
      class="col-8"
      label="LAT/LONG or GPS Coordinates"
      type="latlong"
      [formControlInput]="myFormGroup.get('latLong')"
    ></ciao-form-field>
    <div class="col-8">
      <button
        (click)="myFormGroup.get('latLong')?.setValue(null)"
        class="btn-action-light"
      >
        Update Value Null
      </button>
      <button
        (click)="
          myFormGroup
            .get('latLong')
            ?.setValue({ lattitude: 30.124, longitude: -35.239 })
        "
        class="btn-action-light"
      >
        Update Value Good
      </button>
      <button
        (click)="
          myFormGroup
            .get('latLong')
            ?.setValue({ lattitude: 30.124, longitude: -35.2 })
        "
        class="btn-action-light"
      >
        Update Value Bad 1
      </button>
      <button
        (click)="
          myFormGroup
            .get('latLong')
            ?.setValue({ lattitude: 30.124, longitude: 35.239 })
        "
        class="btn-action-light"
      >
        Update Value Bad 2
      </button>
      <button
        (click)="myFormGroup.get('latLong')?.setValue({ lattitude: 30.124 })"
        class="btn-action-light"
      >
        Update Value Bad 2
      </button>
      <button
        (click)="myFormGroup.get('latLong')?.setValue('Hello World')"
        class="btn-action-light"
      >
        Update Value Bad 3
      </button>
    </div>
  </div>
  <ciao-form-field
    label="Type = Date"
    type="date"
    [formControlInput]="miscellaneousControls.get('date')"
  ></ciao-form-field>
  <ciao-form-field
    label="Type = DateRange"
    type="dateRange"
    [formControlInput]="miscellaneousControls.get('dateRange')"
  ></ciao-form-field>
  <ciao-form-field
    label="Type = DateTime"
    type="datetime"
    [formControlInput]="miscellaneousControls.get('dateTime')"
  ></ciao-form-field>
  <!-- <label>Manually redoing work</label>
  <mat-form-field>
    <input
      matInput
      class="imSpecial"
      type="datetime-local"
      [formControl]="miscellaneousControls.get('dateTimeNative')"
  /></mat-form-field> -->
  <pre><code>Miscellaneous Types Data: {{ miscellaneousControls.value | json}}</code></pre>
  <br />
  <ciao-form-field label="Does Not Grow" type="input"></ciao-form-field>
  <ciao-form-field label="Some Input" type="input"></ciao-form-field>
  <h3>Drop Downs</h3>
  <ciao-form-field
    [formControlInput]="dropdownControls.get('A')"
    label="(A)Type = Dropdown"
    type="dropdown"
    [selectOptions$]="dropdownOptionSets.simpleLowerCase$"
    width="30%"
  ></ciao-form-field>
  <ciao-form-field
    [formControlInput]="dropdownControls.get('F')"
    label="(F)Type = Multiselect"
    type="multiselect"
    [selectOptions$]="dropdownOptionSets.region_roles$"
    [compareWith]="RegionRoleCompare"
    [customTrigger]="RegionRoleCustomTrigger"
    width="50%"
  ></ciao-form-field>
  <ciao-form-field
    disabled
    [formControlInput]="dropdownControls.get('B')"
    label="(B)Type = Dropdown, People ...  Never mind, bad idea ..."
    type="dropdown"
    subtype="NOPEpeople"
  ></ciao-form-field>
  <ciao-form-field
    [formControlInput]="dropdownControls.get('C')"
    label="(C)Type = Dropdown, People, Autocomplete"
    type="combobox"
    subtype="people"
  ></ciao-form-field>
  <ciao-form-field
    [formControlInput]="dropdownControls.get('D')"
    label="(D)Type = Dropdown, Autocomplete"
    type="combobox"
    [selectOptions$]="dropdownOptionSets.simpleLowerCase$"
  ></ciao-form-field>
  <!-- <ciao-form-field
    [formControlInput]="dropdownControls.get('E')"
    label="(E)Type = Input, Autocomplete"
    type="input"
    [selectOptions$]="autocompleteOptionSets.simpleLowerCase$"
    autocomplete="true"
  ></ciao-form-field> -->
  <br />
  <button (click)="FillDropdownsWithBadData()">Fill Bad Data</button>
  <button (click)="FillDropdownsWithGoodData()">Fill Good Data</button>
  <pre><code>{{ dropdownResults }}</code></pre>
</div>

<div>
  <h3>Responsive</h3>
  Wraps onto next line, because responsive design
  <br />
  <ciao-form-field
    label="Control A"
    [formControlInput]="myFormGroup.get('secondGroup.controlA')"
    type="checkbox"
  ></ciao-form-field>
  <ciao-form-field
    label="Control B"
    [formControlInput]="myFormGroup.get('secondGroup.controlB')"
    type="input"
    width="90%"
  ></ciao-form-field>
  <ciao-form-field
    label="Control C"
    [formControlInput]="myFormGroup.get('secondGroup.controlC')"
    type="toggle"
  ></ciao-form-field>
  <ciao-form-field
    label="Control D"
    [formControlInput]="myFormGroup.get('secondGroup.controlD')"
    type="dropdown"
    [selectOptions$]="dropdownOptionSets.simpleLowerCase$"
    width="600px"
  ></ciao-form-field>
  <ciao-form-field
    label="Control E"
    [formControlInput]="myFormGroup.get('secondGroup.controlE')"
    type="checkbox"
  ></ciao-form-field>
  <ciao-form-field
    label="Control F"
    [formControlInput]="myFormGroup.get('secondGroup.controlF')"
    type="toggle"
  ></ciao-form-field>
  <ciao-form-field
    label="Control G"
    [formControlInput]="myFormGroup.get('secondGroup.controlG')"
    type="input"
  ></ciao-form-field>
  <ciao-form-field
    label="Control H"
    [formControlInput]="myFormGroup.get('secondGroup.controlH')"
    type="dropdown"
    [selectOptions$]="dropdownOptionSets.simpleUpperCase$"
  ></ciao-form-field>
  <ciao-form-field
    label="Control I"
    [formControlInput]="myFormGroup.get('controlI')"
    type="combobox"
    subtype="people"
  ></ciao-form-field>
</div>
<div class="half-width">
  <h3>Form Values using 'this.myFormGroup.value | json'</h3>
  <pre>data: {{ this.myFormGroup.value | json }}</pre>
</div>
<div class="half-width">
  <h3>Form Values Set in FormGroup.valueChanges.subscribe</h3>
  <pre><code>{{ formDebug2 }}</code></pre>
</div>
