import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { UserAttributes } from '~app/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  _needsUpdate$ = new Subject<void>();
  _foundUser$ = new Subject<void>();

  /** pseudo-private variable.  Only to be changed by AuthService */
  _currentUser$ = new BehaviorSubject<UserAttributes>(null);
  currentUser$ = this._currentUser$.asObservable();

  /** pseudo-private variable.  Only to be changed by AuthService */
  _loginUrl$ = new BehaviorSubject<string>(null);
  loginUrl$ = this._loginUrl$.asObservable();

  /** pseudo-private variable.  Only to be changed by AuthService */
  // We technically could just return the oAuth email here but returning whole object for now
  _oAuthInfo$ = new BehaviorSubject<whoamiResponse['oAuthInfo']>(null);
  oAuthInfo$ = this._oAuthInfo$.asObservable();

  _sessionExpiry$ = new BehaviorSubject<Date>(null);
  sessionExpiry$ = this._sessionExpiry$.asObservable();

  constructor() {}
}

export type whoamiResponse = {
  sessionId: string;
  sessionExpiry: string;
  registered: boolean;
  authenticated: boolean;
  user?: UserAttributes;
  oAuthInfo?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  issuer: string;
  redirectUri?: string;
  error?: string;
};
