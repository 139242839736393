import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
  share,
  shareReplay,
  startWith,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import * as _ from 'lodash-es';

import { UserGroupAttributes } from '~app/models/user-group';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';

import { UserGroupService } from '~app/services/user-group.service';
import { AuthService } from '~app/services';
import { UserGroupFormComponent } from '../user-group-form/user-group-form.component';
import { PersonAttributes } from '~app/models';
import { RolePermissionService } from '~app/services/role-permission.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SortingCriteria } from '~app/components/report-table/types';
import { AppliedPermission } from '~app/models/applied-permission';
import { PaginationResult } from '~app/models/pagination-data';
import { SortByCriteria } from '~app/components/report-table/client-side-pagination.functions';
import { BooleanInput } from '@angular/cdk/coercion';
import { ToastrService } from 'ngx-toastr';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';

type GroupOptions = {
  label: string;
  id: UserGroupAttributes['id'];
  parentId: UserGroupAttributes['parentId'];
  userGroupObject: UserGroupAttributes;
  children?: GroupOptions[];
};

@Component({
  selector: 'ciao-user-group-table',
  templateUrl: './user-group-table.component.html',
  styleUrls: ['./user-group-table.component.less'],
})
export class UserGroupTableComponent implements OnInit, OnDestroy {
  @Input() allowFocusChange: BooleanInput;
  @ViewChild('actionUserGroupModal') actionUserGroupModal: CiaoModalComponent;
  @ViewChild('actionUserGroupForm') actionUserGroupForm: UserGroupFormComponent;
  @ViewChild('deleteUserGroupModal') deleteUserGroupModal: CiaoModalComponent;
  @ViewChild('viewTeamModal') viewTeamModal: CiaoModalComponent;

  //lone control to filter report table
  searchTextControl = new UntypedFormControl('');

  continuousFilterGroup = new UntypedFormGroup({
    searchText: this.searchTextControl,
  });

  filterModalControls = {
    myUserGroups: new UntypedFormControl(),

    /** filled in dynamically */
    regions: new UntypedFormGroup({}),
    /** filled in dynamically */
    forests: new UntypedFormGroup({}),
    /** The set of checkboxes that control "All" the other checkboxes in their group */
    allChecks: new UntypedFormGroup({}),
  };
  filterModalGroup: UntypedFormGroup;
  filterModalInitialized = false;
  readonly filterRegionList$ = this.initializeFilterModalRegionOptions();
  readonly applyFilters$ = new BehaviorSubject<void>(null);

  myUserGroups$ = this.userGroupService.searchMyUserGroups([
    'team_member',
    'supervisor',
    'region_admin',
  ]);
  /** For reseting the filters, ie: when you close the modal without applying */
  previousFilterData: any;

  readonly filterData$ = combineLatest([
    this.continuousFilterGroup.valueChanges.pipe(
      startWith(this.continuousFilterGroup.value),
      debounceTime(500),
      distinctUntilChanged((x, y) => _.isEqual(x, y))
    ),
    this.applyFilters$,
  ]).pipe(switchMapTo(this.getFilterDataObservableLogic()));

  selectedData: UserGroupAttributes & {
    supervisors?: PersonAttributes[];
    teamMembers?: PersonAttributes[];
  };
  selectedTeamPermissions$ = of({
    edit: false,
    remove: false,
    assignTeamMember: false,
    assignSupervisor: false,
    viewTeamMembers: false,
    /** This is a combination of edit or assign team member or assign supervisor */
    clickUpdate: false,
  });
  canCreateTeam$: Observable<boolean> = of(false);
  currentUser$ = this.authService.currentUser$;

  isEditForm: boolean = true;
  modalTitle$: Observable<string> = of('View Team');

  sortingBy$: BehaviorSubject<SortingCriteria[]> = new BehaviorSubject([
    { sortId: 'regionStation.sortOrder', order: 'ASC' },

    { sortId: 'forestLab.sortOrder', order: 'ASC' },

    { sortId: 'label', order: 'ASC' },
  ]);
  pagingInfo$ = new BehaviorSubject({ limit: 0, offset: 0 });

  get paginationMessage() {
    return this.searchTextControl.value
      ? `matching '${this.searchTextControl.value}'`
      : '';
  }

  //#region ClientSideSorting
  // Todo: Replace with ServerSide ?
  sourceData$: Observable<(UserGroupAttributes & { compareString: string })[]> =
    this.userGroupService.allTeams$.pipe(
      map((groups) =>
        groups.map((group) => {
          group.compareString = [
            group.label,
            group.forestLab?.label,
            group.regionStation?.label,
            group.abbreviation,
            group.forestLab?.abbreviation,
            group.regionStation?.abbreviation,
            group.pointOfContact,
            group.contactEmail,
          ]
            .join(' ')
            .toLowerCase();
          return group as UserGroupAttributes & { compareString: string };
        })
      ),
      map((groups) => groups.slice()),
      share()
    );

  paginationResult$: Observable<PaginationResult<UserGroupAttributes>> =
    combineLatest([
      this.sourceData$,
      this.filterData$,
      this.myUserGroups$,
      this.sortingBy$,
      this.pagingInfo$.pipe(distinctUntilChanged((a, b) => _.isEqual(a, b))),
    ]).pipe(
      map(([data, filterData, myUserGroups, sortBy, pagingInfo]) => {
        let searchTerms = filterData.filters.searchText
          .toLowerCase()
          .split(' ') as string[];

        let resultData = data.filter((row) =>
          searchTerms.every((term) => row.compareString.includes(term))
        );

        if (filterData.filters.userGroups.length > 0) {
          resultData = resultData.filter((row) =>
            filterData.filters.userGroups.includes(row.id)
          ); // Filter user groups that are ins ide the selected teams
        }

        // This was a bit pre mature but the logic is here for when the issue is ready to be worked on #1229
        // if (filterData.filters.myUserGroups) {
        //   resultData = resultData.filter((row) => {
        //     return myUserGroups.every((team) => team.id === row.id);
        //   });
        // }

        SortByCriteria(resultData, sortBy);
        let pagedData = resultData.slice(
          pagingInfo.offset,
          pagingInfo.offset + pagingInfo.limit
        );
        if (filterData.errors.noUserGroupsMatch) {
          return {
            totalRowCount: 0,
            rowCount: 0,
            rows: [],
            isLoading: false,
          };
        }
        return {
          rows: pagedData,
          rowCount: pagedData.length,
          totalRowCount: resultData.length,
        };
      }),
      share()
    );
  //#endregion

  private allSubscriptions: Subscription = new Subscription();

  // font awesome
  filter = faFilter;

  constructor(
    private userGroupService: UserGroupService,
    private authService: AuthService,
    private rolePermissionService: RolePermissionService,
    private toastrService: ToastrService
  ) {
    this.allSubscriptions.add(this.filterRegionList$.pipe(take(1)).subscribe());
  }

  ngOnInit(): void {
    this.canCreateTeam$ = this.getCanCreateTeam$();
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  openActionUserGroupModal(userGroup, action) {
    this.selectedData = userGroup;
    if (action == 'Add') {
      this.isEditForm = false;
      this.modalTitle$ = of('Add Team');
      this.canCreateTeam$ = this.getCanCreateTeam$();
    } else {
      this.isEditForm = true;
      this.selectedTeamPermissions$ = this.getTeamEditPermissions(userGroup);
      this.modalTitle$ = this.selectedTeamPermissions$.pipe(
        map(
          (perms) =>
            perms.edit || perms.assignSupervisor || perms.assignTeamMember
        ),
        map((canEdit) => (canEdit ? 'Edit Team' : 'View Team')),
        startWith('Loading')
      );
    }
    let modalRef = this.actionUserGroupModal.openModal();
    this.actionUserGroupForm.refreshUserGroup(userGroup.id);
    modalRef.result.then(
      (message) => {
        this.selectedData = null;
      },
      (message) => {
        this.selectedData = null;
      }
    );
  }

  openDeleteUserGroupModal(userGroup) {
    this.deleteUserGroupModal.openModal();
    this.selectedData = userGroup;
  }

  getTeamEditPermissions(userGroup: UserGroupAttributes) {
    return this.rolePermissionService
      .searchMyAppliedPermissions({
        permissionIds: [
          'edit_usergroup',
          'delete_usergroup',
          'assign_team_member',
          'assign_supervisor',
          'view_team_members',
        ],
        filterWithinUserGroups: [userGroup],
      })
      .pipe(
        startWith([] as AppliedPermission[]),
        map((perms) => perms.map((perm) => perm.AppliedPermissionId)),
        map((permIds) => ({
          edit: permIds.indexOf('edit_usergroup') !== -1,
          remove: permIds.indexOf('delete_usergroup') !== -1,
          assignTeamMember: permIds.indexOf('assign_team_member') !== -1,
          assignSupervisor: permIds.indexOf('assign_supervisor') !== -1,
          viewTeamMembers: permIds.indexOf('view_team_members') !== -1,
        })),
        map(
          ({
            edit,
            remove,
            assignTeamMember,
            assignSupervisor,
            viewTeamMembers,
          }) => ({
            edit,
            remove,
            assignTeamMember,
            assignSupervisor,
            viewTeamMembers,
            clickUpdate: edit || assignSupervisor || assignTeamMember,
          })
        ),
        shareReplay(1)
      );
  }

  getCanCreateTeam$() {
    return this.rolePermissionService
      .searchMyAppliedPermissions({
        permissionIds: ['create_child_usergroup'],
      })
      .pipe(
        map((perms) => perms.length > 0),
        share()
      );
  }

  saveData() {
    this.actionUserGroupForm
      .saveFormData()
      .pipe(
        tap(() => {
          this.isEditForm
            ? this.toastrService.success(
                'Team was updated successfully.',
                'Success'
              )
            : this.toastrService.success(
                'Team was added successfully',
                'Success'
              );
        }),
        catchError((err, caught) => {
          console.error(err);
          if (this.isEditForm) {
            this.toastrService.error(
              `Team was not updated successfully. Error: ${err}`,
              'Error'
            );
          } else {
            this.toastrService.error(
              `Team was not saved successfully. Error: ${err}`,
              'Error'
            );
          }
          return of(null);
        }),

        tap(() => this.actionUserGroupModal.close('Team sucessfully edited'))
      )
      .subscribe();
  }

  deleteData() {
    this.actionUserGroupForm
      .deleteData()
      .pipe(
        tap(() => {
          return this.toastrService.success(
            'Team was deleted successfully.',
            'Success'
          );
        }),
        catchError((err) => {
          console.error(err);
          return of(
            this.toastrService.error(
              `Team was not deleted successfully. Error: ${err}`,
              'Error'
            )
          );
        }),
        tap(() => this.deleteUserGroupModal.close('Team succesfully deleted')),
        tap(() => this.actionUserGroupModal.close('Team succesfully deleted'))
      )
      .subscribe();
  }
  clearFilters() {
    this.filterModalGroup.reset();
    this.applyFilters$.next();
    this.previousFilterData = this.filterModalGroup.value;
  }
  applyFilters() {
    this.previousFilterData = this.filterModalGroup.value;
    this.applyFilters$.next();
    this.filterModalGroup.markAsPristine();
  }
  resetFilters() {
    this.filterModalGroup.setValue(this.previousFilterData);
  }

  initializeFilterModalRegionOptions() {
    this.filterModalInitialized = false;
    return combineLatest([
      this.userGroupService.systemUserGroup$,
      this.userGroupService.allRegions$,
      this.userGroupService.allForestLabs$,
      this.userGroupService.allTeams$,
    ]).pipe(
      take(1),
      map(([system, regions, forests, teams]) => ({
        system,
        regions,
        forests,
        teams,
      })),
      map(({ system, regions, forests, teams }) => {
        let regionOptions = regions.map((region) => {
          let forestOptions = region.children
            .filter((forest) => forest.tierLabel === 'ForestLab')
            .map((forest) => {
              return {
                label: forest.labelPrefix + forest.label,
                id: forest.id,
                parentId: forest.parentId,
                userGroupObject: forest,
              };
            });
          return {
            label: region.labelPrefix + region.label,
            id: region.id,
            parentId: region.parentId,
            children: forestOptions,
            userGroupObject: region,
          };
        });

        return regionOptions as GroupOptions[];
      }),
      tap((regionOptions) => {
        this.filterModalControls.regions = new UntypedFormGroup({});
        this.filterModalControls.forests = new UntypedFormGroup({});
        this.filterModalControls.allChecks = new UntypedFormGroup({});
        this.filterModalControls.allChecks.addControl(
          'regions',
          new UntypedFormControl()
        );
        regionOptions.forEach((region) => {
          this.filterModalControls.regions.addControl(
            region.id,
            new UntypedFormControl()
          );
          this.filterModalControls.allChecks.addControl(
            region.id,
            new UntypedFormControl()
          );
          region.children.forEach((forest) => {
            this.filterModalControls.forests.addControl(
              forest.id,
              new UntypedFormControl()
            );
          });
        });
        this.filterModalGroup = new UntypedFormGroup(this.filterModalControls);
        this.clearFilters();
      }),
      tap((regionOptions) => {
        this.initializeFilterModalBehaviors(regionOptions);
      }),
      tap(() => {
        this.filterModalInitialized = true;
      }),
      shareReplay(1)
    );
  }

  initializeFilterModalBehaviors(regionOptions: GroupOptions[]) {
    // Checking the regions - "All" checkbox should check all the regions.
    // Checking one of the forests - "All" checkbox should check all forests in that region.
    // Unchecking regions "All" when it is checked should uncheck all regions.
    // Unchecking one of the forests - "All" boxes should uncheck all forests in that region.
    // Unchecking one of the regions should uncheck the regions - "All" checkbox
    // Unchecking one of the forests should uncheck the "All" checkbox for that region.
    // Manually checking the last region  should automatically check or uncheck the Regions - "All" checkbox.
    // Manually checking the forest in a group should automatically check or uncheck the All Forests checkbox for that region.

    // Tried to use `{emitEvent: false}`, but that didn't work. Because we can't compare with unemitted events.
    // Instead, using skipChanges and skipping changes that occur while skipChanges is true.

    const forestOptions = regionOptions.map((region) => region.children).flat();
    const groupOptionsById = Object.fromEntries(
      [...regionOptions, ...forestOptions].map((option) => [option.id, option])
    ) as { [id: string]: GroupOptions };

    let skipChanges = false;

    // Make "All" checkboxes check and uncheck boxes in their group
    this.allSubscriptions.add(
      this.filterModalControls.allChecks.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let groupId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return { groupId, value: newValues[groupId] };
          }),
          filter(({ groupId, value }) => !!groupId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          map(({ groupId, value }) => {
            let list = regionOptions;
            let control = this.filterModalControls.regions;
            if (groupId !== 'regions') {
              list = groupOptionsById[groupId].children;
              control = this.filterModalControls.forests;
            }

            skipChanges = true;
            control.patchValue(
              Object.fromEntries(list.map((group) => [group.id, value]))
              // { emitEvent: false }
            );
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // Check and Uncheck
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          map((regions) => Object.entries(regions).every((entry) => entry[1])),
          filter((_) => !skipChanges),
          tap((allRegionsChecked) => {
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              regions: allRegionsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
    this.allSubscriptions.add(
      this.filterModalControls.forests.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            let forestId = Object.keys(newValues).find(
              (k) => newValues[k] !== oldValues[k]
            );
            return forestId;
          }),
          filter((forestId) => !!forestId),
          // End Find Changed Key
          filter((_) => !skipChanges),
          tap((forestId) => {
            // Find region that this forest is in.
            // Check all forests in that region to see if they're checked or not.
            const forestData = this.filterModalControls.forests.value;
            const regionId = groupOptionsById[forestId].parentId;
            const forestIds = groupOptionsById[regionId].children.map(
              (forest) => forest.id
            );
            const allForestsChecked = forestIds.every(
              (forestId) => forestData[forestId]
            );
            skipChanges = true;
            this.filterModalControls.allChecks.patchValue({
              [regionId]: allForestsChecked,
            });
            skipChanges = false;
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );

    // If a region is unchecked, then mark all children as false.
    // Do this regardless of whether it's in response to one of the other automated behaviors
    this.allSubscriptions.add(
      this.filterModalControls.regions.valueChanges
        .pipe(
          // Find Changed Key out of all value.
          //   (the alternative is adding a subscription to each and every control, which I'm not about.)
          startWith(this.filterModalControls.allChecks.value),
          pairwise(),
          map(([oldValues, newValues]) => {
            return Object.keys(newValues)
              .filter((k) => newValues[k] !== oldValues[k])
              .map((regionId) => ({ regionId, value: newValues[regionId] }));
          }),
          mergeMap((regions, index) => of(...regions)),
          // regionId is truthy and value is falsey
          filter(({ regionId, value }) => !!regionId && !value),
          // End Find Changed Key
          tap(({ regionId, value }) => {
            let patchValue = Object.fromEntries(
              groupOptionsById[regionId].children.map((child) => [
                child.id,
                false,
              ])
            );
            this.filterModalControls.allChecks.patchValue({
              [regionId]: false,
            });
            this.filterModalControls.forests.patchValue(patchValue);
          }),
          catchError((err, caught) => {
            console.error(err);
            skipChanges = false;
            return caught;
          })
        )
        .subscribe()
    );
  }

  getFilterDataObservableLogic() {
    return this.userGroupService.userGroupsByTier$.pipe(
      map((allGroups) => {
        const modalData: {
          myUserGroups: boolean;
          regions: { [key: string]: true };
          forests: { [key: string]: true };
        } = this.filterModalGroup.value;

        const filterData = {
          filters: {
            myUserGroups: this.filterModalControls.myUserGroups.value,
            searchText: this.searchTextControl.value,
            userGroups: [],
          },
          errors: {
            noUserGroupsMatch: false,
          },
        };
        /** Calculations that are used multiple times (or in multiple iterations of one loop) */
        const modalDataProcessed = {
          /**
           * Use this to determine if at least one forest inside a region selection is selected.
           * For the purpose of selecting all the forests in a region.
           */
          regionsPartiallySelected: Object.fromEntries(
            allGroups.regions.map((region) => [
              region.id,
              region.children.some((forest) => modalData.forests[forest.id]),
            ])
          ),
          someGroupSelectionMade:
            allGroups.regions.some((region) => modalData.regions[region.id]) ||
            allGroups.forests.some((forest) => modalData.forests[forest.id]),
        };

        if (modalData) {
          if (modalDataProcessed.someGroupSelectionMade) {
            // Add UserGroups, but only if group selections were made.
            filterData.filters.userGroups.push(
              ...allGroups.teams
                .filter(
                  (team) =>
                    modalData.forests[team.forestLab?.id] ||
                    (modalData.regions[team.regionStation?.id] &&
                      !modalDataProcessed.regionsPartiallySelected[
                        team.regionStation?.id
                      ])
                )
                .map((region) => region.id)
            );
          }
        }

        if (
          filterData.filters.userGroups.length === 0 &&
          modalDataProcessed.someGroupSelectionMade
        ) {
          filterData.errors.noUserGroupsMatch = true;
        }

        return filterData;
      })
    );
  }
}
