import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function MarkedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href, title, text) => {
    let link: HTMLAnchorElement = document.createElement('a');
    // Clean Url from https://github.com/markedjs/marked/blob/master/src/helpers.ts#L66
    link.href = encodeURI(href).replace(/%25/g, '%');
    if (title) {
      link.title = title;
    }
    if (
      href.startsWith('https://') ||
      href.startsWith('http://') ||
      href.startsWith('/assets')
    ) {
      link.target = '_blank';
      link.rel = 'noopener';
      link.innerHTML =
        text +
        "<span class='icon material-icons-round mat-icon-no-color'>arrow_outward</span>";
    } else {
      link.innerHTML = text;
      link.classList.add('inserted-router-link');
    }
    const output = link.outerHTML;
    return output;
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}
