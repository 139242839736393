import { Component, OnInit } from '@angular/core';
import { ErrorService } from '~app/services';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.less'],
})
export class ErrorPageComponent implements OnInit {
  errorList: Error[];

  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.lastError.subscribe((lastErr) => {
      this.errorList = this.errorService.getErrors();
    });
  }
}
