import { AbstractControl } from '@angular/forms';

export class LatLong {
  constructor(public lattitude: number, public longitude: number) {}
}

/**
 * Understanding Precision in Lat/Long coords
 * https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude/8674#8674
 * https://xkcd.com/2170/
 *
 * In short, we should cap precision at 5 decimal places.  But most people will likely use either 3 or 4 digits
 *
 *   - 3 digits (28.123) will indicate something like a big farm field or a university campus.
 *   - 4 digits of precision (28.1234) will indicate a parcel of land, or a corner of a house.
 *   - 5 digits (28.12345) will distinguish one tree from a neighboring tree.
 *   - 6 digits (28.12346)  would distinguish one tulip from a neighboring tulip.
 *
 */
export function CheckPrecision(value: number, label: string) {
  if (Math.round(value * 1e5) / 1e5 !== value) {
    return {
      property: label.toLowerCase() + 'PrecisionTooTight',
      message:
        label +
        ' precision is too tight.  6 decimal places of precision is the distance between two tulips in a flowerbed.',
    };
  }
  if (Math.round(value * 1e1) / 1e1 === value) {
    return {
      property: label.toLowerCase() + 'PrecisionTooLoose',
      message:
        label +
        ' precision is too loose.  1 decimal place of precision is the distance between two cities.',
    };
  }
  return null;
}

export function LongitudeValidator(control: AbstractControl) {
  const val = control.value;
  if (!val) {
    return null;
  }
  let errorCount = 0;
  let errors: any = {};

  if (parseFloat(val) !== val) {
    errorCount++;
    errors.longitudeNotNumber = 'Longitude must be a number';
  } else {
    if (val < -180 || val > 180) {
      errorCount++;
      errors.longitudeOutOfRange = 'Longitude must be valid';
    }
    if (val > 0) {
      errorCount++;
      errors.longitudeEast = 'Longitude must be in Western Hemisphere';
    }
    let precisionErr = CheckPrecision(val, 'Longitude');
    if (precisionErr) {
      errorCount++;
      errors[precisionErr.property] = precisionErr.message;
    }
  }

  return errorCount > 0 ? errors : null;
}

export function LattitudeValidator(control: AbstractControl) {
  const val = control.value;
  if (!val) {
    return null;
  }
  let errorCount = 0;
  let errors: any = {};

  if (parseFloat(val) !== val) {
    errorCount++;
    errors.lattitudeNotNumber = 'Lattitude must be a number';
  } else {
    if (val < -90 || val > 90) {
      errorCount++;
      errors.lattitudeOutOfRange = 'Lattitude must be valid';
    }
    if (val < 0) {
      errorCount++;
      errors.lattitudeEast = 'Lattitude must be in Northern Hemisphere';
    }
    let precisionErr = CheckPrecision(val, 'Lattitude');
    if (precisionErr) {
      errorCount++;
      errors[precisionErr.property] = precisionErr.message;
    }
  }

  return errorCount > 0 ? errors : null;
}
