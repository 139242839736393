<div class="content">
  <h2 id="title-header-whatnot" class="title">
    DEVELOPER ADMIN | Scheduled Actions
  </h2>
  <p class="help-text">
    Note: If you're not a system admin or you're not a developer, you do not
    belong here.
    <br />
    Please report to the developers the fact that you were able to get here.
  </p>
  <div class="interactables-row">
    <span class="search-and-filter">
      <ciao-form-field
        label="Search"
        [formControlInput]="filterControls.searchText"
      ></ciao-form-field>
      <button class="btn-action-solid" (click)="filterModal.openModal()">
        <fa-icon [icon]="filter"></fa-icon> Filter
      </button>
      <button class="btn-action-light" (click)="refreshData$.next()">
        Refresh
      </button>
    </span>
    <button class="new-item-button semantic-button" (click)="checkForActions()">
      Check Actions (this occurs every 2 minutes anyways)
      <span class="icon" role="presentation">
        <img src="assets/img/icons/new-item-button-icon.svg" alt="Go" />
      </span>
    </button>
  </div>
  <ciao-page-selector
    [displayRowCount]="true"
    [tableName]="'Actions'"
    [paginationResult]="paginationResult$ | async"
  ></ciao-page-selector>
  <div class="scroll-x-on-overflow">
    <table ciao-table-style-v1 [attr.aria-describedby]="'title-header-whatnot'">
      <thead>
        <tr>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'actionType'"
          >
            Action Type
          </th>
          <th ciaoSortableHeader [sortingBy$]="sortingBy$" [sortId]="'status'">
            Status
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'scheduledFor'"
          >
            Scheduled For
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'startedAt'"
          >
            Started At
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'completedAt'"
          >
            Completed At
          </th>
          <th>Notable Options</th>
        </tr>
      </thead>
      <tbody *ngIf="paginationResult$ | async as paginationResult">
        <ng-template [ngIf]="paginationResult.isLoading">
          <tr class="messageRow">
            <td [attr.colspan]="6">
              <ciao-loading></ciao-loading>
            </td>
          </tr>
        </ng-template>
        <ng-template
          ngFor
          let-row
          [ngForOf]="paginationResult?.rows"
          let-i="index"
          [ngForTrackById]
        >
          <tr (click)="focusOnRow(row)">
            <td>
              {{ row.actionType }}
            </td>
            <td>
              <span [ngClass]="'status-' + row.status">
                {{ row.status }}
              </span>
            </td>
            <td>{{ row.scheduledFor | ciaoDate : "datetime" : "readable" }}</td>
            <td>{{ row.startedAt | ciaoDate : "datetime" : "readable" }}</td>
            <td>{{ row.completedAt | ciaoDate : "datetime" : "readable" }}</td>
            <td>
              <ng-container [ngSwitch]="row.actionType">
                <ng-container *ngSwitchCase="'LateTripFifteenMinuteWarning'">
                  Trip:
                  <a [routerLink]="['/trip/id/', row.options.tripId]">
                    {{ row.options.tripId }}
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="'LateTripMarkLate'">
                  Trip:
                  <a [routerLink]="['/trip/id/', row.options.tripId]">
                    {{ row.options.tripId }}
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="'LateTripSendMessage'">
                  Trip:
                  <a [routerLink]="['/trip/id/', row.options.tripId]">
                    {{ row.options.tripId }}
                  </a>
                </ng-container>
                <ng-container *ngSwitchCase="'LateTripCheck15minutes'">
                  Trip:
                  <a [routerLink]="['/trip/id/', row.options.tripId]">
                    {{ row.options.tripId }}
                  </a>
                </ng-container>
                <ng-container *ngSwitchDefault>Unknown</ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <ciao-page-selector
    #primaryPageSelector
    class="pageSelector"
    [tableName]="'Trips'"
    (paginationDataChange)="paginationModalControls.patchValue($event)"
    [paginationResult]="paginationResult$ | async"
    [filteredMessage]="paginationMessage"
    [pageControl]="primaryPageSelector?.pageControl"
  ></ciao-page-selector>
</div>

<ciao-modal
  #inspectionModal
  [title]="'Inspect Action'"
  (result)="focusedAction = null"
>
  <div class="modal-body" modalBody>
    <ng-container *ngIf="focusedAction; else noFocusedAction">
      ID: {{ focusedAction.id }}
      <br />
      Action Type: {{ focusedAction.actionType }}
      <br />
      Status: {{ focusedAction.status }}
      <br />
      Updated At:
      {{ focusedAction.updatedAt | ciaoDate : "datetime" : "readable" }}
      <br />
      Scheduled For:
      {{ focusedAction.scheduledFor | ciaoDate : "datetime" : "readable" }}
      <br />
      Started At:
      {{ focusedAction.startedAt | ciaoDate : "datetime" : "readable" }}
      <br />
      Completed At:
      {{ focusedAction.completedAt | ciaoDate : "datetime" : "readable" }}
      <br />
      <div style="overflow-y: auto; height: 20em">
        Options:
        <div class="well">
          <pre><code>{{ focusedAction.options | json }}</code></pre>
        </div>
        LateTripSendMessage-specific results:
        <div class="well">
          <pre><code>{{ pickOutRelevantResultsLateTripSendMessage(focusedAction) | json }}</code></pre>
        </div>
        Results:
        <div class="well">
          <pre><code>{{ focusedAction.results | json }}</code></pre>
        </div>
      </div>
    </ng-container>
    <ng-template #noFocusedAction> There is no action selected. </ng-template>
  </div>
  <div class="modal-footer" modalFooter>
    <button class="btn-action-light" (click)="scheduleForRetry(focusedAction)">
      Schedule For Retry
    </button>
    <button class="btn-action-light" (click)="cancelAction(focusedAction)">
      Cancel Action
    </button>
    <button class="btn-action-light" (click)="debugAction(focusedAction)">
      Debug Action
    </button>
    <button class="btn-action-light" (click)="refreshData$.next()">
      Refresh
    </button>
  </div>
</ciao-modal>

<ciao-modal #filterModal [title]="'Filter Settings'">
  <div class="modal-body" modalBody>
    <ciao-form-field
      label="Search"
      [formControlInput]="filterControls.searchText"
    ></ciao-form-field>
    <!-- <ciao-form-field
      label="Status"
      type="dropdown"
      [formControlInput]="filterControls.tripStatus"
      [selectOptions$]="statusFilterOptions$"
    ></ciao-form-field>
    <ciao-form-field
      class="mat-form-background-brown"
      label="Teams"
      placeholder="All Available Teams"
      type="multiselect"
      [formControlInput]="filterControls.userGroupId"
      [selectOptions$]="teamSelectionOptions$"
    ></ciao-form-field>
    <ciao-form-field
      label="Only Show Trips I'm Either On Or Supervising"
      type="toggle"
      [formControlInput]="filterControls.imNamedOnThisTrip"
    ></ciao-form-field> -->
    <br />
  </div>
  <div modalFooter>
    <div class="modal-footer-buttons">
      <button
        (click)="clearFilters(); filterModal.close('')"
        class="btn-action-light"
      >
        Clear Filters
      </button>
    </div>
  </div>
</ciao-modal>
