<ng-template #DialogTemplate>
  <ng-container
    *ngIf="!(alreadySignedOut$ | async); else alreadySignedOutTemplate"
  >
    <mat-dialog-content>
      <h2>Session Timeout Warning</h2>
      <p class="mat-body-2">
        You will be signed out in {{ timeUntilSignOut$ | async }}.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button class="btn-action-solid" (click)="ActionStaySignedIn()">
        Stay Signed In
      </button>
      <button class="btn-action-light" (click)="ActionSignOut()">
        Sign Out
      </button>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #alreadySignedOutTemplate>
    <mat-dialog-content>
      <h2>Session Timeout</h2>
      <p class="mat-body-2">You have been signed out.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button class="btn-action-solid" (click)="ActionSignIn()">
        Sign Back In
      </button>
    </mat-dialog-actions>
  </ng-template>
</ng-template>
