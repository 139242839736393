import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public readonly requestOptions = {
    withCredentials: true,
  };
  constructor(private configService: ConfigService, private http: HttpClient) {}
  get apiUrl(): string {
    return this.configService.apiUrl;
  }

  completePath(endpoint: string, id?: string) {
    let matcher = /[^a-zA-Z0-9\-\/]/g;
    let badEndpointCharacters = endpoint.match(matcher);
    if (badEndpointCharacters) {
      let characterDisplay = `'${badEndpointCharacters.join("', '")}'`;
      throw new Error(
        `Endpoint '${endpoint}' contains illegal characters ${characterDisplay}`
      );
    }
    if (id) {
      let badIdCharacters = id.match(matcher);
      if (badIdCharacters) {
        let characterDisplay = `'${badIdCharacters.join("', '")}'`;
        throw new Error(
          `Id '${id}' contains illegal characters ${characterDisplay}`
        );
      }
    }
    let url = `${this.apiUrl}/${endpoint}`;
    if (id) {
      url = `${this.apiUrl}/${endpoint}/${id}`;
    }
    // remove double forward slashes (after the first set with the colon)
    let clean_url = url.replace(/([^:]\/)\/+/g, '$1');

    return clean_url;
  }

  GetAll<T>(endpoint: string) {
    let url = this.completePath(endpoint);
    let response$ = this.http.get<T[]>(url, this.requestOptions);
    return response$;
  }
  GetFiltered<T>(endpoint: string, filters: any) {
    let url = this.completePath(endpoint);
    let response$ = this.http.get<T[]>(url, this.requestOptions);
    return response$;
  }
  GetSingle<T>(endpoint: string) {
    let url = this.completePath(endpoint);
    let response$ = this.http.get<T>(url, this.requestOptions);
    return response$;
  }
  Get<T>(endpoint: string, id: string) {
    if (!id) {
      throw new Error('ApiService.Get requires an id');
    }
    let url = this.completePath(endpoint, id);
    let response$ = this.http.get<T>(url, this.requestOptions);
    return response$;
  }
  Post<T>(endpoint: string, body: any) {
    let url = this.completePath(endpoint);
    let response$ = this.http.post<T>(url, body, this.requestOptions);
    return response$;
  }
  Put<T>(endpoint: string, id: string, body: any) {
    let url = this.completePath(endpoint, id);
    let response$ = this.http.put<T>(url, body, this.requestOptions);
    return response$;
  }
  Delete<T>(endpoint: string, id: string) {
    let url = this.completePath(endpoint, id);
    let response$ = this.http.delete<T>(url, this.requestOptions);
    return response$;
  }
  Search<T>(endpoint: string, body: any) {
    let url = this.completePath(endpoint);
    let options = Object.assign({ body }, this.requestOptions);
    let response$ = this.http.request<T>('SEARCH', url, options);
    return response$;
  }
  Custom<T>(
    method: string,
    endpoint: string,
    body: any,
    options: unknown = {}
  ) {
    let url = this.completePath(endpoint);
    Object.assign(options, { body }, this.requestOptions);
    let response$ = this.http.request<T>(method, url, options);
    return response$;
  }

  endpoint<T>(endpoint: string): Endpoint<T> {
    let service = this;
    let verifiedUrlPath = service.completePath(endpoint);
    return {
      url: verifiedUrlPath,
      GetAll() {
        return service.GetAll<T>(endpoint);
      },
      GetFiltered(filters) {
        return service.GetFiltered<T>(endpoint, filters);
      },
      GetSingle() {
        return service.GetSingle<T>(endpoint);
      },
      Get(id: string) {
        return service.Get<T>(endpoint, id);
      },
      Post(body: any) {
        return service.Post<T>(endpoint, body);
      },
      Put(id: string, body) {
        return service.Put<T>(endpoint, id, body);
      },
      Delete(id: string) {
        return service.Delete<T>(endpoint, id);
      },
    };
  }
}

export interface Endpoint<T = Object> {
  url: string;
  GetAll: () => Observable<T[]>;
  GetFiltered: (filters: any) => Observable<T[]>;
  GetSingle: () => Observable<T>;
  Get: (id: string) => Observable<T>;
  Post: (body: any) => Observable<T>;
  Put: (id: string, body: any) => Observable<T>;
  Delete: (id: string) => Observable<T>;
}
