import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JobAttributes } from '~app/models/title';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {
  public readonly allJobTitles$: Observable<JobAttributes[]> = of([
    { titleName: 'FS Employee' },
    { titleName: 'Contractor' },
    { titleName: 'Volunteer' },
    { titleName: 'Guest/Partner' },
  ]);

  constructor() {}
}
