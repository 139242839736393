import {
  Component,
  AfterViewInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { UserGroupService } from '~app/services/user-group.service';

/*
https://stackoverflow.com/questions/52311173/angular-material-create-dialog-box-in-same-component-as-existing-functionality
> Update: I was not correct in my assumptions that the TemplateRef's type
> parameter was the component reference - in fact, it's actually the
> "data-binding context of the embedded view", as shown in the
> documentation for the TemplateRef#createEmbeddedView method
*/

const SessionStorageKey = 'Dismissed_Getting_Started_Landing_Page_Popup';

@Component({
  selector: 'ciao-popup-get-started',
  templateUrl: './popup-get-started.component.html',
  styleUrls: ['./popup-get-started.component.less'],
})
/** If a User is not part of any Team, show this pop-up on the Welcome page once per session. */
export class PopupGetStartedComponent implements AfterViewInit {
  @ViewChild('DialogTemplate') DialogTemplate: TemplateRef<any>;
  dialogRef: MatDialogRef<TemplateRef<any>>;

  subscriptions = new Subscription();

  constructor(
    public userGroupService: UserGroupService,
    public dialogService: MatDialog
  ) {}

  ngAfterViewInit(): void {
    this.openPopup();
  }

  ngOnDestroy(): void {
    // page navigation -> don't dismiss for the session
    this.dialogRef?.close('left the page');
    this.subscriptions.unsubscribe();
  }

  onManualClose(): void {
    // Intentional Close -> dismiss for the rest of the session
    this.dialogRef?.close('manual close');
    sessionStorage.setItem(SessionStorageKey, 'true');
  }

  openPopup() {
    const alreadyDismissed = sessionStorage.getItem(SessionStorageKey);
    if (alreadyDismissed) {
      return;
    }
    this.subscriptions.add(
      this.userGroupService
        .searchMyUserGroups(['supervisor', 'team_member'])
        .pipe(
          take(1),
          filter((usergroups) => usergroups.length === 0),
          tap((usergroups) => {
            if (usergroups.length === 0) {
              this.dialogRef = this.dialogService.open(this.DialogTemplate, {
                panelClass: 'ciao-mat-popup-panel',
                maxWidth: '100vw',
                maxHeight: '100vh',
                disableClose: true,
                autoFocus: 'dialog',
              });

              // if there's ever another way of closing,
              // subscribe to the `dialogRef.afterClosed()` observable to handle it.
            } else {
              // Don't recheck every time the user visits the home page
              sessionStorage.setItem(SessionStorageKey, 'true');
            }
          })
        )
        .subscribe()
    );
  }
}
