<div class="forest-image-container">
  <img src="assets/img/forest-service-logo.svg" alt="Forest Service" />
  <div class="help-title">
    <h1>Help Center</h1>
    <span>We're here to help. Select a tile to get started!</span>
  </div>
</div>

<div class="cards-container">
  <div class="flip-card" tabindex="0">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img
          class="icon"
          src="assets/img/icons/help/multitool.svg"
          alt="Multi-Tool"
        />
        <h3>"How To" Guides</h3>
      </div>
      <div class="flip-card-back">
        <h3>How To Guides</h3>
        <p>Access KBAs, training videos and User Guides</p>
        <a [routerLink]="['/help/info/how-to']">Visit How To Guides</a>
      </div>
    </div>
  </div>

  <div class="flip-card" tabindex="0">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img
          class="icon"
          src="assets/img/icons/help/clipboard.svg"
          alt="Clipboard"
        />
        <h3>Frequently Asked Questions</h3>
      </div>
      <div class="flip-card-back">
        <h3>Frequently Asked Questions</h3>
        <p>Common questions about using CIAO</p>
        <a [routerLink]="['/help/info/faq']">View FAQs</a>
      </div>
    </div>
  </div>

  <div class="flip-card" tabindex="0">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <img
          class="icon"
          src="assets/img/icons/help/fieldgoer.svg"
          alt="Field Goer"
        />
        <h3>Admin</h3>
      </div>
      <div class="flip-card-back">
        <h3>Admin</h3>
        <p>Learn about Admin-related tasks and Roles & Permissions</p>
        <a [routerLink]="['/help/info/admin']">Visit Admin Guides</a>
      </div>
    </div>
  </div>

  <div class="flip-card" tabindex="0">
    <div class="flip-card-inner">
      <div class="flip-card-front new">
        <div class="ribbon-wrapper-green">
          <div class="ribbon-green">v1.13</div>
        </div>
        <img
          class="icon"
          src="assets/img/icons/help/compass.svg"
          alt="Compass"
        />
        <h3>Getting Started Guide</h3>
      </div>
      <div class="flip-card-back">
        <h3>Getting Started Guide</h3>
        <p>Download our new guide for comprehensive look at how to use CIAO</p>
        <a
          target="_blank"
          href="/assets/help/pdfs/Getting_Started_Guide_v1.13.pdf"
        >
          View Getting Started Guide
        </a>
      </div>
    </div>
  </div>

  <!-- <div class="flex-wrap-placeholder"></div>
  <div class="flex-wrap-placeholder"></div> -->
</div>

<!-- Leaving this here in case it is desired for a future release -->
<!-- <div class="new-statements-container">
  <p>
    <ciao-new-icon />
    Check out our new
    <a
      target="_blank"
      rel="noopener"
      href="/assets/help/pdfs/Volunteer_Log_In_Guide_v1.pdf"
      >Volunteer Log In Guide</a
    >
    and FAQ!
  </p>
  <p>Download it and share it with your team members without a USDA account.</p>
</div> -->
