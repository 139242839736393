<div mat-dialog-title>
  <button
    (click)="closePopup()"
    mat-button
    type="button"
    class="close"
    aria-label="Close"
  >
    <mat-icon fontSet="material-icons-round">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <h2>LOADING DELAY...</h2>
  <p>Select <b>Refresh Browser</b> button below to reload CIAO.</p>
  <button (click)="refreshPage()" class="btn-action-solid">
    Refresh Browser
  </button>
  <p>
    If the problem persists,
    <b>contact your supervisor or a team-member to update your status.</b>
  </p>
  <div class="hr-short-and-cute"></div>
  <p>
    We apologize for the inconvenience. A detailed summary of your experience
    can be sent to:
    <a href="mailto:sm.fs.ciao_support@usda.gov">
      sm.fs.ciao_support&#64;usda.gov
    </a>
  </p>
</mat-dialog-content>
