<div class="container-fluid">
  <div class="row">
    <div class="col">
      <mat-form-field
        ><input
          matInput
          [id]="inputId + '_from'"
          [title]="label"
          [autocomplete]="'ciao-no-autocomplete'"
          class="dateInput dateInputFrom"
          #dpFromDate
          placeholder="mon dd, yyyy"
          [formControl]="formControlFrom"
          (click)="datepicker.toggle()"
          (blur)="formControlInput.updateValueAndValidity()"
          autocomplete="off"
      /></mat-form-field>
    </div>
    <span class="inBetween"> to </span>

    <div class="col">
      <mat-form-field
        ><input
          matInput
          [id]="inputId + '_to'"
          [title]="label"
          [autocomplete]="'ciao-no-autocomplete'"
          class="dateInput dateInputTo"
          #dpToDate
          placeholder="mon dd, yyyy"
          [formControl]="formControlTo"
          (click)="datepicker.toggle()"
          (blur)="formControlInput.updateValueAndValidity()"
          autocomplete="off"
      /></mat-form-field>
    </div>
  </div>
</div>

<mat-form-field>
  <input
    matInput
    [id]="inputId + '_extra'"
    [title]="label"
    name="datepicker"
    class="datepicker"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    [autoClose]="'outside'"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="2"
    [dayTemplate]="DayTemplate"
    outsideDays="hidden"
    [startDate]="secretFormControl.value.from!"
    [positionTarget]="dpFromDate"
    [placement]="['bottom-left', 'bottom']"
/></mat-form-field>
<ng-template #DayTemplate let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
