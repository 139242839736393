<span class="cardinal-direction" [class.invalid]="lattitudeControl.value < 0">
  {{ lattitudeControl.value < 0 ? "S" : "N" }}
</span>
<input
  #lattitude
  matInput
  [id]="inputId + 'lattitude'"
  [title]="label + 'lattitude'"
  [autocomplete]="'ciao-no-autocomplete'"
  type="number"
  min="-90"
  max="90"
  step="0.00001"
  placeholder="lattitude"
  [formControl]="lattitudeControl"
  [class.invalid]="lattitudeControl.invalid"
/>
<span class="cardinal-direction" [class.invalid]="longitudeControl.value > 0">
  {{ longitudeControl.value > 0 ? "E" : "W" }}
</span>
<input
  #longitude
  matInput
  [id]="inputId + 'longitude'"
  [title]="label + 'longitude'"
  [autocomplete]="'ciao-no-autocomplete'"
  type="number"
  min="-180"
  max="180"
  step="0.00001"
  placeholder="longitude"
  [formControl]="longitudeControl"
  [class.invalid]="longitudeControl.invalid"
/>

<!-- (click)="$event.stopPropagation()" -->
