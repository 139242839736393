import { Injectable } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * @see https://ng-bootstrap.github.io/#/components/datepicker/examples#adapter for example
 */
@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = new Date(value);
      let [year, month, day] = date.toISOString().slice(0, 10).split('-');
      return {
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10),
      };
    }
    return null;
  }

  toModel(ngbDate: NgbDateStruct | null): string | null {
    if (!ngbDate) return null;
    let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    if (isNaN(date.valueOf())) {
      return null;
    }
    return date.toISOString().slice(0, 10);
  }
}
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * @see https://ng-bootstrap.github.io/#/components/datepicker/examples#adapter for example
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    let date = new Date(value);
    if (isNaN(date.valueOf())) {
      return null;
    }
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  format(ngbDate: NgbDateStruct | null): string {
    if (!ngbDate) {
      return '';
    }
    let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    let str = date.toLocaleDateString('en-us', {
      dateStyle: 'medium',
    } as any);
    return str;
  }
}
