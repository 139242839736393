import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

import {
  ConvertContactFormDataToSavableData,
  ConvertSavedContactDataToFormData,
  textNotificationOptions,
} from '~app/models/contactInfo';
import { UserModalFormComponent } from '../type';
import { AuthService, PersonService } from '~app/services';
import { BehaviorSubject, Subscription, combineLatest, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { AsyncPipe, NgFor } from '@angular/common';

const infoText = {
  GovernmentDevice: `Visible to all CIAO admin users.
Select the Text notifications checkbox to receive texts.`,
  Personal: `Visible to all CIAO admin users.
Select the Text notifications checkbox to receive texts.`,
  defaultPhone: `The number that auto-fills when user is added to a Trip.
Text notifications go to any phone that has texts enabled.`,
  defaultSupervisor: `If this person is on a Team with the user, they will be added automatically on a trip when the user is a Crew Member`,
} as const;

@Component({
  selector: 'ciao-user-contact-form',
  templateUrl: './user-contact-form.component.html',
  styleUrls: ['./user-contact-form.component.less'],
  standalone: true,
  imports: [CiaoFormsModule, AsyncPipe, NgFor],
})
export class UserContactFormComponent
  implements OnInit, UserModalFormComponent
{
  personId$ = new BehaviorSubject<string>(null);
  @Input() get personId() {
    return this.personId$.value;
  }
  set personId(val) {
    this.personId$.next(val);
  }
  @Input() registrationContext?: boolean = false;

  canUpdate$ = combineLatest([
    this.authService.currentUser$,
    this.personId$,
  ]).pipe(
    filter(([user, _personId]) => !!user),
    map(([user, personId]) => user.personId === personId)
  );

  readonly defaultData: FormData;

  governmentControls = {
    phoneNumber: new UntypedFormControl(''),
    smsOptIn: new UntypedFormControl(false),
    smsNotifications: new UntypedFormGroup({
      beforeLate: new UntypedFormControl(false),
      exactlyWhenLate: new UntypedFormControl(false),
      repeatedAfterLate: new UntypedFormControl(false),
    }),
  };

  personalControls = {
    phoneNumber: new UntypedFormControl(''),
    smsOptIn: new UntypedFormControl(false),
    smsNotifications: new UntypedFormGroup({
      beforeLate: new UntypedFormControl(false),
      exactlyWhenLate: new UntypedFormControl(false),
      repeatedAfterLate: new UntypedFormControl(false),
    }),
  };

  formGroup = new UntypedFormGroup({
    governmentPhone: new UntypedFormGroup(this.governmentControls),
    personalPhone: new UntypedFormGroup(this.personalControls),
    defaultPhone: new UntypedFormControl(null),
    defaultSupervisorId: new UntypedFormControl(null),
    allTexts: new UntypedFormGroup({
      governmentPhone: new UntypedFormControl(false),
      personalPhone: new UntypedFormControl(false),
    }),
  });

  readonly defaultPhoneSelectOptions$ = of([
    { label: 'Personal Cell Phone', value: 'personal' },
    { label: 'Government Cell Phone', value: 'government' },
  ]);
  readonly defaultSupervisorSelectOptions$ = this.personService.allPeople.pipe(
    map((people) =>
      people
        .filter((person) => this.personId != person.id)
        .map((person) => ({
          value: person.id,
          label: person.displayNameDropdown,
        }))
    )
  );

  textNotificationOptions = textNotificationOptions;

  readonly infoText = infoText;

  subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private personService: PersonService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.refreshPerson();
    this.intializeFormListeners();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  intializeFormListeners() {
    // When gov/personal phone number is not valid, clear values and disable checkboxes
    let govPhoneControl = this.formGroup.get('governmentPhone.phoneNumber');
    let govTextOptions = this.formGroup.get('governmentPhone.smsNotifications');

    this.subscriptions.add(
      this.canUpdate$
        .pipe(
          tap((canUpdate) =>
            canUpdate ? this.formGroup.enable() : this.formGroup.disable()
          )
        )
        .subscribe()
    );

    this.subscriptions.add(
      combineLatest([govPhoneControl.valueChanges, this.canUpdate$])
        .pipe(
          tap(([_value, canUpdate]) => {
            if (
              govPhoneControl.invalid ||
              !govPhoneControl.value ||
              !canUpdate
            ) {
              govTextOptions.patchValue({
                beforeLate: false,
                exactlyWhenLate: false,
                repeatedAfterLate: false,
              });
              govTextOptions.disable();
            } else {
              govTextOptions.enable();
            }
          })
        )
        .subscribe()
    );

    let personalPhoneControl = this.formGroup.get('personalPhone.phoneNumber');
    let personalTextOptions = this.formGroup.get(
      'personalPhone.smsNotifications'
    );
    this.subscriptions.add(
      combineLatest([personalPhoneControl.valueChanges, this.canUpdate$])
        .pipe(
          tap(([_value, canUpdate]) => {
            if (
              personalPhoneControl.invalid ||
              !personalPhoneControl.value ||
              !canUpdate
            ) {
              personalTextOptions.patchValue({
                beforeLate: false,
                exactlyWhenLate: false,
                repeatedAfterLate: false,
              });
              personalTextOptions.disable();
            } else {
              personalTextOptions.enable();
            }
          })
        )
        .subscribe()
    );
  }

  refreshPerson() {
    this.formGroup.reset();
    if (!this.personId) {
      return;
    }
    let contactInfo$ = this.personService.getContactInfo(this.personId);
    this.subscriptions.add(
      contactInfo$
        .pipe(
          map((rawinfo) => {
            let contactForm = ConvertSavedContactDataToFormData(rawinfo);
            return contactForm;
          }),
          tap((info) => {
            this.formGroup.patchValue(info);
          })
        )
        .subscribe()
    );
  }

  resetForm() {
    this.formGroup.reset();
  }

  saveFormData() {
    let data = ConvertContactFormDataToSavableData(this.formGroup.value);

    return this.personService.saveMyContactInfo(data, this.personId).pipe(
      take(1),
      tap((savedPerson) => {
        this.refreshPerson();
        this.toastService.success(
          'Your contact information was updated successfully.',
          'Success'
        );
      }),
      catchError((err, caught) => {
        console.log(err);
        this.toastService.error('Unable to save contact information.', 'Error');
        return err;
      })
    );
  }
}
