import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription, of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  skip,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AuthService, PersonService } from '~app/services';
import { UserRegistrationFormComponent } from './type';
import { PersonAttributes, UserAttributes } from '~app/models';
import {
  ConvertContactFormDataToSavableData,
  PersonContactInfo,
} from '~app/models/contactInfo';
import { ToastrService } from 'ngx-toastr';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';
import { UserDetailFormComponent } from '../user-modal/user-details-form/user-details-form.component';
import { UserContactFormComponent } from '../user-modal/user-contact-form/user-contact-form.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'ciao-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.less'],
  standalone: true,
  imports: [
    UserDetailFormComponent,
    UserContactFormComponent,
    NgIf,
    RouterLink,
  ],
})
export class RegistrationComponent implements OnInit {
  personControl: UntypedFormGroup;

  @ViewChild('detailsForm') detailsForm: UserRegistrationFormComponent;
  @ViewChild('contactForm') contactForm: UserRegistrationFormComponent;

  loadedEmail?: string;
  currentUser$ = this.authService.currentUser$;
  isSaving: boolean = false;

  subscriptions = new Subscription();
  showPage: string = this.route.snapshot.data.showPage;

  get formIsValid() {
    return (
      this.detailsForm?.formGroup?.valid && this.contactForm?.formGroup?.valid
    );
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private personService: PersonService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.authService.oAuthInfo$
      .pipe(
        filter((info) => !!info),
        take(1),
        tap((oAuthInfo) => (this.loadedEmail = oAuthInfo.email)),
        map((oAuthInfo) => ({
          person: {
            firstName: oAuthInfo.firstName,
            lastName: oAuthInfo.lastName,
          },
          users: [
            {
              email: oAuthInfo.email,
              id: '',
              notificationOptions: { defaultSet: false },
            },
            { email: '', id: '', notificationOptions: { defaultSet: false } },
          ],
        })),
        // adding delay(0) here so that this operation is added to the stack
        delay(0),
        tap((userInfo) => this.detailsForm?.formGroup.patchValue(userInfo))
        // This block will be used when the emails are reintroduced to the form visually
        // tap(() => {
        //   // Disable the email fields until issue #1188 is resolved
        //   const formArray = this.detailsForm?.formGroup.get(
        //     'emails'
        //   ) as FormArray;
        //   const govControl = formArray?.at(0);
        //   const nonGovControl = formArray?.at(1);
        //   govControl.disable();
        //   nonGovControl.disable();
        // })
      )
      .subscribe(); // take(1) will automatically unsubscribe after the first item has gone through.

    if (this.currentUser$) {
      this.router.navigate(['/']);
    }
  }

  ngOnDestory() {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }

  register() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    let detailsformValue = this.detailsForm.formGroup.value;
    let contactformValue = this.contactForm.formGroup.value;

    let detailsData = convertDetailsDataToAttr(detailsformValue);
    let contactData = ConvertContactFormDataToSavableData(contactformValue);

    let registerData = {
      id: '',
      firstName: detailsformValue.person.firstName,
      lastName: detailsformValue.person.lastName,
      // registration specific fields
      cellPhone: '',
      smsOptIn: false,
      jobTitle: detailsformValue.person.jobTitle,
    };
    this.subscriptions.add(
      this.authService
        .registerNewUser(registerData)
        .pipe(
          switchMap(() => this.currentUser$),
          skip(1),
          switchMap((user: UserAttributes) => {
            // Make sure that we are saving using the personId and not a user's
            detailsData.person.id = user.personId;

            return this.personService
              .savePerson(detailsData?.person, detailsData?.users)
              .pipe(
                catchError((err) => {
                  this.toastService.error(
                    'There was an error saving your details.',
                    'Error'
                  );
                  throw err;
                })
              );
          }),
          switchMap((person) =>
            this.personService.saveMyContactInfo(contactData, person.id).pipe(
              catchError((err) => {
                this.toastService.error(
                  'There was an error saving your contact information.',
                  'Error'
                );
                throw err;
              })
            )
          ),
          tap(() => {
            this.toastService.success(
              'You have registered with CIAO.',
              'Success'
            );
          }),
          catchError((err) => {
            this.toastService.error('There was an error registering.', 'Error');
            return of(null);
          }),
          tap(() => {
            this.isSaving = false;
          })
        )
        .subscribe()
    );
  }
}

function convertDetailsDataToAttr(data: DetailsFormData): {
  person: PersonAttributes;
  users: UserAttributes[];
} {
  let { id, firstName, lastName, jobTitle } = data.person;
  let email =
    data.users?.find((user) => user?.email?.endsWith('@usda.gov'))?.email ||
    data.users?.[0]?.email ||
    data.users?.[1]?.email ||
    'No Email';
  let person = {
    id: id || undefined,
    firstName: firstName,
    lastName: lastName,
    displayName: `${firstName} ${lastName}`,
    displayNameDropdown: `${firstName} ${lastName} (${email})`,
    jobTitle: jobTitle,
  };
  let users = data.users.filter((user) => !!user.email);
  return { person, users };
}
interface DetailsFormData {
  person: {
    id: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
  };
  users: {
    id: string;
    email: string;
    notificationOptions: { defaultSet: boolean };
  }[];
}
