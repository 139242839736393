<h1>Style Guide</h1>
<h2>
  Examples of reusable components for developers to pull from, non-developers to
  look at and approve, and developers/designers to check their edits
</h2>
<div style="width: 20em; margin: auto">
  <ciao-form-field
    label="Focused Section"
    type="dropdown"
    [selectOptions$]="focusOnOptions$"
    [formControlInput]="focusOnControl"
  ></ciao-form-field>
</div>

<hr />
<div style="padding: 2em">
  <ng-container [ngSwitch]="focusOnControl.value">
    <ng-container *ngSwitchCase="'forms'">
      <template [ngTemplateOutlet]="FormsExample"></template>
    </ng-container>
    <ng-container *ngSwitchCase="'combobox'">
      <ciao-combobox-tester></ciao-combobox-tester>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <template [ngTemplateOutlet]="PipesExample"></template>
      <hr />
      <template [ngTemplateOutlet]="ButtonsExample"></template>
      <hr />
      <template [ngTemplateOutlet]="IconsExample"></template>
      <hr />
      <template [ngTemplateOutlet]="ModalsExample"></template>
      <hr />
      <template [ngTemplateOutlet]="FormsExample"></template>
    </ng-container>
  </ng-container>
</div>
<hr />

<ng-template #PipesExample>
  <h1>Pipes</h1>
  <h3>Date Pipe</h3>
  <ul>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "datetime" : "readable" }}</code>
      {{ date | ciaoDate : "datetime" : "readable" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "date" : "readable" }}</code>
      {{ date | ciaoDate : "date" : "readable" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "time" : "readable" }}</code>
      {{ date | ciaoDate : "time" : "readable" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "datetime" : "simple" }}</code>
      {{ date | ciaoDate : "datetime" : "simple" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "date" : "simple" }}</code>
      {{ date | ciaoDate : "date" : "simple" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "time" : "simple" }}</code>
      {{ date | ciaoDate : "time" : "simple" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "datetime" : "filter" }}</code>
      {{ date | ciaoDate : "datetime" : "filter" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "date" : "filter" }}</code>
      {{ date | ciaoDate : "date" : "filter" }}
    </li>
    <li>
      <code ngNonBindable>{{ date | ciaoDate : "time" : "filter" }}</code>
      {{ date | ciaoDate : "time" : "filter" }}
    </li>
  </ul></ng-template
>

<ng-template #ButtonsExample>
  <h1>Button Styles</h1>
  no class
  <button>Normal</button>
  <button disabled>Disabled</button>
  <br />

  <ng-template ngFor let-name [ngForOf]="buttonStyles">
    btn-{{ name }}[-light or -solid]
    <button class="btn-{{ name }}">Normal</button>
    <button class="btn-{{ name }}-light">Light</button>
    <button class="btn-{{ name }}-solid">Solid</button>
    <button class="btn-{{ name }}" disabled>Normal Disabled</button>
    <button class="btn-{{ name }}-light" disabled>Light Disabled</button>
    <button class="btn-{{ name }}-solid" disabled>Solid Disabled</button>
    <br />
  </ng-template>

  <a [routerLink]="'/home'">Naviagtion Link (visited)</a> <br />
  <a [routerLink]="'/unvisited-link/' + randomNumberString">
    Naviagtion Link (unvisited)
  </a>
  <br />
</ng-template>

<ng-template #IconsExample>
  <h1>FontAwesome Icons</h1>
  <h3>Preferred Icons</h3>
  <ng-template ngFor let-icon [ngForOf]="faPreferredIcons">
    <fa-icon [icon]="icon.name"></fa-icon>&nbsp;
  </ng-template>
  <div>
    <ul>
      <ng-template ngFor let-icon [ngForOf]="faPreferredIcons">
        <li><fa-icon [icon]="icon.name"></fa-icon> {{ icon.displayName }}</li>
      </ng-template>
    </ul>
  </div>
  <h3>Customization Examples</h3>
  <a
    href="https://www.angularjswiki.com/angular/how-to-use-font-awesome-icons-in-angular-applications/"
    >Check here for examples</a
  >
  <div>
    <ul>
      <li>
        <fa-icon [icon]="check"></fa-icon>
      </li>
      <!-- <li>
        default, regular, solid
        <fa-icon [icon]="bell"></fa-icon>
        <fa-icon [icon]="['far', 'bell']"></fa-icon>
        <fa-icon [icon]="['fas', 'bell']"></fa-icon>
      </li> -->
      <li>
        size up
        <fa-icon [icon]="sun"></fa-icon>
        <fa-icon [icon]="sun" size="3x"></fa-icon>
      </li>
      <li>
        colors
        <fa-icon [icon]="sun" [styles]="{ color: 'green' }"></fa-icon>
        <fa-icon [icon]="sun" size="3x" [styles]="{ color: 'green' }"></fa-icon>
        <fa-icon [icon]="sun" size="3x" [styles]="{ color: 'green' }"></fa-icon>
      </li>
      <li>
        Masks
        <fa-icon size="2x" [icon]="sun"></fa-icon>
        <fa-icon size="2x" [icon]="comment"></fa-icon>
        <fa-icon
          size="2x"
          transform="shrink-5"
          [icon]="sun"
          mask="comment"
        ></fa-icon>
        <fa-icon
          size="2x"
          transform="shrink-8"
          [icon]="comment"
          mask="sun"
        ></fa-icon>
      </li>
      <li>
        Layers
        <fa-icon [icon]="ban"></fa-icon>
        <fa-icon [icon]="mobileAlt"></fa-icon>
        <fa-layers size="2x">
          <fa-icon [icon]="mobileAlt" transform="shrink-7"></fa-icon>
          <fa-icon [icon]="ban" style="color: red"></fa-icon>
        </fa-layers>
      </li>
      <li>
        Counters
        <fa-icon [icon]="bullHorn" size="2x"></fa-icon>
        <fa-layers size="2x">
          <fa-icon [icon]="bullHorn"></fa-icon>
          <fa-layers-counter content="28"></fa-layers-counter>
        </fa-layers>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #ModalsExample>
  <h1>Modals</h1>
  <div>
    <h3>Basic no configuration</h3>
    <button (click)="modal1.openModal()">Open Modal 1</button>
    <ciao-modal #modal1></ciao-modal>
  </div>
  <div>
    <h3>Some configuration</h3>
    <button (click)="modal2.openModal()">Open Modal 2</button>
    <ciao-modal #modal2 title="Some configuration">
      <span modalBody>
        This span tag is marked with <code>modalBody</code>.
        <br />
        It doesn't have to be a span.
      </span>
      <span modalFooter>
        <button class="btn" (click)="modal2.dismiss('cancel')">Cancel</button>
        <button class="btn-green">Refresh</button>
        <button class="btn-danger" (click)="modal2.close('confirm')">
          Confirm
        </button>
      </span>
    </ciao-modal>
  </div>
  <div>
    <h3>Multiple Modals</h3>
    <button (click)="modal3.openModal()">Open Modal 3</button>
    <ciao-modal #modal3 title="You specify each modal for closing">
      <p modalBody>
        You have unsaved changes. Are you sure you want to abandon them?
      </p>
      <span modalFooter>
        <button class="btn" (click)="modal3.dismiss('cancel')">Cancel</button>
        <span>Arbitrary Text in footer</span>
        <button class="btn-danger" (click)="modal3.close('confirm')">
          Confirm
        </button>
      </span>
    </ciao-modal>
  </div>
  <div>
    <h3>Close after 4 seconds</h3>
    <button (click)="openThenClose(modal4)">Open Modal 4</button>
    <ciao-modal #modal4 title="Close after 4 seconds">
      <p modalBody>This will automatically close after 4 seconds</p>
      <span modalFooter>
        <button class="btn" (click)="modal4.dismiss('cancel')">Cancel</button>
        <button class="btn-danger" (click)="modal4.close('confirm')">
          Confirm
        </button>
      </span>
    </ciao-modal>
  </div>
</ng-template>

<ng-template #FormsExample>
  <style-guide-form-controls></style-guide-form-controls>
</ng-template>
