import { Component, OnInit } from '@angular/core';
import { ConfigService } from '~app/services';

@Component({
  selector: 'app-fs-footer',
  templateUrl: './fs-footer.component.html',
  styleUrls: ['./fs-footer.component.less'],
})
export class FsFooterComponent implements OnInit {
  links = [
    {
      text: 'US Forest Service',
      route: 'https://www.fs.usda.gov',
    },
    {
      text: 'USDA.gov',
      route: 'https://www.usda.gov',
    },
    {
      text: 'Policies & Links',
      route: 'https://www.usda.gov/policies-and-links',
    },
    {
      text: 'Whitehouse.gov',
      route: 'https://www.whitehouse.gov',
    },
    {
      text: 'USA.gov',
      route: 'https://www.usa.gov',
    },
    {
      text: 'FOIA.gov',
      route:
        'https://www.usda.gov/ogc/office-information-affairs/foia-division',
    },
  ];

  versionInfoString = [
    'CIAO Version',
    this.configService.getConfig('version_number'),
  ].join(' ');

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    // When the user scrolls down 20px from the top of the document, show the white bar
    window.onscroll = () => {
      this.scrollFunc();
    };
  }

  // Hide and show the white bar
  scrollFunc() {
    let returnToTopBar = document.getElementById('scrollBar');

    if (
      document.body.scrollTop > 40 ||
      document.documentElement.scrollTop > 40
    ) {
      returnToTopBar.style.display = 'block';
    } else {
      returnToTopBar.style.display = 'none';
    }
  }

  // Scroll to top of page
  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  clickLink = (link) => {
    window.location.href = link.route;
  };
}
