import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MainSidebar, TopicSidebars, HelpPages } from './info-navigation-map';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ciao-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less'],
})
export class InfoComponent implements OnInit {
  readonly pdfEmbedReady = false;

  navCurrentFocus: string;
  navCurrentSection: string;

  sectionDefinition$: Observable<HelpModule.HelpPage>;

  MainSidebar = MainSidebar;

  MatIcons = {
    video: 'smart_display',
    external: 'arrow_outward',
  };

  breadcrumbTrail: { href: string; title: string }[];
  currentSection: HelpModule.HelpPage;
  currentTopic: HelpModule.HelpPage;
  currentSidebar: HelpModule.SidebarItem[];

  subscriptions = new Subscription();

  constructor(private route: ActivatedRoute, private title: Title) {
    this.subscriptions.add(
      this.route.params
        .pipe(
          map((params) =>
            (params.slugString as string)
              .split('/')
              .map((_, index, arr) => arr.slice(0, index + 1).join('/'))
              .map((slugStack) => ({
                slugStack: slugStack,
                href: '/help/info/' + slugStack,
                page: HelpPages[slugStack],
                title: HelpPages[slugStack]?.title,
              }))
          ),
          tap((breadcrumbs) => {
            this.breadcrumbTrail = [
              { title: 'Home', href: '/home' },
              { title: 'Help', href: '/help' },
              ...breadcrumbs,
            ];
            this.currentSection = breadcrumbs[0]?.page;
            this.currentTopic = breadcrumbs[1]?.page;
            this.currentSidebar = TopicSidebars[breadcrumbs[0]?.slugStack];
            this.navCurrentSection = '/help/info/' + breadcrumbs[0]?.slugStack;
            this.navCurrentFocus = '/help/info/' + breadcrumbs[0]?.slugStack;

            this.title.setTitle(
              breadcrumbs[0]?.title + ' | Info | CIAO - Check In And Out'
            );
          })
        )
        .subscribe()
    );
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
