import { Component } from '@angular/core';

@Component({
  selector: 'ciao-new-icon',
  template: `<span class="new-icon">NEW</span>`,
  styles: `
    .new-icon {
      display: inline-block;
      font-weight: 500;
      font-size: 12px;
      background-color: #EF4A16;
      color: white;
      padding: .15em .35em;
      width: 36px;
      height: 20px;
      margin-left: .5em;
    }
  `,
})
export class NewIconComponent {}
