<div mat-dialog-title>
  <span>
    <img
      class="error-icon"
      src="/assets/img/icons/status-icon-error.svg"
      alt="Error"
    />
    <ng-container [ngSwitch]="data.errorType">
      <ng-container *ngSwitchCase="'INVALID_FILE_TYPE'">
        File Type Invalid
      </ng-container>
      <ng-container *ngSwitchCase="'INVALID_FILE_SIZE'">
        File Size Error
      </ng-container>
      <ng-container *ngSwitchDefault> Something Went Wrong </ng-container>
    </ng-container>
  </span>
  <button
    class="close semantic-button"
    aria-label="Close"
    (click)="dialogRef.close()"
  >
    <mat-icon fontSet="material-icons-round">close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <ng-container [ngSwitch]="data.errorType">
    <ng-container *ngSwitchCase="'INVALID_FILE_TYPE'">
      <p>
        Invalid file format. <em>{{ data.fileName }}</em> could not be uploaded.
      </p>
      <p class="center">
        File extensions allowed:<br />
        <b><em>jpg, png, pdf, doc, docx, xls, xlsx, gpx</em></b>
      </p>
    </ng-container>
    <ng-container *ngSwitchCase="'INVALID_FILE_SIZE'">
      File <em> {{ data.fileName }} </em> exceeds the 10 MB file size limit.
    </ng-container>
    <ng-container *ngSwitchDefault>
      File <em> {{ data.fileName }} </em> could not be uploaded.
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button class="btn-action-solid" (click)="dialogRef.close()">Close</button>
</div>
