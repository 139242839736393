import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ciao-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.less'],
})
export class VersionHistoryComponent implements OnInit {
  readonly historySource = '/assets/help/markdown/version-history-data.md';
  constructor() {}

  ngOnInit(): void {}
}
