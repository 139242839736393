import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { TripStatus } from '~app/models/trip';

import { RolePermissionService } from '~app/services/role-permission.service';
import { UserGroupService } from '~app/services/user-group.service';
import { BasicTripDetails, TripModalFormComponent } from '../types';

@Component({
  selector: 'ciao-details-form',
  templateUrl: './details-form.component.html',
  styleUrls: [
    './details-form.component.less',
    '../trip-modal-form-styles.less',
  ],
})
export class DetailsFormComponent
  implements OnInit, TripModalFormComponent<BasicTripDetails>
{
  @Input() editingExisting: boolean;
  @Input() duplicating: boolean;

  get formTitle() {
    if (this.duplicating) {
      return 'Duplicate Trip';
    } else if (this.editingExisting) {
      return 'Update Trip Details';
    } else {
      return 'New Trip';
    }
  }

  userGroupOptions$: Observable<{ value: string; label: string }[]> =
    this.getUserGroupOptions();

  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl('', Validators.required),
    startDate: new UntypedFormControl(new Date(), [
      Validators.required,
      this.startDateValidator.bind(this),
    ]),
    endDate: new UntypedFormControl('', [
      Validators.required,
      this.endDateValidator.bind(this),
      this.endDateInPastValidator.bind(this),
    ]),
    tripStatus: new UntypedFormControl('Planned'),
  });

  userGroupControl = this.formGroup.get('userGroupId');
  startDateControl = this.formGroup.get('startDate');
  endDateControl = this.formGroup.get('endDate');
  tripStatusControl = this.formGroup.get('tripStatus');
  get finalResult() {
    return this.formGroup.value;
  }

  constructor(
    private userGroupService: UserGroupService,
    private rolePermissionService: RolePermissionService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.editingExisting) {
      this.userGroupControl.disable();
    } else {
      this.userGroupControl.enable();
    }
  }

  //#region Date Validators
  startDateValidator(control) {
    let invalid = control.value > this.endDateControl?.value;
    if (this.endDateControl?.invalid !== invalid) {
      setTimeout(() => this.endDateControl?.updateValueAndValidity(), 0);
    }
    if (invalid) {
      return {
        endDateBeforeStartDate: 'Start Date must be before End Date',
      };
    }
  }
  endDateValidator(control) {
    let invalid = control.value < this.startDateControl?.value;
    if (this.startDateControl?.invalid !== invalid) {
      setTimeout(() => this.startDateControl?.updateValueAndValidity(), 0);
    }
    if (invalid) {
      return {
        endDateBeforeStartDate: 'End Date must be after Start Date',
      };
    }
  }

  endDateInPastValidator(control) {
    let invalid = control.value < new Date().toISOString();
    if (invalid) {
      return {
        endDateInPast: 'Please add a future return date',
      };
    }
  }
  //#endregion

  getUserGroupOptions() {
    // Grab Teams first, then search within Teams,
    // so if the definition of "Team" changes again, this won't change
    return this.userGroupService.allTeams$.pipe(
      switchMap((groups) =>
        this.rolePermissionService.searchMyUserGroupsByPermission({
          permissionIds: ['create_trips', 'edit_trip'],
          filterWithinUserGroups: groups,
        })
      ),
      this.userGroupService.op_sortGroups,
      map((userGroups) =>
        userGroups.map((userGroup) => ({
          value: userGroup.id,
          label: `${userGroup.labelPrefix}${userGroup.label}`,
        }))
      ),
      share()
    );
  }
}
