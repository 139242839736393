<button
  class="opener"
  [class]="buttonClass"
  [disabled]="disabled"
  (click)="okayModal.openSmallModal()"
>
  <div
    class="button-description"
    [ngClass]="{ 'trip-summary-context': tripSummaryContext }"
  >
    <fa-icon class="fa-2x" [icon]="buttonIcon"></fa-icon>
    <div class="button-text">
      <h4>{{ buttonText }}</h4>
      <br />
      <h5 class="button-message">{{ description }}</h5>
    </div>
  </div>
</button>

<ciao-modal
  #okayModal
  [title]="title"
  (modalInstance)="reinitializeValues()"
  [loading]="sendingRequest"
>
  <div class="modal-body" modalBody>
    <p *ngIf="canSendOkayAtAll$ | async" class="font-red">
      Note: You need to be on a Team to {{ displayValues.noTeamMessagePart }}.
      <a [routerLink]="'/help/info/how-to'">Learn how to join a Team here.</a>
    </p>
    <ng-container *ngIf="allowTripSelection">
      <ciao-form-field
        class="imSpecial"
        label="Choose Trip"
        type="combobox"
        [selectOptions$]="trips$"
        [formControlInput]="tripControl"
      ></ciao-form-field>
    </ng-container>
    <div *ngIf="myFormGroup.value.trip; let trip">
      <div class="trip-location">
        <mat-label>Location</mat-label>
        <p class="location-description">{{ getTripLocation(trip) }}</p>
      </div>
      <div class="trip-start">
        <mat-label>Start Date & Time</mat-label>
        <p class="start-datetime">{{ getTripStartTime(trip) }}</p>
      </div>
    </div>
    <ciao-form-field
      [label]="readableOkayType + ' Date'"
      type="datetime"
      [formControlInput]="timeOkayControl"
    ></ciao-form-field>
    <ciao-form-field
      [label]="'Notes'"
      [formControlInput]="notesControl"
    ></ciao-form-field>
    <ng-container *ngIf="crewMembers$ | async as crewMembersOptions">
      <ng-container
        *ngIf="okayType == 'checkIn' && crewMembersOptions.length > 1"
      >
        <ciao-form-checkbox-group
          [label]="'Crew Members'"
          [options]="crewMembersOptions"
          [selectAllEnabled]="true"
          [formControl]="crewMembersControl"
        ></ciao-form-checkbox-group>
        <span class="custom-checkbox-error">
          {{ myFormGroup.get("crewMembers")?.errors?.noneSelected ?? "" }}
        </span>
      </ng-container>
    </ng-container>
  </div>
  <p modalFooter>
    <button
      class="btn-action-solid confirm-button center"
      (click)="SendOkay()"
      [disabled]="myFormGroup.invalid || sendingRequest"
    >
      <fa-icon [icon]="buttonIcon"></fa-icon>
      {{ title }}
      <span class="confirm-button-desc">{{ description }}</span>
    </button>
  </p>
</ciao-modal>
