import { PersonAttributes } from './person';
import { ResourceAttributes } from './resource';

export type PersonContactInfo = {
  governmentPhone?: NotificationNumber & { phoneType: 'government' };
  personalPhone?: NotificationNumber & { phoneType: 'personal' };
  defaultSupervisorId?: string;
  defaultSupervisor?: PersonAttributes;
};

export type NotificationNumber = ResourceAttributes & {
  phoneNumber: string;
  phoneType?: 'government' | 'personal';
  default: boolean;
  smsOptIn: boolean;
  smsNotifications: {
    beforeLate: boolean;
    exactlyWhenLate: boolean;
    repeatedAfterLate: boolean;
  };
};

export const textNotificationOptions = [
  {
    key: 'beforeLate',
    label: '15 minutes before a trip is late (Crew Members Only)',
  },
  {
    key: 'exactlyWhenLate',
    label: 'Only when a trip is late',
  },
  {
    key: 'repeatedAfterLate',
    label:
      'Every 15 minutes (up to 24 hours) after a trip is late until trip is Checked In',
  },
] as const;

export interface ContactFormData {
  defaultPhone: 'government' | 'personal';
  defaultSupervisorId: string;
  governmentPhone: {
    phoneNumber: string;
    smsOptIn: boolean;
    smsNotifications: {
      beforeLate: boolean;
      exactlyWhenLate: boolean;
      repeatedAfterLate: boolean;
    };
  };
  personalPhone: {
    phoneNumber: string;
    smsOptIn: boolean;
    smsNotifications: {
      beforeLate: boolean;
      exactlyWhenLate: boolean;
      repeatedAfterLate: boolean;
    };
  };
  allTexts: {
    governmentPhone: boolean;
    personalPhone: boolean;
  };
}

/** Converting form data to object for db */
export function ConvertContactFormDataToSavableData(
  formData: ContactFormData
): PersonContactInfo {
  let govData = formData.governmentPhone;
  let personalData = formData.personalPhone;
  let govPhone = govData.phoneNumber;
  let personalPhone = personalData.phoneNumber;
  let govSmsOptions = govData.smsNotifications;
  let personalSmsOptions = personalData.smsNotifications;
  let govIsDefault;

  formData.defaultPhone === 'personal'
    ? (govIsDefault = false)
    : (govIsDefault = true);

  // If a phone number is empty upon submission then blast away all values from the respective phone object
  let contactInfo = {
    governmentPhone: govPhone
      ? {
          phoneType: 'government' as const,
          default: govIsDefault,
          phoneNumber: govPhone,
          smsOptIn:
            govSmsOptions?.beforeLate ||
            govSmsOptions?.exactlyWhenLate ||
            govSmsOptions?.repeatedAfterLate,
          smsNotifications: {
            beforeLate: govSmsOptions?.beforeLate,
            exactlyWhenLate: govSmsOptions?.exactlyWhenLate,
            repeatedAfterLate: govSmsOptions?.repeatedAfterLate,
          },
        }
      : null,
    personalPhone: personalPhone
      ? {
          phoneType: 'personal' as const,
          default: !govIsDefault,
          phoneNumber: personalPhone,
          smsOptIn:
            personalSmsOptions?.beforeLate ||
            personalSmsOptions?.exactlyWhenLate ||
            personalSmsOptions?.repeatedAfterLate,
          smsNotifications: {
            beforeLate: personalSmsOptions?.beforeLate,
            exactlyWhenLate: personalSmsOptions?.exactlyWhenLate,
            repeatedAfterLate: personalSmsOptions?.repeatedAfterLate,
          },
        }
      : null,
    defaultSupervisorId: formData.defaultSupervisorId ?? null,
  };
  return contactInfo;
}

/** Converting database object into form data */
export function ConvertSavedContactDataToFormData(
  rawInfo: PersonContactInfo
): ContactFormData {
  let gov = rawInfo?.governmentPhone;
  let govOptions = gov?.smsNotifications;

  let personal = rawInfo?.personalPhone;
  let personalOptions = personal?.smsNotifications;

  let govAllTexts;
  let personalAllTexts;
  govOptions
    ? (govAllTexts = Object.entries(govOptions).every((option) => option[1]))
    : (govAllTexts = null);

  personalOptions
    ? (personalAllTexts = Object.entries(personalOptions).every(
        (option) => option[1]
      ))
    : (personalAllTexts = null);

  let defaultPhone;
  if (gov?.default) {
    defaultPhone = 'government';
  } else {
    defaultPhone = 'personal';
  }

  return {
    governmentPhone: {
      phoneNumber: gov?.phoneNumber,
      smsOptIn: gov?.smsOptIn,
      smsNotifications: {
        beforeLate: govOptions?.beforeLate,
        exactlyWhenLate: govOptions?.exactlyWhenLate,
        repeatedAfterLate: govOptions?.repeatedAfterLate,
      },
    },
    personalPhone: {
      phoneNumber: personal?.phoneNumber,
      smsOptIn: personal?.smsOptIn,
      smsNotifications: {
        beforeLate: personalOptions?.beforeLate,
        exactlyWhenLate: personalOptions?.exactlyWhenLate,
        repeatedAfterLate: personalOptions?.repeatedAfterLate,
      },
    },
    defaultPhone: defaultPhone,
    defaultSupervisorId: rawInfo.defaultSupervisorId,
    allTexts: {
      governmentPhone: govAllTexts,
      personalPhone: personalAllTexts,
    },
  };
}
