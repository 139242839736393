import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  constructor() {}

  getValidationErrorText(key, value) {
    switch (key) {
      case 'required':
        return 'this is required.';
      case 'email':
        return 'must be a valid email';
      case 'nonUsdaEmail':
        return 'must be a @usda.gov email';
      case 'incorrectFsCellPhone':
        return 'Format must be 000-000-0000';
      case 'incorrectSatellitePhone':
        return 'Format must be 000-00-000-000-0000';
      default:
        if (typeof value === 'string') {
          return `${value}`;
        } else {
          return `${key}: ${JSON.stringify(value)}`;
        }
    }
  }

  private getDateInstance(date: Date | string) {
    if (typeof date === 'string' && date.length === 10) {
      date += 'T00:00';
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return date;
  }

  /**
   * A custom formater aimed at short durations.
   * Other date libraries mostly work, but don't like breaking down minutes and seconds.
   * @param {number} timeBetweenMS - The time, in milliseconds, between the two numbers you wish to describe
   * @returns {string} A human-readable description of the duration, eg:
   * - 30 seconds
   * - 4 minutes
   * - 4 minutes and 29 seconds
   * - 3 hours, 2 minutes
   * - 3 hours, 2 minutes, 28 seconds
   * - 148 hours, 1 minutes, 27 seconds
   */
  formatShortDurationMinutesSeconds(timeBetweenMS: number) {
    let totalSeconds = timeBetweenMS / 1000;
    let totalMinutes = totalSeconds / 60;
    let totalHours = totalMinutes / 60;
    let seconds = Math.floor(totalSeconds) % 60;
    let minutes = Math.floor(totalMinutes) % 60;
    let hours = Math.floor(totalHours);

    const parts = [] as string[];
    if (hours) {
      parts.push(`${hours} hours`);
    }
    if (minutes) {
      parts.push(`${minutes} minutes`);
    }
    if (seconds) {
      parts.push(`${seconds} seconds`);
    }
    return parts.join(parts.length === 2 ? ' and ' : ', ');
  }

  formatDatetime(date: Date | string) {
    if (!date) {
      return null;
    }
    date = this.getDateInstance(date);
    return `${this.formatDateOnly(date)} | ${this.formatTimeOnly(date)}`;
  }

  formatSimpleDatetime(date: Date | string) {
    // 2/13/2023 15:00 EST
    if (!date) {
      return null;
    }

    date = this.getDateInstance(date);
    return date
      .toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      })
      .replace(',', ' ');
  }

  formatDateOnly(date: Date | string) {
    if (!date) {
      return null;
    }
    date = this.getDateInstance(date);
    return date.toDateString();
  }
  formatTimeOnly(date: Date | string) {
    if (!date) {
      return null;
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleTimeString(navigator?.language ?? 'en-US', {
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: navigator?.language === 'en-US',
    });
  }
  formatDateForFilter(date: Date, withTime: boolean) {
    if (!date) {
      return 'none';
    }
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    };
    const dateTimeOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      hour12: true,
      minute: '2-digit',
    };
    return date.toLocaleString(
      'en-US',
      withTime ? dateTimeOptions : dateOptions
    );
  }

  getSlug(str: string) {
    return str.toLowerCase().replace(/ /g, '_');
  }

  shortenText(text: string, maxLength = 25) {
    if (!text) return '';

    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }
}
