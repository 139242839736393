import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EasterEggService {
  ciaoDevEasterEgg() {
    // American Flag Big CIAO with blue+white characters for starry area.
    console.log(
      `
  %c      ,gggg,        ,a8a,  %c      ,ggg,     _,gggggg,_    
  %c    ,88"""Y8b,     ,8" "8, %c     dP""8I   ,d8P""d8P"Y8b,  
  %c   d8"     \`Y8     d8   8b%c     dP   88  ,d8'   Y8   "8b,dP
  %c  d8'   8b  d8     88   88 %c   dP    88  d8'    \`Ybaaad88P'
  %c ,8I    "Y88P'     88   88 %c  ,8'    88  8P       \`""""Y8  
  %c I8'               Y8   8P %c  d88888888  8b            d8  
  %c d8                \`8, ,8'%c  ,8"     88  Y8,          ,8P  
  %c Y8,          8888  "8,8"  %c,8P      Y8  \`Y8,        ,8P'  
  %c \`Yba,,_____, \`8b,  ,d8b,%c_,dP       \`8b, \`Y8b,,__,,d8P'  
  %c   \`"Y8888888   "Y88P" "Y8%cP"         \`Y8   \`"Y8888P"'
   
      Check          In         And            Out    
  `,
      'color: blue; text-shadow: -1px -1px 0px white; font-weight: bold',
      'color: red',
      'color: blue; text-shadow: -1px -1px 0px white; font-weight: bold',
      'color: white',
      'color: blue; text-shadow: -1px -1px 0px white; font-weight: bold',
      'color: red',
      'color: blue; text-shadow: -1px -1px 0px white; font-weight: bold',
      'color: white',
      'color: blue; text-shadow: -1px -1px 0px white; font-weight: bold',
      'color: red',
      'color: white',
      'color: white',
      'color: red',
      'color: red',
      'color: white',
      'color: white',
      'color: red',
      'color: red',
      'color: white',
      'color: white'
    );
  }
}
