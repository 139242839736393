<div class="content">
  <h2 id="people-table-identifier" class="title">Users</h2>
  <div class="interactables-row">
    <span class="search-and-filter">
      <ciao-form-field
        label="Search"
        [formControlInput]="searchTextControl"
      ></ciao-form-field>
      <button
        *ngIf="true"
        class="btn-action-solid filter-btn"
        (click)="filterModal.openModal()"
      >
        <fa-icon [icon]="filter"></fa-icon> Filter
      </button>
    </span>

    <button
      class="new-item-button semantic-button"
      (click)="openUserModal(null)"
    >
      New User
      <span class="icon" role="presentation">
        <img src="assets/img/icons/new-item-button-icon.svg" alt="Go" />
      </span>
    </button>
  </div>
  <ciao-page-selector
    [displayRowCount]="true"
    [tableName]="'Users'"
    [paginationResult]="paginationResult$ | async"
  ></ciao-page-selector>
  <div class="scroll-x-on-overflow">
    <table
      ciao-table-style-v1
      [attr.aria-describedby]="'people-table-identifier'"
    >
      <thead>
        <tr>
          <th class="expander-column">
            <span class="screenreader">Click To Expand</span>
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'firstName'"
          >
            First Name
          </th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'lastName'"
          >
            Last Name
          </th>
          <th>Roles</th>
          <th
            ciaoSortableHeader
            [sortingBy$]="sortingBy$"
            [sortId]="'jobTitle'"
          >
            User Type
          </th>
          <th>Email</th>
        </tr>
      </thead>
      <tbody *ngIf="paginationResult$ | async as paginationResult">
        <ng-template [ngIf]="paginationResult.isLoading">
          <tr class="messageRow">
            <td [attr.colspan]="7">
              <ciao-loading></ciao-loading>
            </td>
          </tr>
        </ng-template>
        <ng-template
          ngFor
          let-person
          [ngForOf]="paginationResult?.rows"
          let-i="index"
          [ngForTrackById]
        >
          <tr (click)="openUserModal(person)">
            <td class="expander">
              <button (click)="toggleExpandedRows(person, 'toggle', $event)">
                <span class="material-icons" aria-hidden="true">
                  {{ isSelected(person) ? "expand_more" : "chevron_right" }}
                </span>
                <span class="screenreader">
                  {{ isSelected(person) ? "Collapse" : "Expand" }}
                </span>
              </button>
            </td>
            <td class="person-name">{{ person.firstName }}</td>
            <td class="person-name">{{ person.lastName }}</td>
            <td class="expander nowrap">
              <button (click)="toggleExpandedRows(person, 'toggle', $event)">
                {{ person.AssignedRoles?.length }} Role(s)
              </button>
            </td>
            <td>
              {{ person.jobTitle }}
            </td>
            <td class="email">
              <span *ngFor="let user of person.users">
                {{ user.email }}
              </span>
            </td>
          </tr>
          <ng-container *ngIf="isSelected(person)">
            <ng-template
              ngFor
              let-role
              [ngForOf]="person.AssignedRoles"
              let-roleIndex="index"
              [ngForTrackById]
            >
              <tr class="role-row">
                <td></td>
                <td class="user-group-label" colspan="2">
                  {{ role.userGroup?.labelPrefix }}{{ role.userGroup?.label }}
                </td>
                <td>{{ role.role?.label }}</td>
              </tr>
            </ng-template>
            <tr class="messageRow" *ngIf="!person.AssignedRoles">
              <td [attr.colspan]="7">
                <ciao-loading></ciao-loading>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </tbody>
    </table>
  </div>
  <ciao-page-selector
    class="pageSelector"
    (paginationDataChange)="pagingInfo$.next($event)"
    [tableName]="'People'"
    [paginationResult]="paginationResult$ | async"
    [filteredMessage]="paginationMessage"
  ></ciao-page-selector>
</div>

<ciao-user-modal #userModal [id]="personId"></ciao-user-modal>

<ciao-modal
  #filterModal
  id="filter-modal"
  [title]="'Filter By'"
  (result)="resetFilters()"
>
  <div modalBody *ngIf="filterModalInitialized">
    <!-- This div is temporarily kept in case we need to debug. -->
    <div
      *ngIf="false"
      style="height: 100px; resize: vertical; overflow-x: scroll"
    >
      <pre><code>{{getFilterDataObservableLogic() | async | json}}</code></pre>
    </div>
    <div id="roles-section">
      <h4 class="section-header">Roles</h4>
      <div class="control-container">
        <ciao-form-field
          class="control-item"
          label="System Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.system_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Region/Station Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.region_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Forest/Lab Admin"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.forest_admin')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          label="Team Supervisor"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.supervisor')"
        ></ciao-form-field>
        <ciao-form-field
          class="control-item"
          [label]="'Team Member'"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('roles.team_member')"
        ></ciao-form-field>
      </div>
    </div>
    <div id="filter-scroll-box">
      <hr />
      <div id="region-section" *ngIf="filterShouldShowRegionStation">
        <h4 class="section-header">Region or Station(s)</h4>
        <ciao-form-field
          class="control-item"
          label="All"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('allChecks.regions')"
        ></ciao-form-field>
        <div class="control-container">
          <ng-template
            ngFor
            ngForTrackById
            let-region
            [ngForOf]="filterRegionList$ | async"
          >
            <ciao-form-field
              class="control-item"
              [formControlInput]="filterModalGroup.get('regions.' + region.id)"
              [label]="region.label"
              type="checkbox"
            ></ciao-form-field>
          </ng-template>
        </div>
      </div>
      <div id="forest-section" *ngIf="filterShouldShowForestLab">
        <ng-template
          ngFor
          ngForTrackById
          let-region
          [ngForOf]="filterRegionList$ | async"
        >
          <ng-container *ngIf="filterModalGroup.value.regions[region.id]">
            <hr />
            <h4 class="section-header">{{ region.label }}</h4>
            <ciao-form-field
              class="control-item"
              label="All"
              type="checkbox"
              [formControlInput]="
                filterModalGroup.get(['allChecks', region.id])
              "
            ></ciao-form-field>
            <div class="control-container">
              <ng-template
                ngFor
                ngForTrackById
                let-forest
                [ngForOf]="region.children"
              >
                <ciao-form-field
                  class="control-item"
                  [formControlInput]="
                    filterModalGroup.get(['forests', forest.id])
                  "
                  [label]="forest.label"
                  type="checkbox"
                ></ciao-form-field>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div modalFooter>
    <div class="modal-footer-buttons">
      <button
        class="btn-action-light"
        (click)="clearFilters(); filterModal.close('')"
      >
        Clear Filters
      </button>
      <button
        class="btn-action-solid"
        (click)="applyFilters(); filterModal.close('')"
        [disabled]="!filterModalGroup?.dirty"
      >
        Apply Filters
      </button>
    </div>
  </div>
</ciao-modal>
