<div class="registration-page">
  <h1>Welcome to CIAO</h1>
  <div class="form-container mat-elevation-z4">
    <ng-container *ngIf="showPage === 'intro-question'">
      <p class="bold center">Do you have an existing CIAO account?</p>
      <div class="action-button-container center">
        <a class="btn-action-solid" [routerLink]="'/register/existing-user'">
          Yes
        </a>
        <a class="btn-action-solid" [routerLink]="'/register/new-user'"> No </a>
      </div>
    </ng-container>
    <ng-container *ngIf="showPage === 'existing-user'">
      <p>
        If you have an existing account,
        <span class="text-transform-uppercase text-decoration-underline bold">
          please do not register for a new CIAO account.
        </span>
      </p>
      <p>
        To add an additional email address to your existing account, review
        these steps:
      </p>
      <ol>
        <li>Select "Cancel Registration" to exit CIAO, then...</li>
        <li>Log in to CIAO with your existing account, and then...</li>
        <li>
          Add "<span class="bold">{{ loadedEmail || "example@gmail.com" }}</span
          >" to a blank email field in your user profile.
        </li>
      </ol>

      <img
        alt="Screenshot of the CIAO user profile page with two labeled fields for entering email addresses: one for the primary email and another for the secondary email. An arrow points to the secondary email field with a label reading 'Use blank email field,' indicating where the user should input an additional email address."
        src="/assets/help/img/add-secondary-email-demo.png"
        style="width: 100%; margin-bottom: 1em"
      />
      <p>
        Once completed, you will be able to authenticate into CIAO using both
        email accounts. For further assistance, please contact your supervisor
        or a CIAO admin.
      </p>

      <div class="action-button-container center">
        <button class="btn-action-light" (click)="logout()">
          Cancel Registration
        </button>
        <a class="btn-action-light" [routerLink]="'/register'"> Go Back </a>
      </div>
    </ng-container>
    <ng-container *ngIf="showPage === 'new-user'">
      <div id="details-form-wrapper">
        <ciao-user-details-form
          #detailsForm
          [personId]="null"
          [registrationContext]="true"
        ></ciao-user-details-form>
      </div>

      <div id="contact-form-wrapper">
        <ciao-user-contact-form
          #contactForm
          [personId]="null"
        ></ciao-user-contact-form>
      </div>
      <br />
      <div class="action-button-container">
        <button
          class="btn-action-solid register-button big"
          [disabled]="!formIsValid || isSaving"
          (click)="register()"
        >
          Create User Profile
        </button>
        <a class="btn-action-light big" [routerLink]="'/register'"> Cancel </a>
      </div>
    </ng-container>
  </div>
</div>
