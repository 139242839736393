<div class="content">
  <h2 id="teams-table-identifier" class="title">Teams</h2>
  <div class="interactables-row">
    <span class="search-and-filter">
      <ciao-form-field
        label="Search"
        [formControlInput]="searchTextControl"
      ></ciao-form-field>
      <button
        *ngIf="true"
        class="btn-action-solid filter-btn"
        (click)="filterModal.openModal()"
      >
        <fa-icon [icon]="filter"></fa-icon> Filter
      </button>
    </span>
    <button
      *ngIf="canCreateTeam$ | async"
      class="new-item-button semantic-button"
      (click)="openActionUserGroupModal({}, 'Add')"
    >
      New Team
      <span class="icon" role="presentation">
        <img src="assets/img/icons/new-item-button-icon.svg" alt="Go" />
      </span>
    </button>
  </div>
  <ciao-page-selector
    [displayRowCount]="true"
    [tableName]="'Teams'"
    [paginationResult]="paginationResult$ | async"
  ></ciao-page-selector>
  <div class="scroll-x-on-overflow">
    <table
      ciao-table-style-v1
      [attr.aria-describedby]="'teams-table-identifier'"
    >
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Region or Station</th>
          <th>Forest or Lab</th>
          <th>Contact</th>
          <th>Contact Email</th>
        </tr>
      </thead>
      <tbody *ngIf="paginationResult$ | async as paginationResult">
        <ng-template [ngIf]="paginationResult.isLoading">
          <tr class="messageRow">
            <td [attr.colspan]="6">
              <ciao-loading></ciao-loading>
            </td>
          </tr>
        </ng-template>
        <ng-template
          ngFor
          let-team
          [ngForOf]="paginationResult?.rows"
          let-i="index"
          [ngForTrackById]
        >
          <tr (click)="openActionUserGroupModal(team, 'Edit')">
            <td>{{ team.labelPrefix }}{{ team.label }}</td>
            <td>
              {{ team.regionStation?.label }}
            </td>
            <td>
              {{ team.forestLab?.label }}
            </td>
            <td>
              {{ team.pointOfContact }}
            </td>
            <td>
              {{ team.contactEmail }}
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
  <ciao-page-selector
    class="pageSelector"
    (paginationDataChange)="pagingInfo$.next($event)"
    [tableName]="'Teams'"
    [paginationResult]="paginationResult$ | async"
    [filteredMessage]="paginationMessage"
  ></ciao-page-selector>
</div>

<ciao-modal #actionUserGroupModal title="{{ modalTitle$ | async }}">
  <div modalBody>
    <span *ngIf="selectedTeamPermissions$ | async as teamPermissions">
      <ciao-user-group-form
        #actionUserGroupForm
        [isEditForm]="isEditForm"
        [canEditUserGroup]="teamPermissions.edit || !isEditForm"
        [canEditTeamMembers]="teamPermissions.assignTeamMember || !isEditForm"
        [canEditSupervisors]="teamPermissions.assignSupervisor || !isEditForm"
        [canViewTeamMembers]="teamPermissions.viewTeamMembers || !isEditForm"
      ></ciao-user-group-form>
    </span>
  </div>

  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="actionUserGroupModal.dismiss('cancel')"
      >
        Cancel
      </button>
      <button
        *ngIf="isEditForm == false; else editSaveButton"
        class="btn-action-solid center"
        (click)="saveData()"
        [disabled]="!actionUserGroupForm.allowSave"
      >
        Confirm
      </button>
      <ng-template #editSaveButton>
        <button
          *ngIf="(selectedTeamPermissions$ | async).clickUpdate"
          class="btn-action-solid center"
          (click)="saveData()"
          [disabled]="!actionUserGroupForm.allowSave"
        >
          Update
        </button>
        <button
          *ngIf="(selectedTeamPermissions$ | async).remove"
          class="btn-action-light center"
          (click)="deleteUserGroupModal.openModal()"
        >
          Delete
        </button>
      </ng-template>
    </div>
  </span>
</ciao-modal>

<ciao-modal #deleteUserGroupModal title="Confirm Team Deletion">
  <div modalBody>
    <h2>
      Are you sure you want to delete <br />
      {{ selectedData?.parent?.label }} - {{ selectedData?.label }}?
    </h2>
  </div>
  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="deleteUserGroupModal.dismiss('cancel')"
      >
        Cancel
      </button>
      <button class="btn-action-solid center" (click)="deleteData()">
        Confirm Delete
      </button>
    </div>
  </span>
</ciao-modal>

<ciao-modal
  #filterModal
  id="filter-modal"
  [title]="'Filter By'"
  (result)="resetFilters()"
>
  <div modalBody *ngIf="filterModalInitialized">
    <!-- <div id="roles-section">
      <div class="control-container">
        <ciao-form-field
          class="control-item"
          label="My User Groups"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('myUserGroups')"
        ></ciao-form-field>
      </div>
    </div>
      <hr /> -->
    <div id="filter-scroll-box">
      <div id="region-section">
        <h4 class="section-header">Region or Station(s)</h4>
        <ciao-form-field
          class="control-item"
          label="All"
          type="checkbox"
          [formControlInput]="filterModalGroup.get('allChecks.regions')"
        ></ciao-form-field>
        <div class="control-container">
          <ng-template
            ngFor
            ngForTrackById
            let-region
            [ngForOf]="filterRegionList$ | async"
          >
            <ciao-form-field
              class="control-item"
              [formControlInput]="filterModalGroup.get('regions.' + region.id)"
              [label]="region.label"
              type="checkbox"
            ></ciao-form-field>
          </ng-template>
        </div>
      </div>
      <div id="forest-section">
        <ng-template
          ngFor
          ngForTrackById
          let-region
          [ngForOf]="filterRegionList$ | async"
        >
          <ng-container *ngIf="filterModalGroup.value.regions[region.id]">
            <hr />
            <h4 class="section-header">{{ region.label }}</h4>
            <ciao-form-field
              class="control-item"
              label="All"
              type="checkbox"
              [formControlInput]="
                filterModalGroup.get(['allChecks', region.id])
              "
            ></ciao-form-field>
            <div class="control-container">
              <ng-template
                ngFor
                ngForTrackById
                let-forest
                [ngForOf]="region.children"
              >
                <ciao-form-field
                  class="control-item"
                  [formControlInput]="
                    filterModalGroup.get(['forests', forest.id])
                  "
                  [label]="forest.label"
                  type="checkbox"
                ></ciao-form-field>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div modalFooter>
    <div class="modal-footer-buttons">
      <button
        class="btn-action-light"
        (click)="clearFilters(); filterModal.close('')"
      >
        Clear Filters
      </button>
      <button
        class="btn-action-solid"
        (click)="applyFilters(); filterModal.close('')"
        [disabled]="!filterModalGroup?.dirty"
      >
        Apply Filters
      </button>
    </div>
  </div>
</ciao-modal>
