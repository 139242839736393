import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { repeatLatestWhen } from '~app/utilities/repeat-latest-when.operator';

import { SortingCriteria } from '~app/components/report-table/types';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { PaginationData, PaginationResult } from '~app/models/pagination-data';
import { ScheduledAction } from '~app/models/scheduled-action';
import { ApiService, TripService } from '~app/services';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';

@Component({
  selector: 'ciao-scheduled-action-dashboard',
  templateUrl: './scheduled-action-dashboard.component.html',
  styleUrls: ['./scheduled-action-dashboard.component.less'],
})
export class ScheduledActionDashboardComponent implements OnInit {
  @ViewChild('inspectionModal') inspectionModal: CiaoModalComponent;

  readonly endpointUrl = '/scheduled-actions';

  tripsPaginationOperator$: (
    input: Observable<PaginationData>
  ) => Observable<PaginationResult<ScheduledAction>>;

  refreshData$ = new Subject<void>();

  // Form Field
  filterControls = {
    searchText: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl(null),
  };
  filterGroup = new UntypedFormGroup(this.filterControls);

  teamSelectionOptions$: Observable<{ label: string; value: string }[]>;
  sortingBy$: BehaviorSubject<SortingCriteria<ScheduledAction>[]> =
    new BehaviorSubject([{ sortId: 'scheduledFor', order: 'DESC' }]);

  paginationModalControls = new UntypedFormGroup({
    limit: new UntypedFormControl(0),
    offset: new UntypedFormControl(0),
    order: new UntypedFormControl([]),
  });

  get paginationMessage() {
    return this.filterGroup.value.searchText
      ? `matching '${this.filterGroup.value.searchText}'`
      : '';
  }

  paginationResult$: Observable<PaginationResult<ScheduledAction>>;
  paginationData$: Observable<PaginationData>;

  subscriptions = new Subscription();

  focusedAction: ScheduledAction = null;

  filter = faFilter;

  constructor(
    private tripService: TripService,
    private apiService: ApiService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.paginationData$ = combineLatest([
      this.paginationModalControls.valueChanges.pipe(
        startWith(this.paginationModalControls.value)
      ),
      this.filterGroup.valueChanges.pipe(
        startWith(this.filterGroup.value),
        debounceTime(500)
      ),
      this.sortingBy$,
    ]).pipe(
      distinctUntilChanged((x, y) => _.isEqual(x, y)),
      repeatLatestWhen(this.refreshData$),
      map(([paginationFormData, filterFormData, sortingBy]) => {
        let where: [string, any][] = [];
        if (filterFormData.searchText) {
          where.push(['searchText', filterFormData.searchText]);
        }

        return {
          where,
          limit: paginationFormData.limit,
          offset: paginationFormData.offset,
          order: sortingBy.map((sort) => [sort.sortId, sort.order]) as [
            string,
            ('ASC' | 'DESC')?
          ][],
        };
      })
    );

    this.subscriptions.add(
      this.refreshData$
        .pipe(
          filter(() => !!this.focusedAction),
          switchMap(() =>
            this.apiService.Get<ScheduledAction>(
              this.endpointUrl,
              this.focusedAction.id
            )
          ),
          tap((refreshedAction) => (this.focusedAction = refreshedAction))
        )
        .subscribe()
    );

    this.paginationResult$ = this.paginationData$.pipe(
      switchMap((paginationData) =>
        this.apiService.Search<PaginationResult<ScheduledAction>>(
          this.endpointUrl,
          paginationData
        )
      ),
      shareReplay(1)
    );
  }

  focusOnRow(row: ScheduledAction) {
    this.focusedAction = row;
    this.inspectionModal.openModal();
  }
  scheduleForRetry(action: ScheduledAction) {
    this.subscriptions.add(
      this.apiService
        .Put<ScheduledAction>(this.endpointUrl, action.id, {
          status: 'scheduled',
          results: null,
          startedAt: null,
          completedAt: null,
        })
        .pipe(tap(() => this.refreshData$.next()))
        .subscribe()
    );
  }
  cancelAction(action: ScheduledAction) {
    let attr = {
      status: 'canceled',
      results: Object.assign(
        { adminMessage: 'Canceled By Admin.' },
        action.results
      ),
    };
    switch (action.status) {
      case 'canceled':
      case 'completed':
      case 'failed':
        // Do not change it.  It's already canceled.
        return;
      case 'scheduled':
        // attr is as above.
        break;
      case 'started':
        attr.status = 'failed';
        attr.results.adminMessage = 'Canceled by admin after starting.';
        break;
    }

    this.subscriptions.add(
      this.apiService
        .Put<ScheduledAction>(this.endpointUrl, action.id, attr)
        .pipe(tap(() => this.refreshData$.next()))
        .subscribe()
    );
  }
  debugAction(action) {
    this.subscriptions.add(
      this.apiService
        .Get(this.endpointUrl + '/debug-action', action.id)
        .subscribe()
    );
  }

  checkForActions() {
    this.subscriptions.add(
      this.apiService
        .Post<ScheduledAction>(this.endpointUrl + '/check-for-actions', null)
        .pipe(tap(() => this.refreshData$.next()))
        .subscribe()
    );
  }

  pickOutRelevantResultsLateTripSendMessage(action: ScheduledAction) {
    try {
      return action.results?.results?.map((res) => {
        let addressList = res.input?.MessageRequest?.Addresses;
        let address = addressList ? Object.keys(addressList)?.[0] : '!!!';
        return {
          address: address,
          status: res.status,
          attemptCount: res.attemptCount,
          errorMessage: res.error?.message,
          firstStatusCode:
            res.attempts?.[0]?.MessageResponse?.Result?.[address]?.StatusCode,
        };
      });
    } catch (err) {
      return ['Unknown Pattern'];
    }
  }

  clearFilters() {
    this.filterGroup.setValue({
      searchText: '',
    });
  }
  applyFilters() {}
}
