import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ciaoDate',
  standalone: true,
})
export class CiaoDatePipe implements PipeTransform {
  readonly;
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  extractDate(value: Date | string) {
    if (!value) {
      return null;
    }
    if (typeof value === 'string' && value.length === 10) {
      value += 'T00:00';
    }
    if (!(value instanceof Date)) {
      value = new Date(value);
    }

    return value;
  }

  transform(
    value: Date | string,
    parts: 'date' | 'time' | 'datetime',
    style: 'readable' | 'filter' | 'simple'
  ): string {
    let date = this.extractDate(value);
    if (!date) {
      return '';
    }
    if (isNaN(date.valueOf())) {
      console.error('Received Invalid Date:', JSON.stringify(value));
      return '';
    }

    let datePart: string;
    let timePart: string;
    let combiner: string = ' | ';

    switch (style) {
      case 'readable':
        datePart = date.toDateString();
        timePart = date.toLocaleTimeString(this._locale, {
          timeZoneName: 'short',
          hour12: this._locale === 'en-US',
          hour: '2-digit',
          minute: '2-digit',
        });
        break;
      case 'simple':
        datePart = date.toLocaleString(this._locale, {
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
        });
        timePart = date.toLocaleString(this._locale, {
          timeZoneName: 'short',
          hour12: this._locale === 'en-US',
          hour: '2-digit',
          minute: '2-digit',
        });
        break;
      case 'filter':
        datePart = date.toLocaleString(this._locale, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        });
        timePart = date.toLocaleString(this._locale, {
          hour12: this._locale === 'en-US',
          hour: '2-digit',
          minute: '2-digit',
        });
        combiner = ' ';
        break;
      default:
        console.error('Invalid Style');
        return 'Invalid DatePipe';
    }

    switch (parts) {
      case 'date':
        return datePart;
      case 'time':
        return timePart;
      case 'datetime':
        return datePart + combiner + timePart;
      default:
        console.error('Invalid Parts');
        return 'Invalid DatePipe';
    }
  }
}
