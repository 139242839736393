import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, AsyncSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CountyAttributes, StateAttributes } from '~app/models/';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private loaded = new AsyncSubject<boolean>();
  private _statesById: { [id: string]: StateAttributes };
  private _countiesById: { [id: string]: CountyAttributes };
  private _allStates: StateAttributes[];
  private _allCounties: CountyAttributes[];
  public allStates$ = this.loaded.pipe(map(() => this._allStates));
  public allCounties$ = this.loaded.pipe(map(() => this._allCounties));

  constructor(private apiService: ApiService) {
    this.loadAll();
  }

  findStateById(id: string): Observable<StateAttributes> {
    return this.loaded.pipe(map(() => this._statesById[id]));
  }
  findCountyById(id: string): Observable<CountyAttributes> {
    return this.loaded.pipe(map(() => this._countiesById[id]));
  }

  loadAll() {
    let statePipe = this.apiService.GetAll('states').pipe(
      tap((states: StateAttributes[]) => {
        this._allStates = states;
        this._statesById = {};
        states.forEach((state) => {
          this._statesById[state.id] = state;
          state.counties = [];
        });
      })
    );
    let countyPipe = this.apiService.GetAll('counties').pipe(
      tap((counties: CountyAttributes[]) => {
        counties.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
        this._allCounties = counties;
        this._countiesById = {};
        counties.forEach((county) => {
          this._countiesById[county.id] = county;
        });
      })
    );
    let togetherPipe = combineLatest([statePipe, countyPipe]).pipe(
      tap(() => {
        this._allCounties.forEach((county) => {
          let state = this._statesById[county.state.id];
          state.counties.push(county);
        });
      }),
      tap(() => {
        this.loaded.next(true);
        this.loaded.complete();
      })
    );
    togetherPipe.subscribe();
  }
}
