import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import {
  LatLong,
  LattitudeValidator,
  LongitudeValidator,
} from './lat-long.functions';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import * as _ from 'lodash-es';

@Component({
  selector: 'ciao-form-field-lat-long',
  templateUrl: './lat-long.component.html',
  styleUrls: ['./lat-long.component.less'],
  providers: [{ provide: MatFormFieldControl, useExisting: LatLongComponent }],
})
export class LatLongComponent
  implements
    OnInit,
    OnDestroy,
    OnChanges,
    ControlValueAccessor,
    MatFormFieldControl<LatLong>
{
  @ViewChild('lattitude') lattitudeInput: ElementRef<HTMLInputElement>;
  @ViewChild('longitude') longitudeInput: ElementRef<HTMLInputElement>;
  @Input('label') label: string;
  @Input('inputId') inputId: string;
  private _control: AbstractControl;
  get control() {
    // console.log('Get Control', this._control);
    return this._control;
  }
  set control(val: AbstractControl) {
    // console.log('Set Control', val);
    this._control = val;
  }
  // @Input() required: BooleanInput;

  lattitudeControl = new UntypedFormControl(null, [LattitudeValidator]);
  longitudeControl = new UntypedFormControl(null, [LongitudeValidator]);
  formGroup = new UntypedFormGroup(
    {
      lattitude: this.lattitudeControl,
      longitude: this.longitudeControl,
    },
    [this.validator]
  );

  subscriptions = new Subscription();
  constructor(
    formBuilder: UntypedFormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // console.log(ngControl);
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngOnInit(): void {
    this.initialize();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.initialize();
  }

  // Control Value Accessor
  writeValue(obj: any): void {
    // console.log('writeValue', obj);
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    // console.log(fn);
  }
  registerOnTouched(fn: any): void {
    // console.log(fn);
  }
  validator(group: UntypedFormGroup) {
    const lat = group.controls.lattitude;
    const lon = group.controls.longitude;
    if (!lat || !lon) {
      return { invalidControls: 'Invalid Controls' };
    }
    if (lat.errors || lon.errors) {
      return Object.assign({}, lat.errors, lon.errors);
    } else if (!lat.value !== !lon.value) {
      // if one control value is empty, but the other control is not empty.
      return { incomplete: 'Lat/Long Pair is incomplete.' };
    } else {
      return null;
    }
  }
  // get errors() {
  //   return this.validator();
  // }

  //#region MatFormFieldControl
  onContainerClick(event: MouseEvent): void {
    // console.log(event);
  }
  setDescribedByIds(ids: string[]): void {
    // console.log(ids);
  }

  get value(): LatLong {
    // console.log('Get Value');
    if (this.lattitudeControl.value || this.longitudeControl.value) {
      return this.formGroup.value;
    } else {
      return null;
    }
  }
  set value(val: LatLong) {
    this.formGroup.setValue({
      lattitude: val?.lattitude ?? null,
      longitude: val?.longitude ?? null,
    });
    this.stateChanges.next();
  }
  stateChanges = new Subject<void>();
  id: string;
  placeholder: string;
  get focused() {
    let active = document.activeElement;
    return (
      active === this.lattitudeInput?.nativeElement ||
      active === this.longitudeInput?.nativeElement
    );
  }
  get empty() {
    return (
      this.lattitudeControl.value === null &&
      this.longitudeControl.value === null
    );
  }
  shouldLabelFloat: boolean;
  _required: boolean;
  @Input() set required(val: BooleanInput) {
    this._required = coerceBooleanProperty(val);
  }
  get required(): boolean {
    return this._required;
  }
  _disabled: boolean;
  @Input() set disabled(val: BooleanInput) {
    this._disabled = coerceBooleanProperty(val);
  }
  get disabled(): boolean {
    return this._disabled;
  }
  get errorState() {
    return this.formGroup.errors != null;
  }
  readonly controlType = 'lat-long';
  userAriaDescribedBy?: string;

  //#endregion

  initialize() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.lattitudeControl.setValidators(LattitudeValidator);
    this.longitudeControl.setValidators(LongitudeValidator);
    if (this.required) {
      this.lattitudeControl.addValidators(Validators.required);
      this.longitudeControl.addValidators(Validators.required);
    }
    this.ngControl.control.addValidators(() => this.formGroup.errors);

    this.subscriptions.add(
      this.formGroup.valueChanges
        .pipe(
          map((value) => {
            if (value.lattitude || value.longitude) {
              return value;
            } else {
              return null;
            }
          }),
          filter((value) => !_.isEqual(value, this.ngControl.control.value)),
          tap((value) => this.ngControl.control.setValue(value))
        )
        .subscribe()
    );
    this.subscriptions.add(
      this.formGroup.statusChanges
        .pipe(
          tap(() => {
            let outerControl = this.ngControl.control;
            this.formGroup.dirty
              ? outerControl.markAsDirty()
              : outerControl.markAsPristine();
            this.formGroup.touched
              ? outerControl.markAsTouched()
              : outerControl.markAsUntouched();
          })
        )
        .subscribe()
    );
  }
}
