import { Directive, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldComponent } from '~app/components/shared/forms/field/field.component';
import { LocationService, PersonService } from '~app/services';

@Directive({
  selector:
    'ciao-form-field[subtype][type=dropdown], ciao-form-field[subtype][type=combobox]',
})
export class DropdownSubtypeDirective implements OnInit {
  @Input('subtype') subtype: string;
  @Input() selectOptions$: Observable<SelectOption[]>;
  constructor(
    private host: FieldComponent,
    private personService: PersonService,
    private locationService: LocationService
  ) {}
  ngOnInit() {
    switch (this.subtype) {
      case 'people':
        this.host.selectOptions$ = this.personService.allPeople.pipe(
          map((people) =>
            people.map((person) => ({
              value: person,
              label: person.displayNameDropdown,
            }))
          )
        );
        break;
      case 'countyCodes':
        this.host.selectOptions$ = this.locationService.allCounties$.pipe(
          map((counties) =>
            counties.map((county) => ({
              value: county.id,
              label: county.id,
            }))
          )
        );
        break;
      default:
        break;
    }
  }
}

type SelectOption =
  | string
  | {
      label: string;
      value: string | Object;
    };
