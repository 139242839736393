<h3 class="modal-form-title">
  {{ editingExisting ? "Update" : "Add" }} Crew Member
</h3>

<div class="row">
  <ciao-form-field
    class="form-field col-lg-6 col-sm-12"
    type="combobox"
    label="Crew Member"
    [formControlInput]="formGroup.get('crewMember')"
    [selectOptions$]="crewMemberOptions$"
    [compareWith]="comparePersonForSelect"
  >
  </ciao-form-field>

  <ciao-form-field
    class="member-info col-lg-6 col-sm-12"
    subtype="fsCellPhones"
    label="Cell Phone"
    type="phoneNumber"
    [formControlInput]="formGroup.get('fsCellPhone')"
  ></ciao-form-field>
</div>

<div class="row">
  <ciao-form-field
    class="form-field col-lg-6 col-sm-12"
    type="combobox"
    label="Supervisor"
    placeholder="Supervisor/Admin/Dispatcher"
    toolTip="
      Cannot be a Crew Member on the Trip. This person will initiate safety protocols in the event of an emergency for this crew member. Can be a dispatcher, a lead, or safety manager who has been designated by Admin.
    "
    [toolTipTitle]="'Supervisor/Admin/Dispatcher'"
    [formControlInput]="formGroup.get('supervisor')"
    [selectOptions$]="supervisorOptions$"
    [compareWith]="comparePersonForSelect"
  >
  </ciao-form-field>

  <ciao-form-field
    class="member-info col-lg-6 col-sm-12"
    subtype="fsCellPhones"
    label="Cell Phone"
    placeholder="For text notifications"
    type="phoneNumber"
    [formControlInput]="formGroup.get('supervisorPhone')"
  ></ciao-form-field>
</div>

<div class="text-alert-accordion">
  <button class="text-alert-title" (click)="helpTextActive = !helpTextActive">
    <fa-icon [icon]="helpTextActive ? faChevronDown : faChevronRight"></fa-icon>
    About Text Alerts
  </button>
  <span [ngClass]="{ active: helpTextActive }" class="text-alert-info"
    >Crew Members and Team Supervisors can choose to Receive Text Notifications
    by opting-in on their User Profile. Users will only receive alerts at the
    cell number entered in the profile. Text alerts will be sent 2 minutes after
    the Trip end time, if the Trip has not been Checked-In.
  </span>
</div>

<div class="row">
  <ciao-form-field
    class="col-lg-6 col-sm-12"
    subtype="fsCellPhones"
    label="Satellite Phone"
    type="satPhoneNumber"
    [formControlInput]="formGroup.get('satPhone')"
    placeholder="15 Digit Number"
  ></ciao-form-field>

  <ciao-form-field
    class="col-lg-6 col-sm-12"
    label="Satellite Communicator"
    type="input"
    [toolTip]="
      'This field is not required but highly suggested if details are known for a Spot, Garmin and/or other GPS tracking device.'
    "
    [formControlInput]="formGroup.get('satLocatorId')"
    placeholder="Tracker Device"
  ></ciao-form-field>
</div>

<div class="row">
  <ciao-form-field
    label="Other Notes"
    type="textarea"
    [toolTip]="
      'Include any additional known information that will assist in establishing location or contact for crew members.'
    "
    [formControlInput]="formGroup.get('notes')"
    placeholder="Add radio or other communication devices"
  ></ciao-form-field>
</div>
