export const mainFaqMap: {
  title: string;
  questions: {
    slug: string;
    question: string;
    answer: any;
    isExpanded: boolean;
  }[];
}[] = [
  {
    title: 'General Info',
    questions: [
      {
        slug: 'ciao-to-me',
        question: 'What does CIAO mean to me?',
        answer: `The goal of CIAO is to safeguard our greatest asset: YOU. CIAO is a check-in and check-out tool for all Forest Service (FS) personnel, ensuring that everyone stays safe and connected while they are in the field or away from the office on FS business.`,
        isExpanded: false,
      },
      {
        slug: 'ciao-features',
        question: `Why aren't all the features I want in the app?`,
        answer: `CIAO's team is continuously striving to improve the application and incorporate additional features. We are conducting user testing to develop a list of features that we will roll out in future versions of the CIAO application.`,
        isExpanded: false,
      },
      {
        slug: 'ciao-notification-system',
        question:
          'How does this fit in with the Emergency notification System that is being developed?',
        answer: `The ENS is managed by the Physical Security Office and is a separate product from CIAO.`,
        isExpanded: false,
      },
      {
        slug: 'ciao-kbas',
        question:
          'Where can I watch videos or see detailed guides and Knowledge Based Articles (KBAs)?',
        answer: `Visit the [CIAO How To Guides](https://ciao.fs2c.usda.gov/ciao/help/info/how-to). For admins, visit the [admin help page](https://ciao.fs2c.usda.gov/ciao/help/info/admin). Videos appear at the top of the page and KBA links appear under the videos.`,
        isExpanded: false,
      },
    ],
  },
  {
    title: 'Users',
    questions: [
      // {
      //   slug: '',
      //   question: 'How do I turn on text notifications?',
      //   answer: `Trip crew members and supervisors can opt-in or opt-out of receiving text alerts for notification of late check-in's. Select your user name in the upper right of the CIAO header to open your user profile. Enter the cell phone number to which you would like to receive text notifications. Then, select the toggle button beneath the cell phone field and click Save to submit the changes.`,
      //   isExpanded: false,
      // },
      {
        slug: 'create-ciao-user',
        question: 'How do I create a CIAO user account?',
        answer:
          'Navigate to https://ciao.fs2c.usda.gov/ciao/ in your web browser. You will be routed to authenticate with one of our approved USDA authentication services. Once authenticated, CIAO will ask if you would like to register as a user. Enter your information into your User Profile to register.',
        isExpanded: false,
      },
      {
        slug: 'ciao-next-steps',
        question: "I'm registered as a CIAO user. What's next?",
        answer: `Welcome to CIAO. To create [trips](https://ciao.fs2c.usda.gov/ciao/search) and Check Out, users need to be added to a CIAO team. Please view a list of existing teams on the teams page or contact your local safety manager to find out more about CIAO [teams](https://ciao.fs2c.usda.gov/ciao/teams) in your organization.`,
        isExpanded: false,
      },
      {
        slug: 'non-usda-team-members',
        question: 'Can team members without a USDA account use CIAO?',
        answer: `Yes. Users without a USDA account can use their existing Login.gov account for authentication. If you do not have a Login.gov account, create a new account at: [https://login.gov/create-an-account/](https://login.gov/create-an-account/).`,
        isExpanded: false,
      },
      {
        slug: 'non-gov-login',
        question: 'How do I log into CIAO from a non-Government device?',
        answer:
          'Users can log into CIAO from a non-government device with Login.gov. To create a Login.gov account go to: https://login.gov/create-an-account/.',
        isExpanded: false,
      },
      {
        slug: 'multi-device-login',
        question:
          'Can I log into my CIAO user account from multiple devices, like a FS Laptop and a personal device?',
        answer:
          'Yes. Please add your Login.gov non-government email address to your user profile in CIAO _before attempting to log into CIAO_ for the first time from a personal device with Login.gov. If you do not add your Login.gov account to your user profile first, you will be asked to create a separate CIAO user account.',
        isExpanded: false,
      },
      {
        slug: 'linking-login.gov',
        question: 'How do I add my Login.gov account to a CIAO user profile?',
        answer:
          'As a CIAO user, navigate to your profile. Please add your Login.gov non-government email address _before you log into CIAO_ with a personal device. Users will need to use a USDA.gov email address for eAuthentication services (like PIV and MobileLinc) and a personal email address for Login.gov.',
        isExpanded: false,
      },
      {
        slug: 'new-user-notifications',
        question:
          'As a new user, will I receive email notifications from CIAO?',
        answer: `As of CIAO version 1.11, new registered users will receive a Welcome to CIAO email with information about how to get started, find resources, and how to reach the development team with feedback.`,

        isExpanded: false,
      },
      {
        slug: 'volunteer-login',
        question: 'How do I help a Volunteer log into CIAO?',
        answer: `As a volunteer, you must create a [Login.gov](https://login.gov/) account using a non-government email address before you can log into CIAO. Once you've registered with Login.gov, navigate to https://ciao.fs2c.usda.gov/ciao/ in your web browser. You will be routed to eAuth, our authentication service. Once authenticated with eAuth using your Login.gov credentials, you will be returned to CIAO. If you are a new user, CIAO will ask if you would like to register as a user. Enter your information into your User Profile to register. Download a one-page [Volunteer Log In Guide](/assets/help/pdfs/Volunteer_Log_In_Guide_v1.pdf) to share with other volunteer team members.`,

        isExpanded: false,
      },
    ],
  },
  {
    title: 'Trips',
    questions: [
      {
        slug: 'ciao-trips',
        question: 'What is a trip?',
        answer: `Trips are used to keep track of Forest Service employee outings. A trip is created whenever someone goes out into the field to work. The trip will keep track of which crew members are going out, how long they plan to be gone, and when they plan to return. Other information, such as the planned location of the trip, the equipment being used, and any additional notes the crew wants to leave, is also included. Trips also have the ability to send SMS notifications to the designated supervisors on the trip when crew members fail to check in at the end of the planned return date/time.`,
        isExpanded: false,
      },
      {
        slug: 'trip-duration',
        question: 'How long is a trip available on CIAO?',
        answer: `Trips will remain in the team's trip list for 30 days after the trip's return date/time.`,
        isExpanded: false,
      },
      // {
      // slug: '',
      //   question: 'How do I create a trip?',
      //   answer: `Trips can be created by navigating to the Home page and clicking "New trip" or by navigating to the trips page and clicking "New trip". A pop-up should expand and provide you with all the forms you need to create a trip.`,
      //   isExpanded: false,
      // },
      {
        slug: 'late-trip-contacts',
        question:
          'Who will receive a text notification if my trip is late and when will they receive it?',
        answer: `All CIAO users named on a trip, including team supervisors who have opted in on their user profile to receive certain text notifications, will receive automated system-generated texts according to the options they select in their user profile.`,
        isExpanded: false,
      },
      {
        slug: 'trip-creation',
        question: 'Who can create a trip?',
        answer: `Trips can be created by any User. Users must be a part of a team, and the team must have at least one supervisor.`,
        isExpanded: false,
      },
      {
        slug: 'supervisor-checkout-emails',
        question:
          'As a Supervisor, will I receive Trip Check Out email notifications from CIAO?',
        answer: `As of CIAO version 1.12, supervisors will receive email notifications when a team checks out for a trip. The notification will only be sent to .GOV email accounts and not to personal email accounts. Emails are confidential and cannot be forwarded to personal email or personal devices. Transmitting screenshots to personal devices is prohibited. Check Out notifications for supervisors cannot be opted-out at this time.`,

        isExpanded: false,
      },
      {
        slug: 'individual-crew-checkin',
        question: `Can an individual check-in separately if they return safely at a different time than the rest of the trip's crew?`,
        answer: `Yes. Individuals can check-in separately from the rest of the crew and prior to closing the trip. From the check-in window, confirm the trip, time of check-in, and indicate yourself and/or the individual(s) checking in. You can still check-in all individuals on the trip at the same time. Note: At this time the Crew Check-in link received in the Successful Check Out email only checks in the entire crew and closes the trip.`,

        isExpanded: false,
      },
    ],
  },
  {
    title: 'Teams',
    questions: [
      {
        slug: 'ciao-teams',
        question: 'What is a team?',
        answer:
          'A team is a unit of FS employees comprised of supervisors and team members. Teams exist under Forests/Labs which all belong to a specific Region/Station.',
        isExpanded: false,
      },
      {
        slug: 'team-structure',
        question:
          'How are teams set up within Regions/Stations and Forests/Labs?',
        answer: `Teams are created in a tree-like structure, with Regions/Stations at the highest level. Each Region/Station has a predefined list of Forests/Labs, under which teams are created. Every team belongs to a Region/Station and Forest/Lab.`,
        isExpanded: false,
      },
    ],
  },
];
