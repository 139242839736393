import { Directive, OnInit, ElementRef } from '@angular/core';

@Directive({
  selector: 'table:not([complexHeaders])',
})
export class TableAdjustmentsDirective implements OnInit {
  constructor(private hostElement: ElementRef<HTMLTableElement>) {}

  ngOnInit() {
    const nativeTable = this.hostElement.nativeElement as HTMLTableElement;
    const tHeaders = nativeTable.querySelectorAll<HTMLTableCellElement>(
      'thead tr:first-child th'
    );
    tHeaders.forEach((th) => (th.scope = 'col'));
  }
}
