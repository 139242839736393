import { formatDate } from '@angular/common';
import { Directive, forwardRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[ciaoDateTimeCustomAccessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeCustomAccessorDirective),
      multi: true,
    },
  ],
})
export class DateTimeCustomAccessorDirective extends DefaultValueAccessor {
  override writeValue(modelValue: string): void {
    let viewValue = this.modelToView(modelValue);
    super.writeValue(viewValue);
  }
  override registerOnChange(fn: (viewValue: string) => {}): void {
    let fn2 = (viewValue: string) => {
      let modelValue = this.viewToModel(viewValue);
      return fn(modelValue);
    };
    super.registerOnChange(fn2);
  }

  // Custom Functions:
  modelToView(isoStringValue: string) {
    if (!isoStringValue) {
      return null;
    }
    let date = new Date(isoStringValue ?? '');
    return isNaN(date.valueOf())
      ? ''
      : formatDate(date, 'yyyy-MM-ddTHH:mm', 'en-US');
  }
  viewToModel(formattedDate: string) {
    let date = new Date(formattedDate);
    return isNaN(date.valueOf()) ? '' : date.toISOString();
  }
}
