<h3 class="modal-form-title">
  {{ formTitle }}
</h3>
<div class="row">
  <div
    class="status-group col"
    *ngIf="tripStatusControl.value"
    style="flex-grow: 0"
  >
    <mat-label class="toolTipLabel">
      <div class="field-title">Trip Status</div>
      <ciao-tool-tip
        class="tool-tip"
        [title]="'Trip Status'"
        [message]="
          'Field will update based on user actions: creating a trip, checking in, checking out, or being marked as okay.'
        "
      ></ciao-tool-tip>
    </mat-label>
    <div
      id="status"
      class="status-tile status-{{ tripStatusControl.value || '' }}"
    >
      {{ tripStatusControl.value }}
    </div>
  </div>

  <ciao-form-field
    class="col"
    label="Team Name"
    type="dropdown"
    [formControlInput]="formGroup.get('userGroupId')"
    [selectOptions$]="userGroupOptions$"
  >
  </ciao-form-field>
</div>

<div class="date-time-fields row">
  <ciao-form-field
    label="Start Date/Time"
    type="datetime"
    class="custom-date col"
    [formControlInput]="formGroup.get('startDate')"
  ></ciao-form-field>
  <ciao-form-field
    label="Return Date/Time"
    type="datetime"
    class="custom-date col"
    [formControlInput]="formGroup.get('endDate')"
  ></ciao-form-field>
</div>
