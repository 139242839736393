<ng-container *ngIf="displayRowCount; else pageSelector">
  <div class="display-row-count">{{ totalRowCount }} {{ tableName }} found</div>
</ng-container>
<ng-template #pageSelector class="pageSelector">
  <div class="total-records" *ngIf="isLoading">Loading...</div>
  <div class="results-per-page">
    <label>
      Rows per page:
      <select [formControl]="rowsPerPage" (change)="calculatePages()">
        <ng-template ngFor let-rowsPerPage [ngForOf]="rowNumbers">
          <option [ngValue]="rowsPerPage">
            {{ rowsPerPage }}
          </option>
        </ng-template>
      </select>
    </label>
  </div>
  <div class="paging" *ngIf="pageCount > 1">
    <div class="select-div">
      <label [title]=""
        >Page
        <input
          class="page-select"
          [formControl]="pageControl"
          (change)="calculatePages()"
          type="number"
        />
        <span class="screenreader"> Page {{ pageControl.value }} </span>
        of {{ pageCount }}
      </label>
    </div>
    <button
      aria-label="Previous Page"
      class="icon borderless"
      (click)="goToPage(pageControl.value - 1)"
    >
      <fa-icon [icon]="faArrowLeft"></fa-icon>
    </button>

    <button
      aria-label="Next Page"
      class="icon borderless"
      (click)="goToPage(pageControl.value + 1)"
    >
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
</ng-template>
