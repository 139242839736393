import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TripModalFormComponent } from '../types';
import { LocationService } from '~app/services';
import {
  CountyAttributes,
  PlotAttributes,
  ResourceAttributes,
  StateAttributes,
} from '~app/models';
import { Observable, Subscription, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { FormsUtility } from '~app/utilities/forms.utility';

@Component({
  selector: 'ciao-locations-form',
  templateUrl: './locations-form.component.html',
  styleUrls: ['./locations-form.component.less'],
})
export class LocationsFormComponent
  implements OnInit, OnDestroy, TripModalFormComponent
{
  @Input() editingExisting = false;
  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    description: new UntypedFormControl('', [
      Validators.required,
      FormsUtility.isOverCharLimit,
    ]),
    state: new UntypedFormControl(''),
    county: new UntypedFormControl(''),
    countyCode: new UntypedFormControl(null),
    plotNumber: new UntypedFormControl(null),
    coords: new UntypedFormControl(null, FormsUtility.isOverCharLimit),
    textCoords: new UntypedFormControl(null, FormsUtility.isOverCharLimit),
  });
  get finalResult(): PlotAttributes {
    return this.formGroup.value;
  }

  countyCodesAutocomplete$: Observable<string[]>;
  stateOptions$: Observable<{ value: StateAttributes; label: string }[]>;
  countyOptions$: Observable<{ value: CountyAttributes; label: string }[]>;

  subscriptions = new Subscription();
  constructor(private locationService: LocationService) {}

  ngOnInit(): void {
    this.setupLocationData();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  compareWithId(a: ResourceAttributes, b: ResourceAttributes) {
    return a?.id === b?.id;
  }

  setupLocationData() {
    this.countyCodesAutocomplete$ = this.locationService.allCounties$.pipe(
      map((counties) => counties.map((county) => county.id))
    );
    let countyCodeControl = this.formGroup.get('countyCode');
    let stateControl = this.formGroup.get('state');
    let countyControl = this.formGroup.get('county');

    this.subscriptions.add(
      stateControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(
            (input) =>
              input && stateControl.valid && input?.counties?.length > 0
          ),
          map((state) => {
            return state?.counties[0];
          }),
          tap((county) => {
            countyControl.setValue(county);
          }),
          tap((county) => {
            countyCodeControl.setValue(county.id);
          })
        )
        .subscribe()
    );
    this.subscriptions.add(
      countyCodeControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter((input) => input && countyCodeControl.valid),
          switchMap((input) => {
            return this.locationService.findCountyById(input);
          }),
          filter((county) => Boolean(county)),
          tap((county) => stateControl.setValue(county.state)),
          tap((county) => countyControl.setValue(county))
        )
        .subscribe()
    );
    this.subscriptions.add(
      countyControl.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter((input) => input && countyControl.valid),
          filter(
            (county: CountyAttributes) => county.id !== countyCodeControl.value
          ),
          tap((county) => countyCodeControl.setValue(county.id)),
          tap((county) => stateControl.setValue(county.state))
        )
        .subscribe()
    );
    this.stateOptions$ = this.locationService.allStates$.pipe(
      map((states) =>
        states.map((state) => ({ value: state, label: state.stateCode }))
      )
    );
    this.countyOptions$ = stateControl.valueChanges.pipe(
      map((state: StateAttributes) => state?.counties),
      startWith([]),
      switchMap((counties) =>
        counties?.length ? of(counties) : this.locationService.allCounties$
      ),
      map((counties) =>
        counties.map((county) => ({
          value: county,
          label: `${county.countyName}, ${county.state.stateCode}`,
        }))
      ),
      share()
    );
  }
}
