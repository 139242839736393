import {
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { SelectOption, SelectOptionLowercase } from './types';

@Directive({
  exportAs: 'customAutocomplete',
  selector: '[ciaoMatAutocompleteCustomAccessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatAutocompleteCustomAccessorDirective),
      multi: true,
    },
  ],
})
export class MatAutocompleteCustomAccessorDirective<T>
  extends MatAutocompleteTrigger
  implements OnInit, OnDestroy
{
  readonly setModelValue$ = new BehaviorSubject<T>(null);
  readonly setViewValue$ = new BehaviorSubject<string | SelectOption<T>>('');
  subscription: Subscription;

  @Input('ciaoMatAutocompleteCustomAccessor')
  override autocomplete: MatAutocomplete;

  @Input() compareWith = (a: T, b: T) => a === b;
  @Input() options$: Observable<SelectOption<T>[]>;
  filteredOptions$: Observable<SelectOption<T>[]>;

  ngOnInit() {
    this.reinitialize();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options$) {
      this.reinitialize();
    }
  }

  // constructor() { }
  override writeValue(modelValue: T) {
    this.setModelValue$.next(modelValue);
  }

  override registerOnChange(fn: (value: any) => {}): void {
    let fn2 = (viewValue: any) => {
      this.setViewValue$.next(viewValue);
      return fn(viewValue.value);
    };
    super.registerOnChange(fn2);
  }

  displayWith(item: { value: string; label: string }) {
    return item?.label;
  }

  @HostListener('focusout') onBlurHandler() {
    // If the user types in the value and tabs off then fill the field value with the corresponding dropdown value
    // This only applys if there's only one option in the list
    this.filteredOptions$
      .pipe(
        take(1),
        // delay needed to make sure that clicking on the option doesn't break things
        delay(100),
        tap((options) => {
          if (options.length === 1) {
            this._onChange(options[0]);
          }
        })
      )
      .subscribe();
  }

  findOptionForModelValue(options: SelectOption<T>[], modelValue: T) {
    return options.find((option) => this.compareWith(option.value, modelValue));
  }

  reinitialize() {
    this.subscription?.unsubscribe();
    this.subscription = this.handleWriteChanges().subscribe();
    this.setupCustomFilter();
  }

  handleWriteChanges() {
    if (!this.options$) {
      throw new Error('Cannot find autocomplete options');
    }
    return combineLatest([this.options$, this.setModelValue$]).pipe(
      tap(([options, value]) => {
        let option = this.findOptionForModelValue(options, value);
        super.writeValue(option);
        if (value && !option && options.length > 0) {
          this.setModelValue$.next(null);
        }
      })
    );
  }
  setupCustomFilter() {
    const lowercaseOptions$: Observable<SelectOptionLowercase<T>[]> =
      this.options$.pipe(
        map((options) =>
          options.map((opt) => ({ lowercase: opt.label.toLowerCase(), ...opt }))
        ),
        shareReplay(1)
      );
    const searchTerm$ = this.setViewValue$.pipe(
      map((value) => (typeof value === 'string' ? value.toLowerCase() : '')),
      // If an option is selected, show full list again.
      distinctUntilChanged()
    );
    this.filteredOptions$ = combineLatest([
      searchTerm$,
      lowercaseOptions$,
    ]).pipe(
      map(([searchTerm, allOptions]) => {
        return this.searchMultipleTerms(searchTerm, allOptions);
      }),
      startWith([{ disabled: true, label: 'Loading', value: null }]),
      shareReplay(1)
    );
  }

  searchMultipleTerms(
    searchString: string,
    list: SelectOptionLowercase<T>[]
  ): SelectOptionLowercase<T>[] {
    const searchTerms = searchString.match(/\w+/g);
    if (!searchTerms) {
      return list;
    }
    // Return all items where all search terms can be found in the lowercase version of the label.
    return list.filter((item) => {
      const missingTerm = searchTerms.find(
        (term) => !item.lowercase.includes(term)
      );
      return !missingTerm;
    });
  }
}
