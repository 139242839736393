<span *ngIf="userGroupFormGroup.dirty">You have Unsaved Changes</span>
<form *ngIf="canEditUserGroup || !isEditForm; else viewOnly">
  <span class="pull-right color-red font-size-3xs"> * Required Field </span>
  <ciao-form-field
    type="text"
    label="Team Name"
    [formControlInput]="userGroupControls.label"
  ></ciao-form-field>
  <ciao-form-field
    type="dropdown"
    label="Region or Station"
    [formControlInput]="userGroupControls.regionStation"
    [selectOptions$]="allRegionsOptions$"
  ></ciao-form-field>
  <ciao-form-field
    type="dropdown"
    label="Forest or Lab"
    [formControlInput]="userGroupControls.forestLab"
    [selectOptions$]="allForestLabOptions$"
  ></ciao-form-field>
  <ciao-form-field
    type="text"
    label="Point of Contact"
    [formControlInput]="userGroupControls.pointOfContact"
  ></ciao-form-field>
  <ciao-form-field
    type="text"
    label="Contact Email"
    [formControlInput]="userGroupControls.contactEmail"
  ></ciao-form-field>
  <ciao-form-field
    *ngIf="false"
    type="text"
    label="Description"
    [formControlInput]="userGroupControls.description"
  ></ciao-form-field>
</form>
<ng-template #viewOnly>
  <dl>
    <dt>Team Name</dt>
    <dd>{{ userGroupFormGroup.value.label }}</dd>
    <dt>Region or Station</dt>
    <dd>{{ regionStation?.labelPrefix }}{{ regionStation?.label }}</dd>
    <dt>Forest or Lab</dt>
    <dd>{{ forestLab?.labelPrefix }}{{ forestLab?.label }}</dd>
    <dt>Point of Contact</dt>
    <dd>{{ userGroupFormGroup.value.pointOfContact }}</dd>
  </dl>
</ng-template>

<ng-container *ngIf="!canEditSupervisors">
  <span class="label">Supervisors/Admin/Dispatchers</span>
  <br />
</ng-container>

<ciao-form-field
  *ngIf="canEditSupervisors"
  label="Supervisors/Admins/Dispatchers"
  placeholder="Add Supervisor/Admin/Dispatcher"
  type="combobox"
  subtype="people"
  [formControlInput]="membersControls.nextSupervisor"
  [requiredProxy]="true"
  toolTip="Required: Select at least one (1) Supervisor who is not the sole Team Member.  If there is only one Supervisor, they cannot also be a Team Member."
  toolTipTitle="Description"
></ciao-form-field>
<span *ngFor="let person of membersControls.listSupervisor.value">
  <button
    *ngIf="canEditSupervisors"
    (click)="removePersonFromList(person, membersControls.listSupervisor)"
    class="delete-button"
    aria-label="Remove Person From List"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  {{ person.displayName }} <br />
</span>
<ng-container *ngIf="canViewTeamMembers">
  <ng-container *ngIf="!canEditTeamMembers">
    <span class="label">Team Members</span>
    <br />
  </ng-container>
  <ciao-form-field
    *ngIf="canEditTeamMembers"
    label="Team Members"
    placeholder="Add Team Member"
    type="combobox"
    subtype="people"
    [formControlInput]="membersControls.nextTeamMember"
    [requiredProxy]="true"
    toolTip="Required: Select at least one (1) Team Member. Team Members can also serve as Supervisors if there is more than one member on the team."
    toolTipTitle="Description"
  ></ciao-form-field>
  <span *ngFor="let person of membersControls.listTeamMember.value">
    <button
      *ngIf="canEditTeamMembers"
      (click)="removePersonFromList(person, membersControls.listTeamMember)"
      class="delete-button"
      aria-label="Remove Person From List"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    {{ person.displayName }} <br />
  </span>
</ng-container>

<ciao-modal #confirmLeaveModal title="Unsaved Changes">
  <span modalBody>
    You have unsaved changes. Are you sure you want to abandon them?
  </span>
  <span modalFooter>
    <button class="btn" (click)="confirmLeaveModal.dismiss('cancel')">
      Cancel
    </button>
    <button class="btn-danger" (click)="confirmLeaveModal.close('confirm')">
      Confirm
    </button>
  </span>
</ciao-modal>
