<fieldset class="checkbox-form-group">
  <legend>{{ label ?? "Group Options" }}</legend>
  <br />
  <ng-container *ngIf="options?.length > 1 && selectAllEnabled">
    <a *ngIf="!showDeselectAll" (click)="selectAll()">
      Select All {{ label ?? "Group Options" }}
    </a>
    <a *ngIf="showDeselectAll" (click)="deselectAll()">
      Deselect All {{ label ?? "Group Options" }}
    </a>
  </ng-container>
  <ciao-form-field
    *ngFor="let option of options"
    [label]="option.label"
    type="checkbox"
    [formControlInput]="control.controls[option.value]"
  />
  <!-- {{ control.errors | json }}
  {{ errors | json }}
  {{ valid | json }} -->
</fieldset>
