import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TripModalV2Component } from './trip-modal-v2.component';
import { MaterialModule } from '~app/components/shared/material.module';
import { CiaoSharedModule } from '~app/components/shared/shared.module';
import { CiaoFormsModule } from '~app/components/shared/forms/forms.module';

import { DetailsFormComponent } from './details-form/details-form.component';
import { LocationsFormComponent } from './locations-form/locations-form.component';
import { CrewFormComponent } from './crew-form/crew-form.component';
import { EquipmentFormComponent } from './equipment-form/equipment-form.component';
import { AttachmentsFormComponent } from './attachments-form/attachments-form.component';
import { FileUploadComponent } from './attachments-form/file-upload/file-upload.component';

@NgModule({
  declarations: [
    TripModalV2Component,
    DetailsFormComponent,
    LocationsFormComponent,
    CrewFormComponent,
    EquipmentFormComponent,
  ],
  imports: [
    CommonModule,
    CiaoSharedModule,
    CiaoFormsModule,
    MaterialModule,
    RouterModule,
    AttachmentsFormComponent,
  ],
  exports: [TripModalV2Component],
})
export class TripModalV2Module {}
