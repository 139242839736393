import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
} from 'rxjs/operators';
import { AppliedPermission } from '~app/models/applied-permission';
import { AssignedRole, Role } from '~app/models/assigned-role';
import { UserGroupAttributes } from '~app/models/user-group';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { UserGroupService } from './user-group.service';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionService {
  public readonly isAdmin$: Observable<boolean>;
  public readonly isSystemAdmin$: Observable<boolean>;

  public readonly RolesById$ = this.authService.oAuthInfo$.pipe(
    filter((oAuthInfo) => !!oAuthInfo?.email),
    take(1),
    switchMapTo(this.apiService.GetAll<Role>('/roles/')),
    map((roles) => {
      let rolesById: { [id: string]: Role } = {};
      roles.forEach((role) => (rolesById[role.id] = role));
      return rolesById;
    }),
    shareReplay(1)
  );
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userGroupService: UserGroupService
  ) {
    this.isAdmin$ = this.userGroupService
      .searchMyUserGroups(['system_admin', 'region_admin'])
      .pipe(
        map((groups) => groups.length > 0),
        shareReplay(1)
      );
    this.isSystemAdmin$ = this.userGroupService
      .searchMyUserGroups(['system_admin'])
      .pipe(
        map((groups) => groups.length > 0),
        shareReplay(1)
      );
    // Subscribe to make sure this doesn't lag the users tab if users get to it at the end.
    this.RolesById$.pipe().subscribe();
  }

  searchRoles(input: { personIds: string[]; roleIds?: string[] }) {
    if (!input.personIds) {
      throw new Error('Cannot search for Roles without a person');
    }
    const searchUserGroupsResponse$ = this.apiService.Search<
      (UserGroupAttributes & {
        AssignedRoles: { PersonId: string; RoleId: string; id: string }[];
      })[]
    >('actions/search-user-groups', {
      roleIds: input.roleIds,
      personIds: input.personIds,
    });
    return combineLatest([
      searchUserGroupsResponse$,
      this.userGroupService.allUserGroupsById$,
      this.RolesById$,
    ]).pipe(
      map(([userGroupResults, userGroupsById, rolesById]) => ({
        userGroupResults,
        userGroupsById,
        rolesById,
      })),
      map(({ userGroupResults, userGroupsById, rolesById }) => {
        let setsByGroup = userGroupResults.map((groupAndAssigned, index1) =>
          groupAndAssigned.AssignedRoles.map((assignedRolePartial, index2) => {
            if (!userGroupsById[groupAndAssigned.id]) {
              console.log(
                userGroupsById,
                groupAndAssigned.id,
                userGroupsById[groupAndAssigned.id]
              );
            }
            return {
              id: assignedRolePartial.id,
              UserGroupId: groupAndAssigned.id,
              PersonId: assignedRolePartial.PersonId,
              RoleId: assignedRolePartial.RoleId,
              role: rolesById[assignedRolePartial.RoleId],
              userGroup: userGroupsById[groupAndAssigned.id],
            };
          })
        );
        return ([] as AssignedRole[]).concat(...setsByGroup);
      })
    );
  }

  // method to search for user groups where the current user has edit/create permission
  // checks will be about 1 million since 1000 user group cap + nested loop (1000^2)
  searchMyUserGroupsByPermission(input: {
    permissionIds: string[];
    filterWithinUserGroups?: UserGroupAttributes[];
  }) {
    let allUserGroups$ = this.userGroupService.allUserGroups$;

    let myAppliedPermissions$ = this.searchMyAppliedPermissions(input);

    let obs = combineLatest([allUserGroups$, myAppliedPermissions$]).pipe(
      map(([allUserGroups, myAppliedPermissions]) => {
        return allUserGroups.filter((group) =>
          myAppliedPermissions.find((perm) => perm.UserGroupId === group.id)
        );
      })
    );
    return obs;
  }

  searchMyAppliedPermissions(input: {
    permissionIds: string[];
    filterWithinUserGroups?: UserGroupAttributes[];
  }) {
    return this.authService.personId$.pipe(
      map((personId) => ({
        personIds: [personId],
        permissionIds: input.permissionIds,
        filterWithinUserGroups: input.filterWithinUserGroups,
      })),
      switchMap((input) => this.searchAppliedPermissions(input))
    );
  }
  searchAppliedPermissions(input: {
    personIds: string[];
    permissionIds: string[];
    filterWithinUserGroups?: UserGroupAttributes[];
  }) {
    let searchBody = {
      PersonIds: input.personIds,
      PermissionIds: input.permissionIds,
      UserGroupIds: undefined as string[],
    };
    if (input.filterWithinUserGroups) {
      searchBody.UserGroupIds = input.filterWithinUserGroups.map(
        (group) => group.id
      );
    } else {
      delete searchBody.UserGroupIds;
    }
    return this.apiService.Search<AppliedPermission[]>(
      'actions/search-applied-permissions',
      searchBody
    );
  }

  assignRole$({ UserGroupId, PersonId, RoleId }) {
    return this.apiService.Post('/assigned-roles', {
      UserGroupId,
      PersonId,
      RoleId,
    });
  }
  unassignRole$({ UserGroupId, PersonId, RoleId }) {
    return this.apiService.Custom('DELETE', '/assigned-roles/unassign', {
      UserGroupId,
      PersonId,
      RoleId,
    });
  }
}
