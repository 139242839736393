<h2>Combobox Testing</h2>
Testing combobox validation with programmatic inputs.

<div class="row">
  <ciao-form-field
    class="col-4"
    style="width: 20em"
    label="The Combobox"
    type="combobox"
    [selectOptions$]="selectOptions$"
    [formControlInput]="controls.main"
    [compareWith]="compareWith"
  ></ciao-form-field>
  <ciao-form-field
    class="col-4"
    style="width: 20em"
    label="First input must not match this input"
    type="combobox"
    [selectOptions$]="selectOptions$"
    [formControlInput]="controls.auxillary"
    [compareWith]="compareWith"
  ></ciao-form-field>
</div>
<br />
<button class="btn-action-solid" (click)="changeValue('alpha')">
  Set Alpha
</button>
<button class="btn-action-solid" (click)="changeValue('beta')">Set Beta</button>
<button class="btn-action-solid" (click)="changeValue('gamma')">
  Set Gamma
</button>
<br />
<div class="row">
  <pre class="col-4"><code>observe {{debugVars$ | async | json}}</code></pre>
  <pre class="col-4"><code>sync {{debugVars | json}}</code></pre>
</div>
