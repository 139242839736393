import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { AddValidator } from '../../form.util';
import {
  CustomDateParserFormatter,
  CustomDateAdapter,
} from '../custom-ngb-date-parser-formatter';
import { BooleanInput } from '@angular/cdk/coercion';

@Component({
  selector: 'ciao-form-field-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.less'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
  ],
})
export class FormFieldDateRangeComponent implements OnInit, OnDestroy {
  @Input('label') label: string;
  @Input('inputId') inputId: string;

  hoveredDate: NgbDate = null;

  fromDate: NgbDate;
  toDate: NgbDate;

  formControlFrom: UntypedFormControl = new UntypedFormControl('');
  formControlTo: UntypedFormControl = new UntypedFormControl('');
  secretFormControl: UntypedFormGroup = new UntypedFormGroup({
    from: this.formControlFrom,
    to: this.formControlTo,
  });
  @Input() formControlInput: UntypedFormControl;
  @Input() required: BooleanInput = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public adapter: NgbDateAdapter<string>
  ) {}

  ngOnInit(): void {
    // Sync formControls
    let obs1 = this.formControlInput.valueChanges.pipe(
      distinctUntilChanged(this.dateSetsEqual),
      map((isoSet) => this.IsoToEnUS(isoSet)),
      tap((enUSSet) => this.secretFormControl.setValue(enUSSet))
    );
    let obs2 = this.secretFormControl.valueChanges.pipe(
      tap(() => {
        if (this.secretFormControl.touched) {
          this.formControlInput.markAllAsTouched();
        } else {
          this.formControlInput.markAsUntouched();
        }
        if (this.secretFormControl.pristine) {
          this.formControlInput.markAsPristine();
        } else {
          this.formControlInput.markAsDirty();
        }
      }),
      distinctUntilChanged(this.dateSetsEqual),
      map((enUSSet) => this.EnUSToIso(enUSSet)),
      tap((isoSet) =>
        this.formControlInput.setValue(isoSet, { emitEvent: false })
      )
    );
    this.subscriptions.add(obs1.subscribe());
    this.subscriptions.add(obs2.subscribe());

    setTimeout(() => {
      // Add Validators
      AddValidator(this.secretFormControl, this.validateControl);
      AddValidator(this.formControlInput, this.validateControl);
      AddValidator(this.formControlFrom, this.validateFromControl);
      AddValidator(this.formControlTo, this.validateToControl);

      setTimeout(() => this.formControlInput.updateValueAndValidity());
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      (date.equals(this.fromDate) || date.after(this.fromDate))
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.secretFormControl.setValue({
      to: this.formatter.format(this.toDate),
      from: this.formatter.format(this.fromDate),
    });
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateControl: ValidatorFn = (control: AbstractControl) => {
    let errors: ValidationErrors = {};
    Object.assign(
      errors,
      this.validateFromControl(this.formControlFrom),
      this.validateToControl(this.formControlTo)
    );

    return errors;
  };
  validateFromControl: ValidatorFn = (fromControl: UntypedFormControl) => {
    if (fromControl !== this.formControlFrom) {
      throw new Error('Expected different Control');
    }
    let errors: ValidationErrors = {};
    const val = this.secretFormControl.value;
    const from = NgbDate.from(this.formatter.parse(val.from || ''));
    const to = NgbDate.from(this.formatter.parse(val.to || ''));
    if (from && !this.calendar.isValid(from)) {
      errors.fromDateInvalid = true;
    }
    if (to && !from) {
      errors.fromDateRequired = true;
    }
    if (to?.before(from)) {
      errors.toDate_before_fromDate = true;
    }
    if (!to && this.required) {
      errors.required = true;
    }

    return errors;
  };
  validateToControl: ValidatorFn = (toControl: UntypedFormControl) => {
    if (toControl !== this.formControlTo) {
      throw new Error('Expected different Control');
    }
    let errors: ValidationErrors = {};
    const val = this.secretFormControl.value;
    const from = NgbDate.from(this.formatter.parse(val.from));
    const to = NgbDate.from(this.formatter.parse(val.to));
    if (to && !this.calendar.isValid(to)) {
      errors.toDateInvalid = true;
    }
    if (from && !to) {
      errors.toDateRequired = true;
    }
    if (to?.before(from)) {
      errors.toDate_before_fromDate = true;
    }

    return errors;
  };

  dateSetsEqual<T extends Date | string>(a: DateSet<T>, b: DateSet<T>) {
    return (
      a?.from?.valueOf() === b?.from?.valueOf() &&
      a?.to?.valueOf() === b?.to?.valueOf()
    );
  }

  IsoToEnUS(value: DateSet<string>): DateSet<string> {
    return {
      from: this.formatter.format(this.adapter.fromModel(value.from)),
      to: this.formatter.format(this.adapter.fromModel(value.to)),
    };
  }

  EnUSToIso(value: DateSet<string>): DateSet<string> {
    return {
      from: this.adapter.toModel(this.formatter.parse(value.from)),
      to: this.adapter.toModel(this.formatter.parse(value.to)),
    };
  }
}

type DateSet<T extends Date | string> = {
  from: T;
  to: T;
};
