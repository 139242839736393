import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import {
  MatProgressBarModule,
  ProgressBarMode,
} from '@angular/material/progress-bar';

import { ConfigService } from '~app/services';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';

/**
 * states that this can be in:
 * About to Upload: determinate, value=1, yellow
 * Uploading: indeterminate, value=?, yellow
 * Fully Uploaded: determinate, value=100, green
 * Deleting: indeterminate, value=?, red
 * Deleted: (should be gone), red
 *
 * User-linked Behaviors:
 * Push file to start uploading
 * View/Download file on click
 * delete confirmation on click
 * Start deleting on confirm click
 */

export const statusIndicators: {
  [key in FileAttachments.Status]: {
    mode: ProgressBarMode;
    value: number;
    class: string;
    show: boolean;
    clicksAllowed?: true;
  };
} = {
  waiting: {
    mode: 'determinate',
    value: 2,
    class: 'progress-bar-paused',
    show: true,
  },
  uploading: {
    mode: 'buffer',
    value: -1,
    class: 'progress-bar-good',
    show: true,
  },
  uploaded: {
    mode: 'determinate',
    value: 100,
    class: 'progress-bar-good',
    clicksAllowed: true,
    show: false,
  },
  deleting: {
    mode: 'buffer',
    value: -1,
    class: 'progress-bar-bad',
    show: true,
  },
  deleted: {
    mode: 'determinate',
    value: 100,
    class: 'progress-bar-bad',
    show: false,
  },
} as const;

@Component({
  selector: 'ciao-individual-file-bar',
  standalone: true,
  imports: [
    NgClass,
    MatIcon,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  templateUrl: './individual-file-bar.component.html',
  styleUrl: './individual-file-bar.component.less',
})
export class IndividualFileBarComponent {
  @Input({ required: true }) file: FileAttachments.AttachedFile;

  @Output() removeFile = new EventEmitter<FileAttachments.AttachedFile>();

  @ViewChild('confirmDelete') confirmDeleteTemplate: TemplateRef<any>;
  confirmDeleteDialogRef: MatDialogRef<TemplateRef<any>>;

  get statusIndicator() {
    return statusIndicators[this.file.status] ?? statusIndicators.waiting;
  }

  constructor(private matDialogService: MatDialog) {}

  removeFileClick() {
    this.confirmDeleteDialogRef = this.matDialogService.open(
      this.confirmDeleteTemplate,
      { panelClass: 'raleway-dialog-alpha', width: '450px' }
    );
  }
  confirmDeleteClick() {
    this.removeFile.emit(this.file);
    this.confirmDeleteDialogRef.close();
  }
}
