/**
 * Use for inputting search parameters from table or field component through one of the Services
 *
 * @example
 * ```
 *  let blah: SearchData = {
 *    limit: 0,
 *    offset: 0,
 *    order: [['createdAt', 'DESC']],
 *    where: [
 *      ['name', 'Jones'],
 *      ['wild', 'tree']
 *    ],
 *  };
 * ```
 *
 * */
export interface PaginationData {
  limit: number;
  offset: number;
  order: [string, ('ASC' | 'DESC' | undefined)?][];
  where: [string, any?][];
}

export interface PaginationResult<T> {
  totalRowCount: number;
  rowCount: number;
  options?: PaginationData;
  rows: T[];
  isLoading?: boolean;
}

export function IsLoadingPaginationResult<T = any>(): PaginationResult<T> {
  return {
    totalRowCount: 0,
    rowCount: 0,
    rows: null,
    isLoading: true,
  };
}
