import { AbstractControl, ValidatorFn } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export function AddValidator(
  control: AbstractControl,
  newValidator: ValidatorFn
) {
  let fn = control.validator;
  let validators = fn ? [fn] : [];
  validators.push(newValidator);
  control.setValidators(validators);
}
