import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Observable, of } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { PlotAttributes, TripAttributes } from '~app/models';
import { TextService, TripService } from '~app/services';
import { TripModalV2Component } from '../trip-modal-v2/trip-modal-v2.component';
import { OkayType } from '~app/models/okay';
import { ToastrService } from 'ngx-toastr';
import { TripDataChunk } from '../trip-modal-v2/types';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';

@Component({
  selector: 'ciao-trip-summary',
  templateUrl: './trip-summary.component.html',
  styleUrls: ['./trip-summary.component.less'],
})
export class TripSummaryComponent implements OnInit {
  tripID = '';
  trip$: Observable<TripAttributes> = this.getTripObservable();
  @ViewChild('TripModal') TripModal: TripModalV2Component;

  @ViewChild('deleteTripConfirmModal')
  deleteTripConfirmModal: CiaoModalComponent;

  // font awesome
  faCircle = faArrowCircleLeft;
  faPlus = faPlus;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public tripService: TripService,
    private toastr: ToastrService,
    private textService: TextService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.route.snapshot.data.openModal) {
      this.TripModal?.open('Details', null);
    } else if (history.state.openModal) {
      this.TripModal?.open('Locations', null);
    }
  }

  formatAddress(location: PlotAttributes) {
    const addressStr = this.tripService.formatFullAddressAsString(location);
    return this.textService.shortenText(addressStr, 40);
  }

  formatDate(date: Date | string) {
    return this.textService.formatSimpleDatetime(date);
  }

  getReadableOkayType(okay) {
    let readableType =
      {
        checkOut: 'Check Out',
        checkIn: 'Check In',
        okay: "I'm Okay",
      }[okay.type] ?? '';
    let closedTripIndicator = okay.closedTrip ? ': Trip Closed' : '';
    return readableType + closedTripIndicator;
  }

  showCheckedIn(trip: TripAttributes) {
    return trip.crewMembersProcessed.every(
      (crewMember) => crewMember?.crewMemberStatus === 'CheckedIn'
    );
  }

  saveTrip(options: {
    transform: (trip: TripAttributes) => void;
    successMessage?: string;
    failureMessage?: string;
    allowInvalid?: boolean;
    clearForm?: boolean;
  }) {
    options.successMessage =
      options.successMessage || 'Successfully Saved Trip';
    options.failureMessage = options.failureMessage || 'Failed to Save Trip';
    this.trip$
      .pipe(
        take(1),
        tap((trip) => options.transform(trip)),
        switchMap((trip) => this.tripService.saveTrip(trip)),
        tap((trip) => {
          this.toastr.success(options.successMessage);
        }),

        catchError((err) => {
          this.toastr.error(options.failureMessage);
          console.error(err);
          return of<TripAttributes>(null);
        })
      )
      .subscribe();
  }

  getTripObservable() {
    return this.route.params.pipe(
      map((value) => value.id),
      tap((tripId) => (this.tripID = tripId)),
      switchMap((tripId) =>
        tripId
          ? this.tripService.findById(tripId)
          : of(this.tripService.getBlankTripForm())
      ),
      map((trip) => {
        // sort the okays with latest on top
        trip.okays = trip.okays?.sort((a, b) =>
          b.recordedAt > a.recordedAt ? 1 : b.recordedAt < a.recordedAt ? -1 : 0
        );
        return trip;
      }),
      shareReplay(1)
    );
  }

  // TODO - implement
  saveToPdf() {
    // console.log('save to pdf not implemented');
  }

  canOkay(trip: TripAttributes, okayType: OkayType) {
    let tripIsValid =
      trip.id &&
      trip.startDate &&
      trip.endDate &&
      trip.usergroup &&
      trip.locations.length > 0 &&
      trip.crewMembers.length > 0;

    switch (okayType) {
      case 'checkOut':
        return tripIsValid && trip.tripStatus === 'Planned';
      case 'checkIn':
      case 'okay':
        return trip.tripStatus === 'Open' || trip.tripStatus === 'Late';
    }
  }

  onRowDelete(id: string, table) {
    this.saveTrip({
      transform: (trip) => {
        const list = this.getListFromTripAndCurrentTab(trip, table);
        const index = list.findIndex((item) => item.id === id);
        list.splice(index, 1);
      },
      allowInvalid: true,
      clearForm: true,
    });
  }

  getListFromTripAndCurrentTab(trip: TripAttributes, table): TripDataChunk[] {
    return (
      {
        Locations: trip?.locations,
        Crew: trip?.crewMembers,
        Equipment: trip?.equipmentList,
      }[table] || null
    );
  }

  cancelDeleteTripClicked(ev) {
    ev.preventDefault();
    this.deleteTripConfirmModal.close('');
  }

  confirmDeleteTripClicked(ev) {
    try {
      // this trip pipe to accomplish the same thing
      this.tripService
        .deleteTrip(this.tripID)
        .pipe(
          take(1),
          tap((trip) => {
            this.router.navigate(['/search']);
            this.deleteTripConfirmModal.close('');
          })
        )
        .subscribe();
      this.toastr.success('Trip has been successfully deleted.', 'Success');
    } catch (err) {
      this.toastr.error(`There was an error deleting the trip.`, 'Error');
    }
  }
}
